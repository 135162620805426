<template>
  <div class="date-time-wrapper flex-column" v-bind:style="dates.length === 0 ? 'display: none !important;' : ''">
    <span class="datet-section-title" style="margin-bottom: 15px" v-if="!disableTitle">{{ $t(title) }}</span>
    <div ref="dateTimeScroll" class="date-time-wrapper-list d-flex flex-row-wrap">
      <span style="color: var(--darkish-grey)" v-if="dates.length === 0">{{ $t('no-dates-available-in-section') }}</span>
      <div
        v-for="d in dates"
        :key="d.dateTime"
        v-on:click="onSelectTime(d.dateTime, d.idStaff)"
        class="time-item d-flex"
        v-bind:data-date="d.dateTime"
        :class="{ 'active-time': isActive(d.dateTime) }"
      >
        <span>{{ formatTime(d.dateTime) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    dates: Array,
    afternoon: Boolean,
    selectDate: String,
    disableTitle: Boolean,
  },
  watch: {
    dates: function () {
      this.$nextTick(() => {
        this.scrollToTop();
      });
    },
  },
  methods: {
    formatTime(tmstp) {
      const date = moment(tmstp).format('HH:mm');
      return date;
    },
    onSelectTime(time, idStaff) {
      this.$emit('selectTime', [time, idStaff]);
    },
    isActive(time) {
      if (!this.selectDate) return false;
      if (this.selectDate === time) return true;

      return false;
    },
    scrollToTop() {
      if (!this.$refs.dateTimeScroll) return;

      this.$refs.dateTimeScroll.scrollTop = 0;
    },
  },
  data() {
    return {
      title: '',
    };
  },
  created() {
    if (this.afternoon === true) this.title = 'time-afternoon-title';
    else this.title = 'time-noon-title';
  },
};
</script>

<style>
.datet-section-title {
  color: var(--staff-detail-section-t-c);
  font-weight: 300;
}
.date-time-wrapper {
  margin-bottom: 20px;
  text-align: left;
  font-size: 12px;
  display: flex;
}
.date-time-wrapper-list {
  background-color: var(--staff-detail-section-item-bg);
  border-radius: 15px;
  padding: 10px;
  overflow-y: auto;
  max-height: 120px;
  box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.13);
}
.date-time-wrapper-list::-webkit-scrollbar {
  width: 20px;
}
.date-time-wrapper-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 20px 20px transparent;
  border: solid 6px transparent;
}

.date-time-wrapper-list::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 20px 20px #c3c3c3;
  border: solid 6px transparent;
  border-radius: 40px;
}
.active-time {
  background-color: var(--staff-detail-date-sel-bg);
  color: var(--staff-detail-date-sel-color) !important;
}
.time-item {
  cursor: pointer;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 38px;
  border-radius: 10px;
  width: calc(20% - 2px);
  font-weight: 300;
  font-size: 13px;
  border: 1px solid transparent;
  margin: 1px 0;
  color: var(--staff-detail-date-color);
}
.time-item:hover {
  border-color: var(--staff-detail-date-sel-bg);
}
</style>

<template>
  <div class="clinic-overview-wrapper d-flex flex-row">
    <div v-if="!logoSrc" class="clinic-svg-wrapper" v-bind:class="{ 'inactive-img': isInactive }" v-html="icon"></div>
    <img v-if="logoSrc" @error="fallbackToDefault" v-bind:class="{ 'inactive-img': isInactive }" :src="logoSrc" alt="pobocka-icon" />
    <div style="text-align: left" class="text-wrapper" v-bind:class="{ inactive: isInactive }">
      <h2>{{ name }}</h2>
      <span class="street">{{ street }} {{ post }} {{ city }}</span>
    </div>
  </div>
</template>

<script>
import { BASE_URL } from '../../api/http';
export default {
  name: 'ClinicOverview',
  components: {},
  props: ['street', 'city', 'post', 'isInactive', 'name', 'logo', 'iconId', 'icon'],
  methods: {
    fallbackToDefault() {
      this.logoSrc = null;
    },
  },
  watch: {
    logo: function (newVal) {
      if (!newVal) return (this.logoSrc = null);
      this.logoSrc = `${this.baseUrl}/getfile?idFile=${newVal}&size=100x100&imageAsType=0`;
    },
  },
  data() {
    return {
      baseUrl: BASE_URL,
      logoSrc: null,
    };
  },
  created() {
    if (!this.logo) return;
    this.logoSrc = `${this.baseUrl}/getfile?idFile=${this.logo}&size=100x100&imageAsType=0`;
  },
};
</script>

<style>
.clinic-overview-wrapper img,
.clinic-overview-wrapper .clinic-svg-wrapper {
  margin: 0 15px;
  width: 44px;
  height: 44px;
  border-radius: 20px;
}
.street {
  font-size: 12px;
  color: var(--clinic-street-subt-c);
}
.clinic-overview-wrapper {
  width: 100%;
  height: 100%;
  align-items: center;
}
.clinic-overview-wrapper h2 {
  font-weight: 400;
  color: var(--clinic-title-c)
}
.inactive span,
.inactive h2 {
  color: rgb(230, 230, 230) !important;
}
.inactive-img {
  opacity: 0.5;
}
.clinic-overview-wrapper #clinic-svg-outter {
  stroke: var(--clinic-icon-active-c);
}
.clinic-overview-wrapper #clinic-svg-inner {
  fill: var(--clinic-icon-active-c);
}
@media only screen and (max-width: 850px) {
  .clinic-overview-wrapper .text-wrapper {
    padding-right: 15px;
  }
  .clinic-overview-wrapper img {
    margin: 0 15px;
    margin-bottom: 5px;
  }
  .clinic-overview-wrapper h2 {
    font-size: 1.3em;
  }
}
</style>

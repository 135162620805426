<template>
  <div class="open-hours-wrapper">
    <h3>{{ $t('open-hours-title') }}</h3>
    <div class="open-hours-list">
      <DayOpenHour v-for="d in dateList" :key="d.id" :dayName="d.dayType" :morningTime="d.start" :afternoonTime="d.finish" />
    </div>
  </div>
</template>

<script>
import DayOpenHour from './DayOpenHour.vue';

export default {
  props: {
    dateList: Array,
  },
  components: {
    DayOpenHour,
  },
};
</script>

<style>
.open-hours-wrapper {
  text-align: left;
  font-weight: 300;
}
.open-hours-wrapper h3 {
  font-weight: 400;
  font-size: 14px;
  color: var(--openhours-title-c);
}
.open-hours-wrapper span {
  margin: 0 5px;
  font-size: 13px;
  font-weight: 400;
}
.open-hours-wrapper .day-span {
  margin: 0;
  margin-right: 100px;
}
@media only screen and (max-width: 850px) {
  .open-hours-wrapper .day-span {
    margin-right: 20px;
  }
  .open-hours-wrapper .time-span {
    justify-self: end;
  }
}
</style>

<template>
  <div v-bind:style="isFinished && mobile ? 'display: none' : ''" class="sidebar-status-wrapper">
    <div
      v-on:click="redirect(s.path)"
      v-for="s in statusList"
      :key="s.step"
      class="sidebar-status-item"
      v-bind:class="{ 'sidebar-status-item-inactive': s.step !== stepOpen, 'sidebar-status-item-error': s.step === errorStep, 'sidebar-status-item-disabled': s.step > step || disableAll }"
    >
      <img v-if="false" :src="require(`@/assets/icons/sidebar/${getIconSrc(s.step, s.src)}`)" alt="status_icon" />
      <div :id="`sidebar-icon-${s.step}`" class="sidebar-icon">
        {{ getIconSvg(stepOpen > s.step && errorStep != s.step ? `shrnuti_inactive` : errorStep == s.step ? `${s.src}_error` : s.src, `sidebar-icon-${s.step}`) }}
      </div>
      <div class="status-item-txt-wrapper">
        <span class="title-small">{{ getTitle(s) }}</span>
        <span v-if="mobile === false" class="subtitle-big" v-bind:style="errorStep == s.step ? 'color: var(--danger); !important' : ''">{{
          s.subtitle.includes('action') ? $t(s.subtitle) : s.subtitle
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getSvg } from '../helpers/assetHelper';
export default {
  props: {
    statusList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    step: {
      type: Number,
    },
    stepOpen: Number,
    errorStep: Number,
    mobile: Boolean,
    disableAll: Boolean,
    isSafari: Boolean,
    iconId: Number,
    isFinished: Boolean,
  },
  data() {
    return {
      extension: 'svg',
    };
  },
  created() {
    if (this.isSafari) this.extension = 'png';
  },
  methods: {
    redirect(path) {
      if (!path || `/${path}` === this.$router.currentRoute.path) return;
      this.$router.push(path);
    },
    getIconSrc(statusStep, src) {
      if (statusStep === this.errorStep) return `${src}_error.${this.extension}`;
      if (statusStep > this.stepOpen) return `${src}_inactive.${this.extension}`;
      if (statusStep < this.stepOpen) return `shrnuti_inactive.${this.extension}`;
      return `${src}${this.iconId}.${this.extension}`;
    },
    getIconSvg(src, el) {
      getSvg('sidebar/' + src + '.svg', el);
      return '';
    },
    getTitle(s) {
      if (this.mobile === true) {
        if (s['mobileTitle']) return this.$t(s['mobileTitle']);

        return this.$t(s.title);
      }

      if (s.title.includes('title')) return this.$t(s.title);

      return s.title;
    },
  },
};
</script>

<style>
.sidebar-status-wrapper {
  display: flex;
  flex-flow: column nowrap;
  grid-area: sidebar;
  height: 100%;
  padding-left: 15%;
}
.sidebar-status-wrapper #sidebar-icon-border,
.sidebar-status-wrapper .sidebar-icon-border {
  stroke: var(--sidebar-icon-active);
}
.sidebar-status-wrapper #sidebar-icon-fill,
.sidebar-status-wrapper .sidebar-icon-fill {
  fill: var(--sidebar-icon-active);
}
.sidebar-status-item-inactive #sidebar-icon-border,
.sidebar-status-item-inactive .sidebar-icon-border {
  stroke: var(--sidebar-icon-inactive);
}
.sidebar-status-item-inactive #sidebar-icon-fill,
.sidebar-status-item-inactive .sidebar-icon-fill {
  fill: var(--sidebar-icon-inactive);
}
.sidebar-status-item-error,
.sidebar-status-item-error .title-small {
  color: var(--sidebar-text-error-c) !important;
}
.sidebar-status-item {
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 8px 0;
}
.sidebar-status-item .sidebar-icon, .sidebar-icon svg {
  width: 44px;
  height: 44px;
}
.sidebar-status-item:hover {
  opacity: 0.8;
}
.sidebar-status-item-disabled {
  pointer-events: none;
}
.sidebar-status-item img {
  width: 44px;
  height: 44px;
}
.status-item-txt-wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
  margin-left: 15px;
}
.status-item-txt-wrapper .title-small {
  font-size: 11px;
  color: var(--sidebar-step-title-c);
}
.status-item-txt-wrapper .subtitle-big {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: var(--sidebar-step-subt-c);
}
.sidebar-status-item-inactive .title-small {
  color: var(--sidebar-step-title-i-c);
}
.sidebar-status-item-inactive .subtitle-big {
  color: var(--sidebar-step-subt-i-c);
}
@media only screen and (max-width: 850px) {
  .sidebar-status-wrapper {
    flex-flow: row nowrap;
    padding-left: 0;
    justify-content: space-evenly;
    width: 85%;
  }
  .sidebar-status-item {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin: 8px 0px;
    max-width: 44px;
  }
  .status-item-txt-wrapper {
    margin-left: 0;
    margin-top: 5px;
  }
  .status-item-txt-wrapper .subtitle-big {
    display: none;
  }
}
</style>

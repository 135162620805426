<template>
  <div
    class="clinic-service-item flex-layout-list-item d-flex flex-column"
    v-bind:style="mobile && isSelect && !detailsData.description ? 'margin-right: 0px !important' : ''"
    v-bind:class="{ 'service-item-open': isSelect, 'service-item-cut-text': !isSelect, 'service-item-open-expand': isSelect && detailsData.description }"
    v-on:click="onOpenService"
  >
    <div class="d-flex flex-row">
      <div class="service-icon-wrapper d-flex">
        <div class="svg-wrapper" v-html="icon"></div>
        <div v-if="!icon" style="width: 40px; height: 40px;"></div>
        <img v-if="false" v-bind:class="{ 'inactive-img': isInactive }" :src="require(`@/assets/icons/sluzba${iconId}.png`)" class="icon-service" alt="sluzba_icon" />
      </div>
      <div class="service-overview-desc-wrapper">
        <h2 class="title" v-bind:class="{ inactive: isInactive }">{{ service.name }}</h2>
        <span v-if="!mobile" class="placeholder">Placeholder</span>
        <span class="duration-span" v-if="isInactive === false">{{ service.duration }} min</span>
        <span class="text-danger duration-span" v-if="isInactive === true">{{ $t('clinic-select-not-possible') }}</span>
        <span class="price-text">{{ parsePrice() }}</span>
      </div>
    </div>
    <div class="service-mobile-details-wrapper service-details-description-wrapper" v-if="isSelect">
      <p v-html="detailsData.description"></p>
      <p v-if="!detailsData.description">{{ $t('no-service-description') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['service', "currency", 'isSelect', 'detailsData', 'mobile', 'iconId', 'icon', 'isSafari'],
  data() {
    return {
      isInactive: false,
    };
  },
  async created() {
    this.isInactive = this.service.staffs.length === 0;
  },
  methods: {
    onOpenService() {
      this.$emit('service-open', this.service.idService);
    },
    parsePrice() {
      let str = this.service.price ? String(this.service.price) : "";

      const isRounded = str.split(".").length > 1 ? false : true;

      if (isRounded && this.service.price !== null) str += ",00";

      str = this.service.price ? str + " " + this.currency : "";

      return str
    }
  },
};
</script>

<style>
.open.clinic-service-item {
  padding-top: 10px;
}
.clinic-service-item {
  margin-right: 30px !important;
}
.service-icon-wrapper {
  justify-content: center;
  align-items: center;
  margin: 0 15px;
}
.service-item-open {
  background-color: var(--select-item-bg-color) !important;
}
.service-item-open-expand {
  margin-right: -20px !important;
}
.service-icon-wrapper img, .service-icon-wrapper .svg-wrapper, .service-icon-wrapper svg {
  width: 40px;
  height: 40px;
}
.service-icon-wrapper #sluzba-zub-outter {
  fill: var(--service-icon-active-c);
}
.desc-wrapper {
  justify-content: center;
  width: 75%;
}
.service-overview-desc-wrapper h2 {
  font-weight: 400;
  color: var(--service-title-c);
}
.service-item-open-expand .price-text {
  margin-right: 45px !important;
}
.service-overview-desc-wrapper .price-text {
  color: var(--service-text-price-c);
  font-weight: 300;
  opacity: 0.6;
  justify-self: end;
  margin-top: 5px;
  font-size: 12px;
  margin-right: 5px;
  grid-area: price;
}
.service-overview-desc-wrapper .title {
  align-self: end;
  grid-area: title;
}
.staff-wrapper {
  width: 100%;
  border: 1px solid black;
  margin: 5px 0;
  cursor: pointer;
  padding: 10px;
  box-sizing: border-box;
}
.duration-span {
  margin-top: 5px;
  font-size: 12px;
  color: var(--service-text-time-c);
  grid-area: duration;
}
.service-overview-desc-wrapper {
  text-align: left;
  width: 80%;
  display: grid;
  grid-template-columns: 2fr 1fr;
        grid-template-areas: 
      "title placeholder"
      "duration price"
      ;
}
.open .service-overview-desc-wrapper {
  align-items: flex-start;
}
.service-overview-desc-wrapper .placeholder {
  visibility: hidden;
  display: block;
  font-size: 5px;
  grid-area: placeholder;
}
.open .service-price-wrapper {
  margin-bottom: 16px;
}
.open .service-price-wrapper h3 {
  display: none;
}
.clinic-service-details {
  align-items: flex-start;
  padding-bottom: 20px;
}
.open .service-icon-wrapper {
  width: 8%;
}
.inactive {
  color: rgb(230, 230, 230);
}
.inactive-img {
  opacity: 0.5;
}
.service-mobile-details-wrapper {
  display: none;
  margin-top: 20px;
  box-sizing: border-box;
  text-align: left;
}
@media only screen and (max-width: 850px) {
  .service-icon-wrapper {
    align-items: center;
    justify-content: flex-start;
  }
  .clinic-service-item {
    margin-right: 0 !important;
    margin-left: var(--item-ml-mobile) !important;
    padding: 20px 10px !important;
    box-sizing: border-box;
    width: calc(100% - 20px) !important;
  }
  .service-icon-wrapper img {
    width: 41px;
    height: 41px;
  }
  .service-mobile-details-wrapper {
    display: block;
    margin-left: 15px;
    padding-right: 25px;
  }
  .service-mobile-details-wrapper p {
    font-weight: 300;
    font-size: 13px;
    overflow: hidden;
  }
  .service-overview-desc-wrapper {
      grid-template-areas: 
      "title title"
      "duration price"
      ;
  }
  .service-overview-desc-wrapper .title {
    font-size: 16px;
  }
  .service-overview-desc-wrapper .price-text {
    font-size: 12px;
    margin-top: 3px;
  }
  .duration-span {
    margin-top: 0px;
    font-size: 12px;
    margin-top: 3px;
  }
  .service-price-wrapper {
    text-align: center;
  }
  .service-icon-wrapper {
    margin-right: 15px;
  }
  .service-item-cut-text .title {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
  }
  .service-item-open .price-text {
    margin-right: 0 !important;
  }
}
</style>

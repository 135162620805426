<template>
    <div class="init-loader-wrapper error-default-logo notfound-overlay d-flex flex-column">
      <img class="img_abs_center x-bg" :src="require('@/assets/icons/x404.svg')" >
      <img class="img_abs_center" :src="require('@/assets/icons/laptop.svg')" >
      <span class="notfound-title">Stránka nenalezena</span>
      <AppButton :action="'gohome'" v-on:gohome="onGoHome" class="go-home-btn" :background="true" :greyTheme="true" :title="'Návrat domů'"/>
    </div>
</template>

<script>
import AppButton from "../components/Button.vue";
import { getAppConfig } from '../configManager';

export default {
    components: {
        AppButton
    },
    methods: {
        onGoHome() {
            window.location.href = getAppConfig("goHomeUrl")
        }
    }
}
</script>

<style>
    .notfound-overlay {
        opacity: 1 !important;
    }
    .img_abs_center {
        position: absolute;
        max-width: 100%;
        max-height: 100%;
    }
    .notfound-title {
        margin-top: 30vh;
        color: #273D52;
        font-size: 36px;
        font-weight: normal;
        opacity: 1;
    }
    .go-home-btn {
        color: #4A4A4A;
        font-weight: 400;
        padding: 5px 10px;
        font-size: 14px;
        margin-top: 15px;
        cursor: pointer;
        transition: .25s ease-in-out;
        z-index: 300;
    }
    .go-home-btn:hover {
        border-color: black !important;
    }
</style>
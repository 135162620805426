import moment from 'moment';

export const getAfternoonTimes = (data) => {
  if (data.openHours) data = data.openHours;
  return data.filter((t) => t.afternoon === true);
};
export const getNoonTimes = (data) => {
  if (data.openHours) data = data.openHours;
  return data.filter((t) => t.afternoon === false);
};
export const splitAllDatesToMonth = (allDates, idStaff) => {
  if (!allDates) allDates = [];
  let parsedObj = {};
  allDates.map(({ date, openHours }) => {
    const mDate = moment(date).locale('en');
    const month = mDate.format('MMM');
    const mDay = mDate.format('D');
    const key = `${month}_${mDate.format('YYYY')}`;
    openHours = openHours.map((o) => {
      o['idStaff'] = idStaff;
      return o;
    });
    if (!parsedObj[key]) parsedObj = { ...parsedObj, [key]: {} };
    parsedObj[key] = { ...parsedObj[key], [mDay]: openHours };
  });
  return parsedObj;
};
export const getHighlightedDays = (daysArr) => {
  const newArr = [];
  daysArr.forEach((d) => {
    newArr.push(new Date(moment(d.dateTime).utc().valueOf()));
  });
  return newArr;
};
export const timestampToMonthYear = (tmstp, useEn = false) => {
  const momentD = moment(tmstp);
  if (useEn === true) momentD.locale('en');
  return `${momentD.format('MMM')}_${momentD.format('YYYY')}`;
};
export const timestampToDay = (tmstp) => {
  const momentD = moment(tmstp);
  return momentD.format('D');
};
export const monthYearToTimestamp = (key) => {
  const [month, year] = key.split('_');

  const dateString = `01 ${month} ${year}`;

  return moment(dateString).startOf('month').valueOf();
};
export const timestampToNextMonth = tmstp => {
  return moment(tmstp).add(1, "M").startOf("day").toDate();
}
export const timestampToPreviousMonth = tmstp => {
  return moment(tmstp).subtract(1, "M").startOf("day").toDate();
}
export const monthNumToString = (mNum) => {
  if (mNum > 12) return 'ErrorDate';
  const mStringArr = ['', 'Leden', 'Únor', 'Březen', 'Duben', 'Květen', 'Červen', 'Červenec', 'Srpen', 'Září', 'Říjen', 'Listopad', 'Prosinec'];
  return mStringArr[mNum];
};
export const monthKeyToString = (mKey) => {
  if (mKey > 12) return 'ErrorDate';
  const mStrings = {
    Jan: 'Leden',
    Feb: 'Únor',
    Mar: 'Březen',
    Apr: 'Duben',
    May: 'Květen',
    Jun: 'Červen',
    Jul: 'Červenec',
    Aug: 'Srpen',
    Sep: 'Září',
    Oct: 'Říjen',
    Nov: 'Listopad',
    Dec: 'Prosinec',
  };
  return mStrings[mKey];
};
export const dateToYear = (d) => {
  return moment(d).format('YYYY');
};
export const dateToMonthTmstp = (d) => {
  return moment(d).startOf('month').valueOf();
};
export const getCurrentMonthEpochDate = () => {
  return moment().startOf('month').valueOf();
};
export const getCurrentMonthStrDate = () => {
  return moment().startOf('month').format('ddd MMM DD YYYY HH:mm:ss');
};
export const getCurrentDayEpoch = () => {
  return moment().startOf('day').unix() * 1000;
};
export const formatEpochToStrDate = (tmstp) => {
  return moment(tmstp).utc().startOf('day').format('ddd MMM DD YYYY HH:mm:ss');
};
export const fullStrDateToDMY = (str) => {
  return moment(str).format('DD.MM.YYYY');
};
export const isDateCurrentMonth = (month) => {
  const mDate = moment(month).startOf('month').valueOf();
  const mNowDate = moment().startOf('month').valueOf();
  return mDate === mNowDate;
};
export const getEarliestDate = (datesAval, returnEpoch = false) => {
  if (Object.values(datesAval).length === 0) {
    //fallback to current date
    const d = moment();
    if (returnEpoch) return d.valueOf();
    return d;
  }
  const date = Date.parse(Object.values(Object.values(datesAval)[0])[0][0].dateTime);

  if (returnEpoch === true) return moment(date).valueOf();
  return date;
};
export const getEarliestDateInMonth = (datesAval, monthTmstp, returnEpoch = false) => {
  monthTmstp = typeof monthTmstp === Number ? timestampToMonthYear(monthTmstp) : monthTmstp;
  
  if (!datesAval[monthTmstp]) return false;

  const date = Date.parse(Object.values(datesAval[monthTmstp])[0][0].dateTime);

  if (returnEpoch === true) return moment(date).valueOf();
  return date;
};

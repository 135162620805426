<template>
  <div class="header-wrapper">
    <a class="header-link d-flex" href="/"><img v-if="logo" :src="logo" @error="fallbackToDefault" alt="logo_img" class="logo" /></a>
    <div class="header-title-wrapper">
      <h1>{{ title }}</h1>
      <h3 v-if="subtitle">{{subtitle}}</h3>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', "subtitle", 'logo'],
  data() {
    return {
      logoSrc: require('@/assets/logo-black.png'),
    };
  },
  methods: {
    fallbackToDefault() {
      this.logoSrc = require('@/assets/logo-black.png');
    },
  },
  created() {
    if (this.logo) this.logoSrc = this.logo;
  },
};
</script>

<style>
.header-wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  grid-area: header;
  padding: 25px 0;
  box-sizing: border-box;
}
.header-link {
  width: 165px;
  height: 65px;
  justify-content: center;
  align-items: center;
}
.logo {
  max-width: 100%;
  max-height: 100%;
}
.header-title-wrapper {
  margin: 0 20px;
  text-align: left;
}
.header-title-wrapper h1 {
  font-weight: 400;
  font-size: 22px;
  color: var(--header-title-c);
}
.header-title-wrapper h3 {
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  color: var(--header-subtitle-c);
}
</style>

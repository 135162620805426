import moment from 'moment';
import { escapeText } from './textHelper';

export const parseDataToPayload = (data, miscData) => {
  const mDate = moment(data.time);

  return {
    idResource: data.staffId,
    idService: data.serviceId,
    idLocation: data.locationId,
    idCompany: null,
    selectedYear: mDate.format('YYYY'),
    selectedMonth: mDate.format('MM'),
    selectedDay: mDate.format('DD'),
    selectedHour: mDate.format('HH'),
    selectedMinute: mDate.format('mm'),
    firstName: escapeText(data.firstName),
    lastName: escapeText(data.lastName),
    email: escapeText(data.email),
    phonePrefix: data.phonePrefix,
    phone: data.phone,
    promo: miscData.INFO_PROMO ? miscData.INFO_PROMO.value : '',
    street: data.street,
    city: data.city,
    zip: data.zip,
    state: data.state,
    clientRequest: miscData.INFO_NOTES ? miscData.INFO_NOTES.value : '',
    optIn: miscData.INFO_OPT_IN ? miscData.INFO_OPT_IN.value : false,
    findUs: miscData.INFO_FIND ? miscData.INFO_FIND.value : '',
    custom: escapeText(data.custom),
    serviceName: data.serviceName,
    marketing: true,
  };
};

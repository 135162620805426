import { getAppConfig } from '../configManager';

export const BASE_URL = getAppConfig('baseUrl');

const parseUrlQueryParams = (params) => {
  let str = '?';

  for (const key in params) {
    const val = params[key];
    let value = val;
    let keyString = key;
    if (val['value']) {
      value = val['value'];
      keyString = val['key'];
    }

    if (str !== '?') str += '&';

    str += keyString + '=' + value;
  }
  return str;
};

const get = async (url, params = {}, returnCode = false) => {
  url += parseUrlQueryParams(params);
  let req;
  try {
    req = await fetch(BASE_URL + url);
  } catch (error) {
    return null;
  }
  const cType = req.headers.get('Content-Type');
  
  let data;
  if (cType.includes('json')) data = await req.json();
  else data = await req.text();

  if (returnCode) return {data, code: req.status};

  return data;
};
const post = async (url, payload) => {
  const req = await fetch(BASE_URL + url, {
    method: 'POST',
    body: payload,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const cType = req.headers.get('Content-Type');

  let data;
  if (cType && cType.includes('json')) data = await req.json();
  else data = await req.text();

  return data;
};

export default {
  //these functions can be replaced anytime by for example axios methods
  get: get,
  post: post,
};

<template>
  <ValidationProvider class="checkbox-input-wrapper input-wrapper" style="width: 100%" tag="div" :name="name" :rules="rulesPipe" v-slot="{ errors }">
    <div class="wrapper d-flex flex-row">
      <span>{{ label }}</span>
      <input
        type="checkbox"
        :name="name"
        class="form-control"
        :class="{ 'input-error': errors.length > 0 && errorActivated, 'input-warning-error': errors.length > 0 && errorActivated && isWarningError }"
        v-model="value"
      />
    </div>
    <span class="error-text" v-bind:class="{ 'error-warning-text': isWarningError }" v-if="displayError">{{ errors[0] }}</span>
  </ValidationProvider>
</template>

<script>
export default {
  props: {
    name: String,
    rules: Array,
    label: String,
    displayError: Boolean,
    model: Boolean,
    isWarningError: Boolean,
    errorActivated: Boolean,
  },
  data() {
    return {
      rulesPipe: '',
      value: [],
    };
  },
  watch: {
    value: function (newVal) {
      this.$emit('change', { newVal, name: this.name });
    },
    model: function (newModel) {
      if (newModel === this.value) return;
      this.value = newModel;
    },
  },
  created() {
    this.rules.map((r) => {
      if (this.rulesPipe !== '') this.rulesPipe += '|';

      this.rulesPipe += r;
    });

    this.value = this.model;
  },
};
</script>

<style scoped>
.checkbox-input-wrapper input {
  border: 1px solid rgba(100, 100, 100, 0.3);
  border-radius: 10px;
  padding: 5px 10px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 300;
  width: 20px;
  height: 20px;
  margin-left: 20px;
}
.checkbox-input-wrapper span {
  font-weight: 300;
  color: var(--form-label-errorwarn-c);
}
.checkbox-input-wrapper {
  width: 100%;
}
.checkbox-input-wrapper .wrapper {
  align-items: center;
  width: 100%;
  margin: 0;
}
@media only screen and (max-width: 850px) {
  .checkbox-input-wrapper .wrapper {
    justify-content: space-between;
  }
}
</style>

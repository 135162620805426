<template>
  <div class="d-flex flex-column" style="height: 100%; justify-content: space-between">
    <ErrorOverlay v-if="filteredServices.length === 0" :icon="require('@/assets/icons/service_overlay.svg')" :subtitle="$t('no-service-overlay-subtitle')" :title="$t('clinic-no-services')" />
    <div v-if="filteredServices.length > 0" ref="parentEl" class="service-selection d-flex flex-row">
      <div class="scroller d-flex" style="width: 100%" v-bind:style="filteredServices.length === 0 ? 'justify-content: center; flex-flow: column nowrap;' : ''">
        <div class="clinic-services-wrapper flex-layout-list">
          <div v-if="showSearchbar" class="searchbar-wrapper">
            <input @keyup="updateSearchQuery" ref="searchBarInput" type="text" placeholder="Vyhledat službu..">
            <img class="search-icon" :src="require('@/assets/icons/search-icon.svg')" alt="ikona" />
          </div>
          <ClinicService
            :isSafari="contentData.isSafari"
            :icon="sluzbaIcon"
            :mobile="contentData.mobile"
            :iconId="contentData.iconId"
            :detailsData="{ description: openService ? openService.description : '' }"
            v-for="s in filteredServices"
            v-on:service-open="onServiceOpen"
            :isSelect="s.idService === openServiceId"
            :service="s"
            :currency="contentData.currency || 'Kč'"
            :key="s.idService"
          />
        </div>
        <div class="service-details d-flex" v-if="detailsOpen && contentData.mobile === false" v-bind:style="!openService.description ? 'visibility: hidden' : ''">
          <div ref="detailEl" class="service-details-wrapper d-flex service-details-description-wrapper">
            <p v-html="openService.description"></p>
            <p v-if="!openService.description">{{ $t('no-service-description') }}</p>
          </div>
        </div>
      </div>
    </div>
    <ButtonPanel
      :hintComplete="openServiceId || filteredServices.length === 0 ? true : false"
      :disabled="openService === null"
      :iconId="contentData.iconId"
      :hintText="$t('hint_service')"
      :mobile="contentData.mobile"
      :submitTitle="'select-title'"
      :loading="contentData.loading"
      :errorObj="error"
      v-on:cancel="onCancelServiceSelect"
      v-on:select="onServiceSelect"
      class="service-btn-panel"
    />
  </div>
</template>

<script>

//$0.scrollIntoViewIfNeeded, now animate
import ClinicService from '../components/Clinics/ClinicService.vue';
import ButtonPanel from '../components/ButtonPanel.vue';
import ErrorOverlay from '../Overlays/ErrorOverlay.vue';

import { adjustDetailsToOpenFit } from '../helpers/componentHelper';
import { getSvg } from '../helpers/assetHelper';

export default {
  props: {
    contentData: Object,
  },
  components: {
    ClinicService,
    ButtonPanel,
    ErrorOverlay,
  },
  data() {
    return {
      detailsOpen: false,
      openService: null,
      openServiceId: null,
      filteredServices: [],
      error: null,
      sluzbaIcon: null,
      query: null
    };
  },
  updated: function () {
    this.$nextTick(function () {
      this.detailsAdjuster();
    });
  },
  computed: {
    showSearchbar() {
      if (this.query) return true; //dont hide when searching lol
      return this.filteredServices.length >= 10;
    }
  },
  watch: {
    'contentData.services': function () {
      this.sortServices();
      if (this.openServiceId) {
        this.onServiceOpen(this.openServiceId);
        this.detailsAdjuster();
      } else {
        this.openFirstService();
        this.adjustDetailHeight();
      }
    },
    openService: function (newVal) {
      let newError = null;
      if (newVal.staffs.length === 0)
        newError = {
          title: 'staff-not-avail-title',
          subtitle: 'staff-not-avail-subtitle',
          subtitleMobile: 'staff-not-avail-subtitle-mobile',
          icon: 'zamestnanec_error',
        };

      this.error = newError;
    },
  },
  methods: {
    updateSearchQuery() {
      const el = this.$refs.searchBarInput;
      if (!el) return;

      this.query = el.value;
      this.sortServices();
    },
    onServiceOpen(id) {
      this.detailsOpen = true;
      this.openServiceId = id;
      this.updateSelectedService();
      this.contentData.onOpen(id);
    },
    onServiceSelect() {
      this.contentData.onSelect(this.openServiceId);
    },
    onCancelServiceSelect() {
      this.$router.go(-1);
    },
    openFirstService() {
      if (this.filteredServices.length > 0) this.onServiceOpen(this.filteredServices[0].idService);
    },
    updateSelectedService() {
      if (!this.openServiceId) return;

      this.openService = this.filteredServices.find((s) => s.idService === this.openServiceId);
    },
    detailsAdjuster() {
      const openEl = document.querySelector('.service-item-open');
      const parentEl = document.querySelector(".service-selection");
      const detailEl = document.querySelector(".service-details-wrapper");

      if (!detailEl) return;

      //now adjust height
      this.adjustDetailHeight();
      adjustDetailsToOpenFit(parentEl, openEl, detailEl);
    },
    adjustDetailHeight() {
      const openEl = document.querySelector('.service-item-open');
      const detailEl = this.$refs.detailEl;
      if (!detailEl) return;

      const openHeight = openEl.getBoundingClientRect().height - 30;
      detailEl.style.minHeight = openHeight + 'px';
    },
    sortServices() {
      const copyServices = [...this.contentData.services];
      this.filteredServices = copyServices.filter((c) => c.staffs && c.staffs.length > 0 && (!this.query ? true : c.name.includes(this.query)));
    },
  },
  async created() {
    if (!this.contentData.services) return;
    this.openFirstService();
    this.sortServices();

    this.sluzbaIcon = await getSvg('sluzba-ic.svg');
  },
  mounted() {
    if (this.contentData.selectedServiceId) {
      this.onServiceOpen(this.contentData.selectedServiceId);
      this.detailsAdjuster();
      this.adjustDetailHeight();
    } else {
      this.openFirstService();
      this.adjustDetailHeight();
    }
  },
};
</script>

<style>
.service-selection {
  width: 100%;
  align-items: flex-start;
  height: 100%;
  overflow-y: scroll;
}
.service-selection .service-details-description-wrapper {
  color: var(--service-description-c);
}
.service-selection .scroller {
  flex-flow: row nowrap;
}
.service-selection .no-services {
  font-size: 20px;
}
.clinic-services-wrapper {
  width: 49%;
  padding-bottom: 10px;
}
.service-details {
  width: 49%;
  margin-right: 6%;
  position: relative;
  height: fit-content;
}
.service-details-wrapper {
  padding: 20px 0px;
  padding-left: 25px;
  padding-right: 17px;
  align-items: center;
  background-color: var(--select-item-bg-color);
  border-radius: var(--content-item-border-radius);
  border-top-left-radius: 0;
  text-align: left;
  min-height: 120%;
  width: 100%;
  position: absolute;
  height: fit-content;
}
.service-details-wrapper p {
  font-size: 13px;
  overflow: hidden;
  font-weight: 300;
  line-height: 20px;
}
.service-btn-panel {
  bottom: 0;
  margin-top: 10px;
}
.searchbar-wrapper {
  position: relative;
  margin: 0 10%;
  padding: 10px 10px;
  padding-right: 45px;
  display: flex;
  align-items: center;
  border: 1px solid #BEBEBE;
  border-radius: 5px;
  width: 100%;
  margin-right: 30px;
}
.searchbar-wrapper.active {
  margin-right: 0;
}
.searchbar-wrapper input {
  color: #BEBEBE;
  background-color: transparent;
  width: 100%;
  height: 100%;
  display: block;
  border: none;
  outline: none;
  padding: 5px 0;
  font-size: 16px;
}
.searchbar-wrapper .search-icon {
  width: 25px;
  height: 25px;
  right: 10px;
  position: absolute;
  cursor: pointer;
}

@media only screen and (max-width: 850px) {
  .service-details-wrapper {
    display: none !important;
  }
  .clinic-services-wrapper {
    padding: 0 5px;
    padding-bottom: 10px;
    box-sizing: border-box;
    width: 100%;
  }
}
</style>

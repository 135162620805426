<template>
  <a class="d-flex flex-row" :href="href" :target="target">
    <div class="link-icon" v-if="iconSrc" v-html="iconSrc"></div>
    <img v-if="false" :src="require('@/assets/icons/' + icon + '.svg')" alt="link-icon" />
    {{ text }}
  </a>
</template>

<script>
import { getSvg } from '../helpers/assetHelper';
export default {
  props: ['href', 'target', 'text', 'icon'],
  data() {
    return {
      iconSrc: null
    }
  },
  methods: {
    async getIcon() {
        this.iconSrc = await getSvg(this.icon+".svg");
    }
  },
  created() {
    this.getIcon();
  }
};
</script>

<style>
.link-icon svg {
  max-width: 16px;
  max-height: 16px;
}
</style>
<style scoped>
a {
  text-decoration: none;
  color: var(--primary-lighter);
  align-items: center;
}
img , .link-icon{
  width: 16px;
  height: 16px;
  margin-right: 5px;
}
@media only screen and (max-width: 850px) {
  img {
    margin-right: 10px;
  }
}
</style>

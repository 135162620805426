import Vue from 'vue';
import VueRouter from 'vue-router';
import ClinicSelection from '../views/ClinicSelection.vue';
import ServiceSelection from '../views/ServiceSelection.vue';
import StaffSelection from '../views/StaffSelection.vue';
import ClientForm from '../views/ClientForm.vue';
import Summary from '../views/Summary.vue';
import CancelOrder from '../views/CancelOrder.vue';
import NotFound from '../views/NotFound.vue';

import routeNames from "../routes.json";

Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/clinicSelection',
  },
  {
    path: '/clinicSelection',
    name: routeNames.CLINIC_SELECT,
    component: ClinicSelection,
    props: true,
  },
  {
    path: '/serviceSelection',
    name: routeNames.SERVICE_SELECT,
    component: ServiceSelection,
    props: true,
  },
  {
    path: '/staffSelection',
    name: routeNames.STAFF_SELECT,
    component: StaffSelection,
    props: true,
  },
  {
    path: '/clientForm',
    name: routeNames.CLIENT_FORM,
    component: ClientForm,
    props: true,
  },
  {
    path: '/summary',
    name: routeNames.SUMMARY,
    component: Summary,
    props: true,
  },
  {
    path: '/Booking/Cancel',
    name: routeNames.CANCEL_ORDER,
    component: CancelOrder,
    props: true,
  },
  {
    path: "/notfound",
    name: routeNames.NOTFOUND,
    component: NotFound,
    props: true
  },
  {
    path: "*",
    redirect: () => {
      return {path: "notfound"}
    }
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;

<template>
  <div :class="['clinic-wrapper', 'flex-layout-list-item', 'd-flex', 'flex-column', isSelect ? 'clinic-wrapper-open' : '']" v-on:click="onClinicOpen">
    <ClinicOverview :icon="icon" :isInactive="inactive" :city="city" :street="street" :post="post" :name="clinicData.name" :iconId="iconId" :logo="logo" />
    <div class="clinic-mobile-details flex-column" v-if="isSelect && open">
      <AppText v-if="clinicData.title" class="clinic-subtitle-mobile" :text="clinicData.title"/>
      <OpenHours class="details-open-hours" :dateList="detailsData.workHourList" />
      <AppText class="desc-mobile" v-if="detailsData.description" :text="detailsData.description" />
      <LinkText class="clinic-contact-label" v-if="detailsData.phone" :icon="'tel'" :href="`tel:${detailsData.phone}`" :text="detailsData.phoneFormated" />
      <LinkText class="clinic-contact-label" v-if="detailsData.email" :icon="'mail'" :href="`mailto:${detailsData.email}`" :text="detailsData.email" />
      <LinkText class="clinic-contact-label" :target="'_blank'" v-if="detailsData.twitter" :icon="'twitter'" :href="detailsData.twitter" :text="'Twitter'"/>
      <LinkText class="clinic-contact-label" :target="'_blank'" v-if="detailsData.facebook" :icon="'facebook'" :href="detailsData.facebook" :text="'Facebook'"/>
      <LinkText class="clinic-contact-label" :target="'_blank'" v-if="detailsData.web" :icon="'web'" :href="detailsData.web" :text="getTextUrl(detailsData.web)"/>
      <div class="map-wrapper">
        <img v-if="!mapError" @click="onMapPointClick" :src="require('@/assets/icons/map-pointer.svg')" class="map-pointer" alt="pointer">
        <img class="map" @click="onMapPointClick" :src="getMapUrl(detailsData.mapId)" @load="onMapLoad" @error="onMapError" alt="Mapa">
      </div>
    </div>
  </div>
</template>

<script>
import ClinicOverview from './ClinicOverview.vue';
import AppText from '../Text.vue';
import LinkText from '../LinkText.vue';
import OpenHours from '../OpenHours.vue';
import { BASE_URL } from '../../api/http';
import { getTextUrl } from '../../helpers/textHelper';

export default {
  name: 'Clinic',
  components: {
    ClinicOverview,
    AppText,
    LinkText,
    OpenHours,
  },
  props: {
    clinicData: Object,
    isSelect: Boolean,
    detailsData: Object,
    iconId: Number,
    icon: String,
    open: Boolean
  },
  data() {
    return {
      city: '',
      street: '',
      post: '',
      logo: null,
      inactive: false,
      mapError: false
    };
  },
  methods: {
    onClinicOpen() {
      this.$emit('open-clinic', this.clinicData.id);
    },
    getMapUrl(id) {
      return `${BASE_URL}/getfile?idFile=${id}&size=100x100&imageAsType=0`
    },
    onMapError(ev) {
      this.mapError = true;
      ev.target.style.display = "none";
    },
    onMapLoad(ev) {
      this.mapError = false;
      ev.target.style.display = "block";
    },
    onMapPointClick() {
      window.open(`https://maps.google.com/?q=${this.street},${this.post},${this.city}`);
    },
    getTextUrl(url) {
      return getTextUrl(url)
    }
  },
  created() {
    const { city, street, post, services, logo, logoSquare } = this.clinicData;
    this.city = city;
    this.street = street;
    this.post = post;
    this.inactive = services.length === 0;

    this.logo = logo === logoSquare ? null : logoSquare;
  },
};
</script>

<style>
.clinic-wrapper {
  margin-right: 30px !important;
  position: relative;
}
.clinic-wrapper .clinic-contact-label {
    color: var(--clinic-contact-c) !important;
}
.clinic-wrapper-open {
  margin-right: -20px !important;
  background-color: var(--select-item-bg-color) !important;
}
.clinic-mobile-details {
  padding: 0 15px;
  box-sizing: border-box;
  display: none;
}
.clinic-subtitle-mobile {
  text-align: left;
  margin-top: 20px;
  font-size: 15px;
}
.clinic-mobile-details .details-open-hours {
  margin: 20px 0;
}
.clinic-mobile-details .details-text {
  text-align: left;
  margin-top: 20px;
  font-weight: 300;
}
.clinic-mobile-details a {
  margin: 5px 0;
}
.desc-mobile {
  margin-bottom: 15px;
  text-align: left;
  font-weight: 300;
  color: var(--clinic-description-c);
  font-size: 13px;
}
@media only screen and (max-width: 850px) {
  .clinic-wrapper {
    margin-right: 0 !important;
    margin-left: var(--item-ml-mobile) !important;
  }
  .safari-style .clinic-wrapper {
    margin-right: 10px !important;
  }
  .clinic-mobile-details {
    display: flex;
  }
}
</style>

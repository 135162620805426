<template>
  <div class="staff-details-wrapper" v-bind:style="showBorder ? 'border-bottom: none !important;' : ''">
    <div class="d-flex flex-column">
      <div class="d-flex flex-row" style="align-items: center; margin-bottom: 20px">
        <img :src="!photoSrc ? contentData.defaultStaffIcon : photoSrc" alt="staff-photo" class="details-staff-photo" />
        <h3 class="details-staff-name">{{ isTemplate ? $t(name) : name }}</h3>
        <!-- here comes staff description when ready -->
      </div>
      <div class="staff-time-content">
        <div class="staff-datepicker-wrapper d-flex flex-column">
          <span class="staff-section-title" style="margin-bottom: 15px">{{ $t('select-date-title') }}</span>
          <datepicker
            :disabled-dates="disabledDates"
            full-month-name
            :language="activeLangSet"
            class="staff-datepicker"
            :highlighted="highlighted"
            v-model="contentData.selectedDay"
            :monday-first="true"
            :inline="true"
            v-on:changedMonth="onDatepickerMonthChange"
            v-on:selected="onDayChange"
          ></datepicker>
        </div>
        <div class="time-picker-wrapper">
          <DateTimeSelector ref="noonPicker" class="time-picker noon-time-picker" :afternoon="false" :selectDate="contentData.selectedTime" :dates="noonTimes" v-on:selectTime="onTimeChange" />
          <DateTimeSelector class="time-picker afternoon-time-picker" :afternoon="true" :selectDate="contentData.selectedTime" :dates="afternoonTimes" v-on:selectTime="onTimeChange" />
        </div>
      </div>
      <div class="staff-more-dates-wrapper">
        <span v-if="Object.values(filteredAvalDates).length > 0" class="staff-section-title">{{ $t('more-free-dates-title') }}</span>
        <div class="details-more-dates-list">
          <AvalMonthDates
            :dateLimit="dateDisplayLimit"
            class="details-more-dates-aval"
            v-on:open="onAvalMonthClick"
            :rawDate="mKey"
            v-for="(d, mKey) in filteredAvalDates"
            :key="mKey"
            v-bind:style="contentData.selectedMonth === Date.parse(mKeyToDateStamp(mKey)) ? 'display: none !important;' : ''"
            :monthYear="getMonthAndYearString(mKey)"
            :dates="countAvalDates(d)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import DateTimeSelector from '../dateTimeSelector.vue';
import AvalMonthDates from '../AvalMonthDates.vue';
import { dateToMonthTmstp, getEarliestDate, getEarliestDateInMonth, monthYearToTimestamp, timestampToMonthYear, timestampToNextMonth, timestampToPreviousMonth } from '../../helpers/timesOfDayHelper';

import { cs, en, de, ru, uk } from 'vuejs-datepicker/dist/locale';

export default {
  components: {
    DateTimeSelector,
    AvalMonthDates,
  },
  props: ['name', 'photoSrc', 'contentData', 'datesAval', 'highlighted', 'afternoonTimes', 'noonTimes', 'dateDisplayLimit', 'monthLimit', 'isTemplate', 'showBorder'],
  data() {
    return {
      parsedDatesAval: [],
      cs: cs,
      selectFirst: true,
      disabledDates: {
        to: undefined,
        from: undefined,
      },
      filteredAvalDates: {},
      activeLangSet: null,
      langSets: {
        cz: cs,
        en: en,
        de: de,
        uk: uk,
        ru: ru
      },
      scrolledTo: false
    };
  },
  created() {
    this.filterDates();
    this.setDatepickerBounds();
    this.setLangSet(this.contentData.currLang);
    if (this.contentData.selectedTime) this.selectFirst = false;
  },
  mounted() {
    this.selectFirstTimeItem();
    this.scrollToTimes();
  },
  updated() {
    this.selectFirstTimeItem();
    this.markHighlighted();
    if (!this.selectFirst && this.contentData.selectedTime) document.querySelector(".time-item[data-date='" + this.contentData.selectedTime + "']").click();
  },
  watch: {
    'contentData.selectedMonth': function () {
      this.filterDates();
      this.selectFirst = true;
      this.$nextTick(() => {
        this.markHighlighted();
      });
    },
    'contentData.selectedDay': function () {
      this.selectFirst = true;
    },
    'contentData.currLang': function (newLang) {
      this.setLangSet(newLang);
    },
    datesAval: function () {
      this.setDatepickerBounds();
      this.selectFirst = true;
    },
  },
  methods: {
    scrollToTimes() { //this is the func that helps users on ios devices figure out theres scrollable content! Scrolls tiny bit down to show the time section!
      if (!this.contentData.mobile || !this.contentData.isSafari || this.scrolledTo) return;

      const scrollOffset = 10;
      const mainStaffWrp = document.querySelector(".main-staff-wrapper");
      const vHeight = mainStaffWrp.clientHeight; //ewww ugly hack!! maybe pass a ref from parent component through prop or something later

      let targetParams = this.$refs.noonPicker.$el;

      if (!targetParams) return console.warn("Failed to get picker params");
      targetParams = targetParams.getBoundingClientRect();
      if (targetParams.y === 0 && targetParams.x === 0) return setTimeout(() => { //mounted is epicaly trolled and the ref isnt actually probably ready yet, so try again
        this.scrollToTimes();
      }, 100);

      if (vHeight >= targetParams.y) return;
      const scrollByYVal = (targetParams.y - vHeight) + scrollOffset; 
      mainStaffWrp.scrollTop = scrollByYVal;
      this.scrolledTo = true;
    },
    selectFirstTimeItem() {
      if (!this.selectFirst) return;
      const timeItem = document.querySelector('.time-item');
      if (timeItem) timeItem.click();

      this.selectFirst = false;
    },
    getLatestDate() {
      let latestM,
        prevDate = 0;
      for (const key in this.datesAval) {
        if (monthYearToTimestamp(key) > prevDate) latestM = this.datesAval[key];
      }
      const sortedDays = Object.keys(latestM).sort((a, b) => b - a);
      return latestM[sortedDays[0]][0].dateTime;
    },
    setDatepickerBounds() {
      let preprocFrom = moment(this.getLatestDate()).add(1, "d").startOf('day').subtract(5, "minutes"); //this will set the date back to the day that last available date is on -> to last possible date available (23:55) - 00:00 is the date of next day

      this.disabledDates.to = new Date(getEarliestDate(this.datesAval, true));
      this.disabledDates.from = preprocFrom.toDate();
    },
    filterDates() {
      const currentMonth = this.contentData.selectedMonth;
      const datesArray = Object.values(this.datesAval);
      let assignedTimes = 0;

      if (datesArray.length === 0) return;

      let parsedObj = {};

      for (const key in this.datesAval) {
        if (dateToMonthTmstp(Object.values(this.datesAval[key])[0][0].dateTime) > currentMonth && assignedTimes < this.monthLimit) {
          assignedTimes++;
          parsedObj = { ...parsedObj, [key]: this.datesAval[key] };
        }
      }

      this.filteredAvalDates = parsedObj;
    },
    setLangSet(key) {
      if (!this.langSets[key]) return;

      this.activeLangSet = this.langSets[key];
    },
    onMonthChange(m) {
      this.$emit('month-change', m);
    },
    onDayChange(d) {
      let comparedSelectedDay = Number.isInteger(this.contentData.selectedDay) ? this.contentData.selectedDay : Date.parse(this.contentData.selectedDay);
      if (Date.parse(d) === comparedSelectedDay) return;
      this.$emit('day-change', d);
    },
    onTimeChange(t) {
      this.$emit('time-change', t);
    },
    mKeyToDateStamp(mKey) {
      const [month, year] = mKey.split('_');
      return `${month}-01-${year}`;
    },
    onAvalMonthClick(m) {
      this.onMonthChange(this.mKeyToDateStamp(m));
      this.onDayChange(getEarliestDateInMonth(this.datesAval, m));
      document.querySelector('.staff-details-wrapper').scrollIntoView();
    },
    onDatepickerMonthChange(m) {
      const mKey = timestampToMonthYear(m, true);
      this.onMonthChange(m);
      let earliestDate = getEarliestDateInMonth(this.datesAval, mKey);
      if (earliestDate === false) {
        return this.onDatepickerMonthChange(this.contentData.selectedMonth > Date.parse(m) ? timestampToPreviousMonth(Date.parse(m)) : timestampToNextMonth(Date.parse(m)));
      }
      this.onDayChange(getEarliestDateInMonth(this.datesAval, mKey));
    },
    getMonthAndYearString(m) {
      let [month, year] = m.split('_');
      let str = this.$t(month);
      str += ' ' + year;

      return str;
    },
    countAvalDates(d) {
      let totalCount = 0;
      totalCount = Object.values(d).length;
      return totalCount;
    },
    markHighlighted() {
      document.querySelectorAll('.highlighted').forEach((el) => {
        let dayNum = el.innerText;
        let dates = this.datesAval[timestampToMonthYear(this.contentData.selectedMonth, true)][dayNum];
        let noon = false,
          afternoon = false;
        if (!dates) return;

        dates.forEach((d) => {
          if (d.afternoon) afternoon = true;
          else noon = true;
        });

        el.classList.remove(['single', 'double']);

        if (!noon || !afternoon) el.classList.add('single');
        else el.classList.add('double');
      });
    },
  },
};
</script>

<style>
.staff-details-wrapper h3 {
  font-weight: 400;
  font-size: 16px;
  color: var(--staff-detail-title-c);
}
.staff-details-wrapper .staff-section-title {
  font-weight: 300;
  font-size: 12px;
  color: var(--staff-detail-section-t-c);
}
.details-staff-photo {
  margin-right: 18px;
  width: 64px;
  border-radius: 50%;
}
.staff-time-content {
  margin-bottom: 30px;
  display: flex;
  flex-flow: row nowrap;
}
.staff-more-dates-wrapper {
  margin-bottom: 40px;
  text-align: left;
}
.vdp-datepicker__calendar header {
  line-height: 20px;
}
.vdp-datepicker__calendar {
  border: 1px solid transparent !important;
  border-radius: var(--content-item-border-radius) !important;
}
.vdp-datepicker__calendar .day__month_btn {
  text-transform: capitalize;
  font-size: 15px;
  color: var(--staff-calendar-month-c);
  width: 40%;
  pointer-events: none !important;
  font-weight: 300;
}
.vdp-datepicker__calendar .prev,
.vdp-datepicker__calendar .next {
  width: 5% !important;
  display: flex;
}
.vdp-datepicker__calendar .prev::after {
  border-right-color: var(--staff-calendar-chevron-c) !important;
}
.vdp-datepicker__calendar .next::after {
  border-left-color: var(--staff-calendar-chevron-c) !important;
}
.vdp-datepicker__calendar .prev:hover,
.vdp-datepicker__calendar .next:hover {
  background-color: transparent !important;
  opacity: 0.8;
}
.vdp-datepicker__calendar .prev.disabled,
.vdp-datepicker__calendar .next.disabled {
  visibility: hidden;
}
.vdp-datepicker__calendar .prev.disabled::after {
  border-right-color: var(--darkish-grey) !important;
}
.vdp-datepicker__calendar .next.disabled::after {
  border-left-color: var(--darkish-grey) !important;
}

.vdp-datepicker__calendar .prev {
  justify-content: flex-end;
  margin-left: 25%;
}
.vdp-datepicker__calendar .next {
  justify-content: flex-start;
  margin-right: 25%;
}
.staff-datepicker .vdp-datepicker__calendar {
  width: 100%;
  padding: 0 2.5%;
  box-shadow: var(--staff-detail-section-item-shadow);
  background-color: var(--staff-detail-section-item-bg);
}
.staff-datepicker .vdp-datepicker__calendar .day {
  width: 40px;
  margin: 2.5px calc(6.785714285714286% - 19px);
  border-radius: 50%;
  padding: 0;
  color: var(--staff-calendar-days-i-c);
  position: relative;
  pointer-events: none;
  opacity: 0.7;
  font-weight: 300;
  font-size: 13px;
}
.staff-datepicker .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover {
  border-color: var(--staff-detail-date-a-hov-c) !important;
}
.staff-datepicker .vdp-datepicker__calendar .day-header {
  width: 40px;
  margin: 2.5px calc(6.785714285714286% - 19px);
  border-radius: 50%;
  padding: 0;
  text-transform: capitalize;
  color: var(--staff-calendar-days-h-c);
  font-size: 14px !important;
  font-weight: 300;
  opacity: 0.5;
}
.staff-datepicker .vdp-datepicker__calendar .highlighted {
  color: var(--staff-calendar-days-a-c);
  background-color: transparent !important;
  font-weight: bold;
  pointer-events: initial !important;
  opacity: 1;
}
.staff-datepicker .vdp-datepicker__calendar .highlighted::before {
  position: absolute;
  bottom: -15px;
  color: var(--primary);
}
.staff-datepicker .vdp-datepicker__calendar .highlighted.single::before {
  content: '\2022' !important;
  left: calc(50% - 2px);
}
.staff-datepicker .vdp-datepicker__calendar .highlighted.double::before {
  content: '\2022              \2022' !important;
  left: calc(50% - 7px);
}
.staff-datepicker .vdp-datepicker__calendar .selected {
  color: var(--staff-calendar-days-sel-c) !important;
  background-color: var(--staff-calendar-days-sel-bg) !important;
  font-weight: normal !important;
}
.staff-datepicker .vdp-datepicker__calendar .selected::before {
  color: var(--staff-calendar-days-sel-c) !important;
}
.staff-datepicker-wrapper {
  width: 48%;
  display: flex;
  text-align: left;
  margin-right: 2%;
}
.time-picker-wrapper {
  width: 48%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  box-sizing: border-box;
  margin-left: 2%;
}
.details-more-dates-aval {
  width: 48%;
  margin: 10px 0;
  margin-right: 2%;
}
.details-more-dates-aval:nth-child(2) {
  margin-right: 0;
  margin-left: 2%;
}
.details-more-dates-list {
  display: flex;
  flex-flow: row nowrap;
}
@media only screen and (max-width: 850px) {
  .staff-time-content {
    flex-flow: column nowrap;
  }
  .staff-datepicker-wrapper,
  .time-picker-wrapper {
    width: 100%;
  }
  .time-picker-wrapper {
    margin-left: 0;
  }
  .details-more-dates-list {
    flex-flow: column nowrap;
  }
  .details-more-dates-aval {
    width: 100%;
  }
  .details-more-dates-aval:nth-child(2) {
    margin-right: 0;
    margin-left: 0%;
  }
  .details-more-dates-aval:nth-child(1) {
    margin-right: 0;
    margin-left: 0%;
  }
  .staff-datepicker .vdp-datepicker__calendar .day {
    width: 30px;
    height: 30px;
    line-height: 30px;
    box-sizing: border-box;
    margin: 5px calc(6.785714285714286% - 14px);
  }
  .staff-datepicker .vdp-datepicker__calendar .day-header {
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin: 0 calc(6.785714285714286% - 14px);
  }
  .staff-datepicker .vdp-datepicker__calendar .highlighted::before {
    bottom: -12px;
    left: calc(50% - 6px) !important;
  }
  .details-staff-photo {
    margin-right: 20px;
  }
  .details-staff-name {
    text-align: left;
    color: var(--staff-detail-title-c);
  }
  .vdp-datepicker__calendar .prev::after {
    border-width: 10px !important;
    border-right-width: 15px !important;
  }
  .vdp-datepicker__calendar .next::after {
    border-width: 10px !important;
    border-left-width: 15px !important;
  }
}
</style>

<template>
  <div class="aval-month-dates-wrapper d-flex flex-row" v-on:click="onClick">
    <span class="span-date-month">{{ monthYear }}</span>
    <div class="d-flex flex-row date-spans-wrapper">
      <span class="span-date-count">{{ limitDates(dates) }} {{ $t('more-free-dates-date') }}</span>
      <img class="chevron-right" :src="require('@/assets/icons/chevron-right.svg')" alt="chevron-icon" />
    </div>
  </div>
</template>

<script>
export default {
  props: ['monthYear', 'dates', 'rawDate', 'dateLimit'],
  methods: {
    onClick() {
      this.$emit('open', this.rawDate);
    },
    limitDates(d) {
      if (d > this.dateLimit) return `${this.dateLimit}+`;

      return d;
    },
  },
};
</script>

<style>
.aval-month-dates-wrapper {
  cursor: pointer;
  border-radius: var(--content-item-border-radius);
  background-color: var(--staff-detail-section-item-bg);
  justify-content: space-between;
  height: 70px;
  align-items: center;
  padding: 0 2vw;
  box-sizing: border-box;
  box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.13);
}
.aval-month-dates-wrapper .span-date-count {
  font-weight: bold;
  color: var(--staff-more-dates-dates-c);
}
.aval-month-dates-wrapper .span-date-month {
  color: var(--staff-more-dates-month-c);
  font-weight: 300;
  font-size: 13px;
}
.date-spans-wrapper {
  align-items: center;
  font-size: 13px;
}
.aval-month-dates-wrapper .chevron-right {
  margin-left: 15px;
  width: 10px;
  color: var(--staff-more-dates-chevron-c);
}
.aval-month-dates-wrapper:hover {
  box-shadow: 2px 2px 10px 5px var(--staff-more-dates-hover-c);
}
</style>

<template>
  <div v-on:click="onClick" :class="['btn', size ? 'btn-' + size : '', background == true ? (greyTheme === true ? 'btn-bg-grey' : 'btn-bg') : 'btn-transparent', disabled === true ? 'disabled' : '']">
    <span>{{ title }}</span>
  </div>
</template>

<script>
export default {
  name: 'AppButton',
  props: {
    title: {
      type: String,
      default: '',
    },
    size: {
      type: String,
    },
    background: {
      type: Boolean,
    },
    action: {
      type: String,
    },
    greyTheme: Boolean,
    disabled: Boolean,
  },
  methods: {
    onClick(e) {
      e.stopPropagation();
      this.$emit(this.action);
    },
  },
};
</script>

<style scoped>
div {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.btn-bg {
  background-color: var(--button-panel-select-bg-c);
  color: var(--button-panel-select-text-c);
  border-radius: 5px;
  padding: 15px 40px;
}
.btn-bg:hover {
  background-color: var(--button-panel-select-bg-hover-c);
}
.btn-bg-grey {
  background-color: var(--button-panel-cancel-bg-c);
  color: var(--button-panel-cancel-text-c);
  border-radius: 5px;
  padding: 15px 40px;
  border: 1px solid var(--button-panel-cancel-border-c);
}
.btn-bg-grey:hover {
  background-color: var(--button-panel-cancel-bg-hover-c);
  border-color: var(--button-panel-cancel-border-hover-c);
}
.btn-bg-grey .disabled {
  border-color: #D8D8D8;
  color: #D8D8D8;
}
.disabled {
  cursor: default !important;
  pointer-events: none !important;
  background-color: #D8D8D8 !important;
  color: var(--white) !important;
}
.btn-transparent {
  text-decoration: underline;
  color: var(--dark-grey);
}
</style>

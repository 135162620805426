<template>
  <div class="d-flex flex-column clinic-master" style="height: 100%; justify-content: space-between">
    <ErrorOverlay v-if="!contentData.loading && sortedClinis.length === 0 && initDone" :icon="require('@/assets/icons/location_overlay.svg')" :title="$t('no-clinics-available')" />
    <div v-if="!contentData.loading && sortedClinis.length > 0" ref="parentEl" class="clinic-selection d-flex flex-row">
      <div class="scroller d-flex" style="width: 100%" v-bind:style="sortedClinis.length === 0 ? 'justify-content: center; flex-flow: column nowrap;' : ''">
        <div class="clinics-wrapper flex-layout-list">
          <Clinic
            :icon="clinicIcon"
            :iconId="contentData.iconId"
            :detailsData="detailsData"
            :isSelect="c.id === openClinicId"
            :open="detailsOpen"
            v-for="c in sortedClinis"
            :key="c.id"
            :clinicData="c"
            v-on:open-clinic="onClinicOpen"
          />
        </div>
        <div class="clinic-details" v-if="detailsOpen && contentData.mobile === false">
          <div ref="detailEl" class="clinic-details-wrapper">
            <OpenHours :dateList="sortedWrkHrList" />
            <AppText v-if="openClinic.description" :text="openClinic.description ? openClinic.description : $t('clinic-no-description')" />
            <div class="d-flex flex-row-wrap" style="width: 100%">
              <LinkText class="clinic-contact-label" v-if="openClinic.phone" :icon="'tel'" :href="`tel:${openClinic.phone}`" :text="formatPhone(null, openClinic.phone)" />
              <LinkText class="clinic-contact-label" v-if="openClinic.email" :icon="'mail'" :href="`mailto:${openClinic.email}`" :text="openClinic.email" />
              <LinkText class="clinic-contact-label" :target="'_blank'" v-if="openClinic.twitter" :icon="'twitter'" :href="formatUrl(openClinic.twitter)" :text="'Twitter'"/>
              <LinkText class="clinic-contact-label" :target="'_blank'" v-if="openClinic.facebook" :icon="'facebook'" :href="formatUrl(openClinic.facebook)" :text="'Facebook'"/>
              <LinkText class="clinic-contact-label" :target="'_blank'" v-if="openClinic.web" :icon="'web'" :href="formatUrl(openClinic.web)" :text="getTextUrl(openClinic.web)"/>
            </div>
            <div class="map-wrapper">
              <img v-if="!mapError" @click="onMapPointClick" :src="require('@/assets/icons/map-pointer.svg')" class="map-pointer" alt="pointer">
              <img @click="onMapPointClick" :src="getMapUrl(openClinic.geoCache ? openClinic.geoCache.idMap : null)" @load="onMapLoad" @error="onMapError" class="map" alt="Mapa">
            </div>
          </div>
        </div>
      </div>
    </div>
    <ButtonPanel
      v-if="!contentData.loading && sortedClinis.length > 0"
      :hintComplete="openClinicId || sortedClinis.length === 0 ? true : false"
      :iconId="contentData.iconId"
      :hintText="$t('hint_clinic')"
      :mobile="contentData.mobile"
      :cancelDisabled="true"
      :submitTitle="'select-title'"
      :loading="false"
      :errorObj="error"
      v-on:cancel="onCancelClinicSelect"
      v-on:select="onSelectClinic"
      :disabled="openClinic === null"
      class="sticky"
    />
  </div>
</template>

<script>
import Clinic from '../components/Clinics/Clinic.vue';
import AppText from '../components/Text.vue';
import LinkText from '../components/LinkText.vue';
import OpenHours from '../components/OpenHours.vue';
import ButtonPanel from '../components/ButtonPanel.vue';
import ErrorOverlay from '../Overlays/ErrorOverlay.vue';

import { formatPhoneNum } from '../helpers/formatHelper';
import { adjustDetailsToOpenFit } from '../helpers/componentHelper';
import { dayTypeToId } from '../helpers/openHourHelper';
import { getSvg } from '../helpers/assetHelper';
import { BASE_URL } from '../api/http';
import { getTextUrl, URLize } from '../helpers/textHelper';

export default {
  props: {
    contentData: Object,
  },
  components: {
    Clinic,
    AppText,
    LinkText,
    OpenHours,
    ButtonPanel,
    ErrorOverlay,
  },
  data() {
    return {
      detailsOpen: false,
      openClinic: null,
      openClinicId: null,
      error: null,
      detailMarginTop: 0,
      sortedClinis: [],
      initDone: false,
      sortedWrkHrList: [],
      clinicIcon: null,
      mapError: false
    };
  },
  computed: {
    detailsData() {
      if (!this.openClinic) return {};

      return { twitter: this.openClinic.twitter, facebook: this.openClinic.facebook, web: this.openClinic.web, phone: this.openClinic.phone, mapId: this.openClinic.geoCache ? this.openClinic.geoCache.idMap : null, description: this.openClinic.description, phoneFormated: this.formatPhone(null, this.openClinic.phone), email: this.openClinic.email, workHourList: this.sortedWrkHrList }
    }
  },
  watch: {
    "contentData.selectedClinicId": function(newVal) {
      if (newVal) {
        this.onClinicOpen(newVal);
        //this.detailsAdjuster();
      } else this.openFirstClinic();
    },
    'contentData.clinicsList': function () {
      this.sortClinics();
      if (this.openClinicId) {
        this.onClinicOpen(this.openClinicId);
        //this.detailsAdjuster();
      } else this.openFirstClinic();
    },
    openClinic: function (newVal) {
      let newError = null;
      if (newVal.services.length === 0)
        newError = {
          title: 'no-service-title',
          subtitle: 'no-service-subtitle',
          subtitleMobile: 'no-service-subtitle-mobile',
          icon: 'sluzba_ic_error',
        };

      this.error = newError;
    },
  },
  updated: function () {
    this.$nextTick(function () {
      this.detailsAdjuster();
      //this.updatePaddingRight();
    });
  },
  methods: {
    formatUrl(url) {
      return URLize(url);
    },
    formatPhone(prefix, num) {
      return formatPhoneNum(prefix, num);
    },
    onClinicOpen(id) {
      this.detailsOpen = true;
      this.openClinicId = id;
      this.updateSelectedClinic();
      this.sortOpenHours();
      this.contentData.onOpen(this.openClinicId);
    },
    onSelectClinic() {
      this.contentData.onSelect(this.openClinicId);
    },
    onCancelClinicSelect() {
      this.$router.push('/');
    },
    onMapError(ev) {
      this.mapError = true;
      ev.target.style.display = "none";
    },
    onMapLoad(ev) {
      this.mapError = false;
      ev.target.style.display = "block";
    },
    openFirstClinic() {
      if (this.sortedClinis.length === 0) return;
      this.onClinicOpen(this.sortedClinis[0].id);
    },
    updateSelectedClinic() {
      if (!this.openClinicId) return;
      this.openClinic = this.contentData.clinicsList.find((c) => c.id === this.openClinicId);
    },
    sortClinics() {
      const copyClinics = [...this.contentData.clinicsList];
      this.sortedClinis = copyClinics.sort((a, b) => b.services.length - a.services.length).filter((c) => c.services && c.services.length > 0);
    },
    detailsAdjuster() {
      const openEl = document.querySelector('.clinic-wrapper-open');
      const parentEl = this.$refs.parentEl;
      const detailEl = this.$refs.detailEl;

      if (!detailEl) return;

      adjustDetailsToOpenFit(parentEl, openEl, detailEl);
    },
    sortOpenHours() {
      if (!this.openClinic) return;

      this.sortedWrkHrList = this.openClinic.workHourList
        .sort((a, b) => {
          return dayTypeToId(a.dayType) - dayTypeToId(b.dayType);
        })
        .filter((d) => d.opened === true);
    },
    getMapUrl(id) {
      return `${BASE_URL}/getfile?idFile=${id}&size=100x100&imageAsType=0`
    },
    updatePaddingRight() {
      let parentEl = document.querySelector(".clinic-selection");
      let clnMaster = document.querySelector(".clinic-master");
      if (!parentEl || !clnMaster) return;
      
      if (clnMaster.clientWidth != parentEl.clientWidth) parentEl.style.paddingRight = "0px";
      else {
        if (this.contentData.mobile) parentEl.style.paddingRight = "20px";
        else parentEl.style.paddingRight = "60px";
      }
    },
    onMapPointClick() {
      window.open(`https://maps.google.com/?q=${this.openClinic.street},${this.openClinic.post},${this.openClinic.city}`);
    },
    getTextUrl(url) {
      return getTextUrl(url)
    }
  },
  async created() {
    this.clinicIcon = await getSvg('pobocka-item0.svg');

    if (!this.contentData.clinicsList) return this.initDone = true;
    this.sortClinics();
    if (!this.contentData.selectedClinicId && this.sortedClinis.length == 1) this.openFirstClinic();
    else if (this.contentData.selectedClinicId) this.onClinicOpen(this.contentData.selectedClinicId);

    this.initDone = true;
  },
  mounted() {
    if (this.contentData.selectedClinicId) {
      this.onClinicOpen(this.contentData.selectedClinicId);
      this.$nextTick(() => { //hackish solution for the detail container being put on the right side
        this.detailsAdjuster();
        //this.updatePaddingRight();
      })
    } else this.openFirstClinic();

  },
};
</script>

<style>
.clinic-selection {
  width: 100%;
  align-items: flex-start;
  height: 100%;
  overflow-y: scroll;
  box-sizing: border-box;
}
.clinic-selection .open-clinic-email-ml {
  margin-left: 25%
}
.clinic-details .clinic-contact-label {
    color: var(--clinic-contact-c) !important;
    min-width: 50%;
}
.clinic-details .clinic-contact-label svg {
  stroke: var(--clinic-contact-c) !important;
}
.clinic-selection .scroller {
  flex-flow: row nowrap;
}
.clinic-selection .no-clinics {
  font-size: 20px;
}
.clinics-wrapper {
  width: 49%;
  padding-bottom: 20px;
}
.clinic-details {
  width: 49%;
  margin-right: 4%;
  position: relative;
  height: fit-content;
}
.clinic-selection .map-wrapper {
  width: 90%;
  margin: 0 auto;
  position: relative;
  margin-top: 15px;
}
.clinic-selection .map {
  max-width: 100%;
  filter: grayscale(1);
  border-radius: var(--content-item-border-radius);
  cursor: pointer;
}
.clinic-selection .map-pointer {
  position: absolute;
  left: calc(50% - 13.5px);
  top: calc(50% - 18px);
  z-index: 10;
  cursor: pointer;
}
.clinic-details-wrapper {
  background-color: var(--select-item-bg-color) !important;
  border-radius: var(--content-item-border-radius);
  border-top-left-radius: 0;
  align-items: flex-start;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  width: calc(100% + 30px);
  position: absolute;
  padding: 25px 30px;
  padding-left: 30px;
  box-sizing: border-box;
}
.clinic-border-hack {
  position: absolute;
  background-color: #00aeef1a;
  display: block;
  z-index: 10;
  width: 3px;
  height: 100%;
  opacity: 0.1;
  left: -2px;
}
.clinic-details-wrapper .open-hours-wrapper {
  margin-bottom: 20px;
}
.clinic-details-wrapper a {
  margin: 5px 0;
  font-size: 13px;
}
.clinic-details-wrapper p {
  margin-bottom: 15px;
  text-align: left;
  font-weight: 300;
  color: var(--clinic-description-c);
  font-size: 13px;
}
.sticky {
  bottom: 0;
}
.clinic-selection .button-panel-wrapper {
  margin-top: 10px;
}

.clinic-selection #link-icon-fill {
  fill: var(--clinic-contact-icon-c);
}
@media only screen and (max-width: 1080px) {
  .clinic-selection .open-clinic-email-ml {
    margin-left: 1.5%
  } 
}
@media only screen and (max-width: 850px) {
  .clinic-details-wrapper {
    display: none !important;
  }
  .clinics-wrapper {
    padding: 0 5px;
    padding-bottom: 20px;
    box-sizing: border-box;
    width: 100%;
  }
}
</style>

<template>
  <div class="missing-param-wrapper d-flex flex-row">
    <div>
      <h3>{{ title }}</h3>
      <span>{{ subtitle }}</span>
    </div>
    <img :src="require(`@/assets/icons/${icon}.svg`)" alt="error-icon" />
  </div>
</template>

<script>
export default {
  props: ['title', 'subtitle', 'icon'],
};
</script>

<style>
.missing-param-wrapper {
  color: var(--danger);
  text-align: right;
  align-items: center;
}
.missing-param-wrapper h3 {
  font-size: 18px;
}
.missing-param-wrapper span {
  font-size: 14px;
}
.missing-param-wrapper img {
  margin-left: 20px;
  width: 40px;
}
@media only screen and (max-width: 850px) {
  .missing-param-wrapper img {
    margin-left: 5px;
  }
  .missing-param-wrapper h3 {
    font-size: 12px;
  }
  .missing-param-wrapper span {
    font-size: 10px;
  }
}
</style>

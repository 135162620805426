<template>
  <div class="d-flex flex-column" style="width: 100%; height: 100%; justify-content: space-between">
    <!-- success header -->
    <div v-if="contentData.orderComplete" class="success-header d-flex">
      <img v-if="!contentData.mobile" :src="require('@/assets/icons/podekovani.svg')" alt="success-icon" class="success-icon" />
      <div class="d-flex flex-column">
        <h2>{{ $t(successTitleKey) }}</h2>
        <span>{{ $t(successSubTitleKey) }}</span>
      </div>
    </div>
    <!-- fail header -->
    <div v-if="showErrorHeader" class="success-header failed d-flex">
      <img v-if="!contentData.mobile" :src="require('@/assets/icons/cross_ic.svg')" alt="success-icon" class="success-icon" />
      <div class="d-flex flex-column">
        <h2>{{ $t(errorHeaderTitle) }}</h2>
        <span>{{ $t(errorHeaderSubtitle) }}</span>
      </div>
    </div>
    <div class="summary-wrapper d-flex flex-column">
      <div class="scroller d-flex flex-column">
        <span v-if="showNote" class="client-label">{{ $t('clinic-note-label') }}</span>
        <span class="client-name" style="margin-bottom: 20px; align-self: flex-start; margin-left: 7.5%;" v-if="showNote" v-html="contentData.clinicNote"></span>
        <span class="client-label">{{ $t('client-label') }}</span>
        <h2 class="client-name">{{ contentData.clientName }}</h2>
        <div class="summary-item-list d-flex flex-row-wrap">
          <SummaryTextItem
            :itemName="$t('selected-service-label')"
            :title="contentData.serviceTitle"
            :subtitle="contentData.serviceSubtitle"
            :imgSrc="contentData.serviceIcon"
            :isHtmlSrc="contentData.serviceIconIsHTML"
            :isRounded="true"
          />
          <SummaryTextItem :itemName="$t('selected-location-label')" :wrapperPointer="true" :onWrapperClick="contentData.onAddressClick" :title="contentData.locationTitle" :subtitle="contentData.locationSubtitle" :imgSrc="contentData.locationIcon" :imgIsIcon="true" />
          <SummaryTextItem :itemName="$t('selected-staff-label')" :subtitle="contentData.staffSubtitle" :imgSrc="contentData.staffImg" :isRounded="true" />
            <SummaryTextItem
              :errorIconSrc="contentData.dateIconError"
              :errorStyle="summaryTextErrorStyle"
              :imgIsIcon="true"
              :itemName="$t('selected-date-label')"
              :imgSrc="contentData.dateIcon"
              :title="contentData.dateTitle"
              :subtitle="contentData.dateSubtitle"
            />
            <div class="atcb" style="display:none;">
                {{getCalendarData()}}
            </div>
        </div>
        <div class="footer-summary d-flex flex-column">
          <span v-if="contentData.price"
            >{{ $t('price-label') }} <span class="footer-summary-price">{{ contentData.price }},-</span></span
          >
          <span v-if="false" class="footer-summary-error">{{ $t('submit-order-footer-error') }}</span>
        </div>
      </div>
    </div>
    <ButtonPanel
      :finishState="true"
      :iconId="contentData.iconId"
      :mobile="contentData.mobile"
      :hintText="$t(getHintMessage())"
      :hintTextIsError="contentData.orderDateError"
      :cancelDisabled="contentData.orderComplete || contentData.orderDateError"
      :orderErrorCode="contentData.errorCode"
      :errorBtn="contentData.orderDateError"
      v-on:cancel="onCancel"
      v-on:select="onSelect"
      :submitTitle="contentData.submitBtnText"
      :disabled="false"
      :loading="contentData.loading"
      class="summary-btn-panel"
    />
  </div>
</template>

<script>
import SummaryTextItem from '../components/SummaryTextItem.vue';
import ButtonPanel from '../components/ButtonPanel.vue';

import { atcb_init } from 'add-to-calendar-button'
import { removeHtmlTags } from '../helpers/textHelper';
import { getAppConfig } from '../configManager';

export default {
  props: {
    contentData: Object,
  },
  components: {
    SummaryTextItem,
    ButtonPanel,
  },
  data() {
    return {
      errorCodes: getAppConfig("orderErrorCodes")
    }
  },
  watch: {
    "$i18n.locale": function() {
      this.$nextTick(() => {
        this.changeCalendarLabel();
      })
    }
  },
  computed: {
    successTitleKey() {
      return this.contentData.isClinicAuto ? 'order-success-auto-title' : 'order-success-manual-title'
    },
    successSubTitleKey() {
      return this.contentData.isClinicAuto ? 'order-success-auto-subtitle' : 'order-success-manual-subtitle'
    },
    summaryTextErrorStyle() {
        return this.contentData.orderDateError && this.contentData.errorCode == this.errorCodes.timeBlocked;
    },
    parsedClinicNote() {
      if (!this.contentData.clinicNote) return null;

      let removedPTags = this.contentData.clinicNote.replace("<p>", "").replace("</p>", "").replace(/\s/g,'');
      return removedPTags;
    },
    showNote() {
      return this.parsedClinicNote && !this.contentData.orderDateError;
    },
    showErrorHeader() {
      if (!this.contentData) return false;

      const {orderComplete, orderDateError, errorCode} = this.contentData;

      return !orderComplete && orderDateError && (errorCode === this.errorCodes.newClientBlocked || errorCode === this.errorCodes.timeBlocked);
    },
    errorHeaderTitle() {
      const {errorCode} = this.contentData;

      switch (errorCode) {
        case this.errorCodes.newClientBlocked:
          return "submit-order-footer-error-block-title"
        case this.errorCodes.timeBlocked:
          return "submit-order-footer-error-date-title"
      }

      return "";
    },
    errorHeaderSubtitle() {
      const {errorCode} = this.contentData;

      switch (errorCode) {
        case this.errorCodes.newClientBlocked:
          return "submit-order-footer-error-block-subtitle"
        case this.errorCodes.timeBlocked:
          return "submit-order-footer-error-date-subtitle"
      }

      return "";
    }
  },
  methods: {
    onCancel() {
      if (this.contentData.orderDateError)  return this.contentData.onErrorGoBack();
      this.$router.go(-1);
    },
    onSelect() {
      this.contentData.onSendOrder();
    },
    getHintMessage() {
      if (!this.contentData.orderDateError) return "";
      let msg;
      switch (this.contentData.errorCode) {
        case this.errorCodes.timeBlocked:
          msg = ''
          break;
        case this.errorCodes.newClientBlocked:
          msg = ''
          break;
        case "DB_PROCEDURE_ERROR":
          msg = 'submit-order-footer-error-db'
          break;  
        case "UNHANDLED":
          msg = 'submit-order-footer-error-db'
          break;  
      
        default:
          msg = 'submit-order-footer-error-db'
          break;
      }
      return msg;
    },
    getCalendarData() {
      return JSON.stringify({
          name: this.contentData.serviceTitle,
          location: this.contentData.locationSubtitle,
          startDate: this.contentData.dayDate,
          endDate: this.contentData.dayDate,
          startTime:this.contentData.dateFrom,
          endTime: this.contentData.dateTo,
          label: this.$t("add-to-calendar"),
          options: [
            "Apple",
            "Google",
            "iCal|"+this.$t("calendar-label")+""
          ],
          timeZone:"Europe/Prague",
          description: removeHtmlTags(this.contentData.serviceDescription),
      }) 
    },
    changeCalendarLabel() {
      document.querySelector(".atcb_text").innerText = this.$t("add-to-calendar");
      document.querySelector(".atcb_list_item:nth-child(3)").childNodes.forEach(e => {
        if (e.nodeName === "#text") e.remove();
      })
      document.querySelector(".atcb_list_item:nth-child(3) .atcb_text").innerText = this.$t("calendar-label"); //THIS IS HARDCODED SELECTOR, IF YOU CHANGE THE ORDER OF OPTIONS IN CALENDAR DATA, MAKE SURE TO CHANGE THIS TOO
    },
    focusCalendarListOptions() {
      setTimeout(() => { //has to be timeout, else it register the click on one of the items and click on it
        document.querySelector(".atcb_list_item:last-child").scrollIntoView();
      }, 0);
    }
  },
  mounted() {
    if (this.contentData.orderDateError) return;
    atcb_init();
    document.querySelector("#atcb_button_0").addEventListener("mouseenter", this.focusCalendarListOptions);
    document.querySelector("#atcb_button_0").addEventListener("focus", this.focusCalendarListOptions);
  }
};
</script>

<style>
.sumamry-dtp .prev,
.sumamry-dtp .next {
  display: none !important;
}
.sumamry-dtp .day__month_btn {
  width: 100% !important;
}
.sumamry-dtp {
  cursor: default !important;
  pointer-events: none !important;
}
.summary-wrapper {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  align-items: center;
  position: relative;
  padding-top: 50px;
}
.summary-wrapper .scroller {
  align-items: center;
  width: 100%;
}
.summary-wrapper .client-label,
.summary-wrapper .client-name {
  text-align: left;
  align-self: flex-start;
  margin-left: 7.5%;
}
.summary-wrapper .client-label {
  color: var(--summary-title-c);
  font-weight: 300;
  font-size: 14px;
}
.summary-wrapper .client-name {
  font-weight: 300;
  font-size: 24px;
  color: var(--summary-subtitle-c);
}
.summary-item-list {
  width: 90%;
  margin-top: 30px;
  margin-left: 5%;
}
.summary-item {
  width: 46%;
  margin: 0 4%;
  margin-bottom: 40px;
}
.summary-item:nth-child(odd) {
  margin-left: 0;
}
.summary-item:nth-child(even) {
  margin-right: 0;
}
.summary-btn-panel {
  bottom: 0;
}
.summary-btn-panel .button-panel-select-btn {
  height: 40%;
  margin-right: 0 !important;
}
.footer-summary {
  text-align: left;
  width: 85%;
  margin-top: 2vh;
}
.footer-summary span:nth-child(1) {
  margin-bottom: 30px;
  font-weight: 300;
}
.footer-summary span {
  color: var(--summary-price-label-c);
}
.footer-summary-price {
  font-size: 26px;
  font-weight: 400;
  margin-left: 5px;
  color: var(--summary-price-value-c) !important;
}
.footer-summary-error {
  color: var(--danger) !important;
  text-align: right;
  margin-top: 15vh;
}
.success-header {
  background-color: var(--summary-thanks-bg);
  width: 100%;
  height: fit-content;
  padding: 4vh 0;
  top: 0;
  left: 0;
  text-align: left;
  align-items: center;
  color: var(--summary-thanks-text-icon-c);
}
.success-header h2 {
  font-weight: 300;
  margin-bottom: 15px;
  font-size: 32px;
}
.success-header span {
  font-weight: 300;
  font-size: 16px;
}
.success-header .success-icon {
  width: 112px;
  margin-left: 50px;
  margin-right: 40px;
}
.success-header.failed {
  background-color: #D0021B;
}
.summary-wrapper .atcb_list_item:first-child {
  padding-top: 12px !important;
}
.summary-wrapper .atcb_button {
  box-shadow: none;
}
.summary-wrapper .atcb_button:hover {
  box-shadow: 1px 2px 10px 0 rgb(0 0 0 / 40%);
}
.summary-wrapper .atcb_button, .summary-wrapper .atcb_list_item {
  background-color: var(--add-to-calendar-bg) !important;
  color: var(--add-to-calendar-t-c) !important;
  border-color: var(--add-to-calendar-b-c) !important;
  stroke: var(--add-to-calendar-t-c) !important;
  fill: var(--add-to-calendar-t-c) !important;
}
.summary-wrapper .atcb_list_item {
  background-color: var(--add-to-calendar-list-bg) !important;
}
@media only screen and (max-width: 850px) {
  .success-header {
    flex-flow: column nowrap;
    padding: 3vh 0;
  }
  .summary-btn-panel .btn-panel-error {
    /*margin-right: 20px !important;*/
  }
  .summary-item {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 20px;
  }
  .summary-item-list .summary-item {
    width: 98%;
  }
  .summary-item-list {
    width: 100%;
    margin-left: 0;
    margin-top: 15px;
  }
  .summary-wrapper .scroller {
    width: fit-content !important;
  }
  .summary-btn-panel .button-panel-select-btn {
    height: fit-content !important;
  }
  .summary-wrapper .scroller {
    margin-left: 20px;
  }
  .summary-wrapper .client-label,
  .summary-wrapper .client-name {
    margin-left: 0;
  }
  .titles-wrapper .subtitle {
    font-size: 14px !important;
  }
  .text-single,
  .text-single span,
  .text-single .font-medium,
  .titles-wrapper .text-single {
    font-size: 18px !important;
  }
  .summary-item:last-child {
    margin-bottom: 0;
  }
  .footer-summary {
    margin-top: 25px;
    width: 100%;
  }
  .footer-summary span:nth-child(1) {
    margin-bottom: 25px;
  }
  .footer-summary-error {
    text-align: left;
    margin-top: 0;
  }
  .success-header {
    text-align: left;
  }
  .success-header div {
    width: 80%;
  }
  .success-header h2 {
    margin-bottom: 5px;
    font-size: 24px;
  }
  .success-header span {
    font-size: 12px;
  }
}
</style>

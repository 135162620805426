<template>
  <div @click="onWrapperClick" v-bind:style="wrapperPointer ? 'cursor: pointer' : '' " class="summary-item">
    <span class="item-title">{{ itemName }}</span>
    <div class="wrapper d-flex flex-row" v-bind:class="{ 'wrapper-error': errorStyle }">
      <div v-if="imgSrc" class="img-wrapper" v-bind:class="{ 'img-wrapper-rounded': isRounded }">
        <img v-if="!isHtmlSrc && (errorStyle === false || !errorIconSrc)" :src="imgSrc" alt="summary-image" />
        <div v-if="isHtmlSrc && (errorStyle === false || !errorIconSrc)" v-html="imgSrc" alt="summary-image"></div>
        <img v-if="errorStyle === true && errorIconSrc" :src="errorIconSrc" alt="error-icon" />
      </div>
      <div class="titles-wrapper d-flex flex-column">
        <span class="title" v-bind:class="{ 'text-single': !subtitle }" v-html="title" v-if="title"></span>
        <span @click="onSubtClick" v-bind:style="subtPointer ? 'cursor: pointer' : '' " class="subtitle" v-bind:class="{ 'text-single': !title }" v-if="subtitle" v-html="subtitle"></span>
      </div>
      <div v-if="icon" class="icon-wrapper d-flex">
        <img :src="icon" alt="summary-icon" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    itemName: String,
    imgSrc: String,
    title: String,
    subtitle: String,
    icon: String,
    errorStyle: Boolean,
    errorIconSrc: String,
    isRounded: Boolean,
    isHtmlSrc: Boolean,
    onSubtClick: {
      type: Function,
      default() {
        return () => {}
      }
    },
    subtPointer: Boolean,
    onWrapperClick: {
      type: Function,
      default() {
        return () => {}
      }
    },
    wrapperPointer: Boolean,
    imgIsIcon: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
};
</script>

<style>
.summary-item {
  display: flex;
  flex-flow: column nowrap;
  text-align: left;
}
.summary-item .wrapper span {
  font-size: 16px;
}
.item-title {
  margin-bottom: 11px;
  font-size: 14px;
  font-weight: 300;
  color: var(--summary-section-title-c);
}
.summary-item .wrapper-error {
  color: var(--danger) !important;
  border-color: var(--danger) !important;
}
.summary-item .wrapper-error span {
  color: var(--danger) !important;
}
.summary-item .wrapper {
  border-left: 5px solid var(--summary-item-left-b-c);
  border-radius: 5px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  min-height: 70px;
  background-color: var(--item-bg-color);
  align-items: center;
  padding: 5px 0;
  padding-left: 20px;
}
.summary-item .title {
  font-weight: 400;
  color: var(--summary-item-title-c);
}
.summary-item .subtitle {
  color: var(--summary-item-subt-c);
  font-weight: 300;
  opacity: 0.6;
  font-size: 14px !important;
}
.titles-wrapper {
  justify-content: space-around;
  height: 50%;
  width: 85%;
}
.img-wrapper {
  margin-right: 15px;
}
.img-wrapper img {
  width: 35px;
}
.img-wrapper svg {
  width: 35px;
  height: 35px;
}
.img-wrapper-rounded img {
  border-radius: 50%;
}
.summary-item .text-single,
.summary-item .text-single span {
  font-size: 20px !important;
  color: var(--summary-item-title-single-c);
  font-weight: 300;
  opacity: 1 !important;
  font-size: 16px !important;
}
.icon-wrapper {
  margin: 0 15px;
  align-items: center;
  justify-content: center;
}
.icon-wrapper img {
  height: 35px;
}

.img-wrapper #sluzba-zub-outter,
.img-wrapper .sluzba-zub-outter {
  fill: var(--primary);
}
</style>

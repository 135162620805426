import Vue from 'vue';
import VueI18n from 'vue-i18n';

import cs from 'vee-validate/dist/locale/cs';
import en from 'vee-validate/dist/locale/en';
import de from 'vee-validate/dist/locale/de.json';
import ru from "vee-validate/dist/locale/ru.json"
import uk from "vee-validate/dist/locale/uk.json"

Vue.use(VueI18n);

const langs = {
  cz: cs,
  en: en,
  de: de,
  uk: uk,
  ru: ru
};

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
      let langObjKey = key.split('./')[1].split('.json')[0];
      if (!langs[langObjKey]) return;
      messages[locale]['validation'] = langs[langObjKey]['messages'];
    }
  });
  return messages;
}

const i18n = new VueI18n({
  locale: 'cz',
  messages: loadLocaleMessages(),
});

export default i18n;

import { configure, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import i18n from './i18n';

const customValidationMessagesForFields = ['min', 'max'];

configure({
  defaultMessage: (field, values) => {
    values._field_ = i18n.t(`fields.${field}`);
    if (values._field_.includes('fields.')) values._field_ = '';

    if (customValidationMessagesForFields.includes(values._rule_)) return i18n.t(`validationCustom.${values._rule_}`, values);

    return i18n.t(`validation.${values._rule_}`, values);
  },
});
//install all rules
for (const key in rules) {
  extend(key, rules[key]);
}

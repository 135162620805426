<template>
  <ValidationProvider tag="div" class="text-input-wrapper input-wrapper" :name="name" :rules="rulesPipe" v-slot="{ errors }">
    <label v-if="false">{{ label }}</label>
    <div class="wrapper">
      <input
        @keypress="isValid"
        @paste="isValid"
        @blur="onBlur"
        @focus="onFocus"
        type="text"
        class="form-control"
        :class="{ 'input-error': errors.length > 0 && errorActivated, 'input-warning-error': errors.length > 0 && errorActivated && isWarningError }"
        :name="name"
        :id="name"
        :placeholder="placeholder"
        v-model="value"
        :maxlength="max"
        :autoCapitalize="autoCapitalize ? 'on' : 'none'"
      />
      <div v-if="displayError && errorActivated && errors.length > 0" class="error-input-icon" v-html="isWarningError ? warningIcon : errorIcon"></div>
    </div>
    <span class="error-text" v-bind:class="{ 'error-warning-text': isWarningError }" v-if="displayError && errorActivated && errors.length > 0">{{ errors[0] }}</span>
  </ValidationProvider>
</template>

<script>
export default {
  props: {
    name: String,
    rules: Array,
    label: String,
    displayError: Boolean,
    isWarningError: Boolean,
    model: String,
    placeholder: String,
    errorIcon: String,
    warningIcon: String,
    autoCapitalize: Boolean
  },
  data() {
    return {
      rulesPipe: '',
      value: '',
      errorActivated: true,
      wasValidated: false,
      max: 256
    };
  },
  methods: {
    onBlur() {
      this.$emit('blur');
    },
    onFocus() {
      this.errorActivated = true;
    },
    setupRulesPipe(val) {
      let rules = val !== undefined ? val : this.rules;
      this.rulesPipe = '';
      rules.map((r) => {
        if (this.rulesPipe !== '') this.rulesPipe += '|';

        if (r.includes("max") || r.includes("digits")) this.setMax(r);

        this.rulesPipe += r;
      });
    },
    isValid(evt) {
      this.wasValidated = true;
      if (this.name !== 'phone' && this.name !== "email") return true;

      if (this.name === "email") return this.validateEmail(evt);
      
      let clipboardData = evt.clipboardData || window.clipboardData;
      if (clipboardData) {
        let s = clipboardData.getData('Text');
        if (s.match(/[^$,.\d]/)) return evt.preventDefault();

        return true;
      }

      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;

      if ((charCode > 31 && (charCode < 48 || charCode > 57)) || charCode === 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    validateEmail(event) {
      let clipboardData, regex, key;
      switch (event.keyCode) {
            case 8:  // Backspace
            case 9:  // Tab
            case 13: // Enter
            case 45: //-
            break;
            default:
            // eslint-disable-next-line
            regex = new RegExp("^[a-zA-Z0-9.@+\+\/$%=*.!?_]");
            key = event.key;
            if (!key) {
              clipboardData = event.clipboardData || window.clipboardData;
              key = clipboardData.getData("Text");
            }
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
            break;
      }
      return true;
    },
    setMax(max) {
      this.max = max.trim().split(":")[1];
    },
    setCustomMax() {
      switch (this.name) {
        case "promotion code":
          this.max = 50;
          break;
      
        default:
          break;
      }
    }
  },
  watch: {
    value: function (newVal) {
      this.$emit('input', { newVal, name: this.name, validated: this.wasValidated});
      this.wasValidated = false;
    },
    model: function (newModel) {
      if (newModel === this.value) return;
      this.value = newModel;
    },
    isWarningError: function () {
      this.onFocus();
    },
    rules: function (newVal) {
      this.setupRulesPipe(newVal);
    }
  },
  created() {
    this.setupRulesPipe();

    this.value = this.model;
    this.setCustomMax();
  },
};
</script>

<style>
.text-input-wrapper input {
  border: 1px solid var(--form-input-i-border-c);
  border-radius: 10px;
  padding: 5px 10px;
  box-sizing: border-box;
  height: 50px;
  font-size: 16px;
  width: 100%;
  font-weight: 300;
}
.text-input-wrapper input::placeholder {
  color: var(--form-input-i-placeholder-c);
}
.text-input-wrapper label {
  font-weight: 300;
  color: var(--form-label-errorwarn-c);
}
.text-input-wrapper {
  text-align: right;
}
.text-input-wrapper .input-error {
  padding-right: calc(5% + 20px) !important;
}
.text-input-wrapper .wrapper {
  position: relative;
  margin: 0;
  width: 100%;
}
.text-input-wrapper .error-input-icon {
  position: absolute;
  right: 5%;
  top: calc(50% - 9px);
}
.text-input-wrapper .error-text {
  margin-right: 15px;
}
.text-input-wrapper .warning-error-text {
    color: var(--form-label-errorwarn-c);
}
</style>

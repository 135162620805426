<template>
  <div ref="selectWrapper" class="app-select-wrapper d-flex flex-column noselect" :id="elId">
    <div :name="name" :id="name" ref="selectDiv" class="app-select-inline d-flex" @click="onSelectedClick">
      <span>{{ localizedValue === true ? $t(selected.label) : selected.label }}</span>
      <img :src="require('@/assets/icons/chevron-right.svg')" class="chevron-select" />
    </div>
    <div ref="optionList" v-bind:style="optionsOpen ? 'visibility: visible' : 'visibility: hidden'" class="app-select-list d-flex flex-column">
      <div class="option-wrapper" v-for="o in options" :key="o.value" :value="o.value" @click="() => onOptionClick(o.value)" v-bind:class="{ 'option-wrapper-active': o.value == selected.value }">
        <span>{{ localizedValue === true ? $t(o.label) : o.label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['options', 'value', 'localizedValue', 'id', 'name'],
  data() {
    return {
      selected: {
        label: '',
        value: null,
      },
      optionsOpen: false,
      elId: '',
    };
  },
  watch: {
    value: function (newVal) {
      if (!newVal) this.updateSelected(this.options[0].value);
      this.updateSelected(newVal);
    },
    options: function (newVal) {
      if (newVal.length === 0) return this.updateSelected(null);
    },
  },
  created() {
    this.elId = `app-select-${this.options.length > 0 ? this.options[0].label : Math.round(Math.random() * 10)}`;

    window.addEventListener('click', (e) => {
      let found = false;
      let path = e.path ? e.path : e.composedPath();

      path.forEach((e) => {
        if (e.id === this.elId) found = true;
      });


      if (found === false && this.optionsOpen === true) this.onSelectedClick();
      //console.log(this.optionsOpen);
    });
    if (this.options.length === 0) return;

    if (this['value']) return this.updateSelected(this['value']);
    this.updateSelected(this.options[0].value);
  },
  mounted() {
    this.countOptionsWidth();
  },
  methods: {
    onSelectedClick() {
      this.optionsOpen = !this.optionsOpen;
    },
    onOptionClick(val) {
      this.updateSelected(val);
      this.onSelectedClick();
    },
    updateSelected(val = undefined) {
      if (this.selected.value === val) return;

      if (val === null) {
        this.selected = { label: '', value: null };
        return this.emitInput();
      }

      if (val === undefined) val = this.selected.value;

      this.selected = this.options.find((o) => o.value === val);

      this.emitInput();
    },
    emitInput() {
      this.$emit('input', this.selected.value);
    },
    countOptionsWidth() {
      if (!this.$refs.optionList) return;
      let el = this.$refs.optionList;
      let rawWidth = el.clientWidth;
      let paddingLeft = window.getComputedStyle(el, null).getPropertyValue('padding-left').split('px')[0];
      let paddingRight = window.getComputedStyle(el, null).getPropertyValue('padding-right').split('px')[0];
      let width = rawWidth - paddingLeft - paddingRight + 4;

      this.$refs.selectDiv.style.width = width + 'px';
    },
  },
};
</script>

<style>
.app-select-wrapper {
  position: relative;
  min-height: fit-content;
  align-items: center;
  text-align: left;
  color: rgb(125, 125, 125);
  font-weight: 300;
  background-color: transparent !important;
  border-radius: 10px;
}
.chevron-select {
  transform: rotate(90deg);
  right: 8px;
  position: absolute;
}
.app-select-inline {
  border: 1px solid var(--form-input-i-border-c);;
  border-radius: 10px;
  padding: 15px;
  padding-right: 25px;
  position: relative;
  align-items: center;
  box-sizing: border-box;
  width: 30vw;
  background-color: var(--item-bg-color);
  max-width: 100%;
}
.app-select-inline span {
    overflow-wrap: anywhere;
}
.app-select-list {
  align-items: center;
  position: absolute;
  margin: 0 !important;
  top: 100%;
  border: 1px solid var(--form-input-i-border-c);;
  z-index: 999;
  text-align: left;
  background-color: var(--form-select-list-bg);
  color: var(--form-select-list-c);
}
.option-wrapper {
  padding: 5px 15px;
  padding-right: 25px;
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  min-width: fit-content;
  overflow-wrap: anywhere;
}
.option-wrapper:hover {
  background-color: var(--secondary);
  color: var(--inverted-primary-color);
}
.option-wrapper-active {
  background-color: var(--primary);
  color: var(--inverted-color);
}
.option-wrapper-active:hover {
  background-color: var(--primary) !important;
}
</style>

<template>
  <div id="app" :data-route="$router.currentRoute.name" v-bind:class="isSafari ? 'safari-style' : '' ">    <div v-if="finishScript" style="display: none" class="script-layer" v-html="finishScript"></div>
    <OrderCompleteScreen v-on:close-screen="onCancelOrderScreenClose" :orderCancelStatus="orderCancelStatus" v-if="$route.name === routes.CANCEL_ORDER && orderCancelScreen"/>
    <LoadingOverlay v-if="companyDataLoading || appointmentLoading" :specialOverlay="appointmentLoading" :initLoader="initLoader"/>
    <div v-if="!isCompanyError && !companyDataLoading" class="app-wrapper" v-bind:class="{'app-wrapper-order-complete': isMobileDisplay && orderComplete, 'app-wrapper-cancel-order': $route.name === routes.CANCEL_ORDER}">
      <Header :subtitle="clinicHeadeSubTitle" :title="clinicHeaderTitle" :logo="clinicHeaderLogo" />
      <LanguageSwitcher :class="'lang-switch'" :langList="langConfig" :setLang="activeLang" v-on:languageChanged="onLangChange" />
      <div class="content" v-bind:style="($route.name === 'Summary' && (orderComplete || orderDateError)) || $route.name === routes.CANCEL_ORDER ? 'padding-top: 0' : ''">
        <span v-bind:style="`left: ${sectionTitleLeft}%`" v-if="!isMobileDisplay && !orderComplete && !orderDateError && $route.name !== routes.CANCEL_ORDER" class="section-title">{{$t(sectionTitleKey)}}</span>
        <Loader :loading="isStaffLoading" />
        <router-view :contentData="contentData" />
      </div>
      <SidebarStatus
        v-if="$route.name !== routes.CANCEL_ORDER"
        :isFinished="orderComplete"
        :iconId="themeIconId"
        :isSafari="isSafari"
        :disableAll="orderComplete || orderDateError"
        :mobile="isMobileDisplay"
        :errorStep="sidebarErrorIndex"
        :statusList="this.sidebarStatusList"
        :stepOpen="openWidgetStep"
        :step="widgetStep"
      />
    </div>
  </div>
</template>

<script>
import Header from './components/Header.vue';
import SidebarStatus from './components/SidebarStatus.vue';
import LanguageSwitcher from './components/LanguageSwitcher.vue';
import Loader from './components/Loader.vue';
import LoadingOverlay from "./Overlays/LoadingOverlay.vue";
import OrderCompleteScreen from "./screens/OrderCompleteScreen.vue";

import { getCompanyMetadata } from '@/api/company.js';
import { getAllAvailableDates, getAvailableDates } from './api/serviceDateTimes';
import { getCurrentMonthEpochDate, splitAllDatesToMonth } from './helpers/timesOfDayHelper';
import { parseDataToPayload } from './helpers/orderHelper';
import { capitalize } from './helpers/textHelper';
import { cancelAppointment, getCancelMetadata, getPhotoFile, getStaffPhotoSrc, sendClientOrderData } from './api/company';
import { formatCustomInputData, pickOutCustomInputs } from './helpers/customInputsHelper';
import { checkContrast, getThemeClass, hexToRgbA, hexToThemeColorClass, pSBC, shadeColor } from './helpers/themeHelper';

import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/cs';
import 'moment/locale/de';
import { BASE_URL } from './api/http';
import { getAppConfig } from './configManager';
import { getSvg } from './helpers/assetHelper';
import { getIsSafari } from './helpers/platformHelper';
import {setLocalParam, getLocalParam} from "./helpers/localStorageHelper";
import routes from "./routes.json";
import manualDates from "../manualDates.json";

export default {
  components: {
    Header,
    SidebarStatus,
    LanguageSwitcher,
    Loader,
    LoadingOverlay,
    OrderCompleteScreen
  },
  computed: {
    contentData() {
      let service, clinic, staff;
      switch (this.$route.name) {
        case 'Home':
          return { langList: this.langConfig, onLanguageChange: this.onLangChange, setLang: this.activeLang, mobile: this.isMobileDisplay, isSafari: this.isSafari };
        case routes.CLINIC_SELECT:
          return {
            selectedClinicId: this.selectedClinicId,
            clinicsList: this.companyData.clinics,
            onSelect: this.onClinicSelect,
            onOpen: this.onClinicOpen,
            mobile: this.isMobileDisplay,
            iconId: this.themeIconId,
            isCompanyError: this.isCompanyError,
            loading: this.isStaffLoading,
            isSafari: this.isSafari,
            colorFromURL: this.colorFromURL
          };
        case routes.SERVICE_SELECT:
          if (this.resetToWelcomeScreen(this.selectedClinicId) === false)
            return {
              selectedServiceId: this.selectedServiceId,
              services: this.getClinic().services,
              onSelect: this.onServiceSelect,
              onOpen: this.onServiceOpen,
              loading: this.isStaffLoading,
              mobile: this.isMobileDisplay,
              currency: this.companyData.config.currency,
              iconId: this.themeIconId,
              isSafari: this.isSafari,
            };
          break;
        case routes.STAFF_SELECT:
          if (this.resetToWelcomeScreen(this.selectedServiceId) === false)
            return {
              staffs: this.getService().staffs,
              idLocation: this.selectedClinicId,
              idService: this.selectedServiceId,
              onSelectStaff: this.onSelectStaff,
              onSelectMonth: this.onMonthChange,
              onSelectDay: this.onDayChange,
              onSelectTime: this.onTimeChange,
              onStaffAndTimeConfirm: this.onStaffAndDateSelect,
              onOpenStaff: this.onOpenStaff,
              selectedMonth: this.selectedMonth,
              selectedDay: this.selectedDay,
              selectedTime: this.selectedTime,
              selectedStaffId: this.selectedStaffId,
              currLang: this.activeLang,
              mobile: this.isMobileDisplay,
              iconId: this.themeIconId,
              isSafari: this.isSafari,
              defaultStaffIcon: this.defaultStaffIcon
            };
          break;
        case routes.CLIENT_FORM:
          if (this.resetToWelcomeScreen(this.selectedStaffId) === false)
            return {
              onSubmit: this.onClientFormSubmit,
              phonePrefix: this.companyData.config.phonePrefix,
              phonePrefixList: this.phonePrefixList,
              formData: this.clientFormData,
              rules: { ...this.rules },
              onChangeFill: this.modifyFormFilled,
              mobile: this.isMobileDisplay,
              customInputs: this.customInputs,
              isEmailOrPhoneReq: this.isEmailOrPhoneReq,
              iconId: this.themeIconId,
              orderDateError: this.orderDateError,
              submitBtnText: this.getSummarySelectBtnText(),
              orderComplete: this.orderComplete,
              loading: this.isStaffLoading,
              isSafari: this.isSafari,
            };
          break;
        case routes.SUMMARY:
          service = this.getService();
          clinic = this.getClinic();
          staff = this.getStaff();

          if (this.resetToWelcomeScreen(this.selectedStaffId) === false)
            return {
              clientName: this.clientFormData.firstName + ' ' + this.clientFormData.lastName,
              serviceTitle: service.name,
              serviceDescription: service.description,
              serviceSubtitle: service.duration + ' ' + this.$t('minutes-action'),
              serviceIcon: this.summarySvgIcon,
              serviceIconIsHTML: !this.isSafari,
              locationTitle: clinic.name,
              locationSubtitle: `${clinic.street}, ${clinic.post} ${clinic.city}`,
              staffSubtitle: staff.name,
              staffImg: this.selectedStaffAvatar,
              price: service.price,
              isClinicAuto: false, //TODO connect to BE
              clinicNote: this.companyData.detail[this.getLastCompanyDetailIndex()].htmlMsgBeforeBook,
              selectedDay: this.selectedDay,
              selectedTime: this.selectedTime,
              dateTitle: `<span class="font-medium">${capitalize(moment(this.selectedTime).format('dddd'))}</span> ${moment(this.selectedTime).format('DD.MM.YYYY')}`, // + " " + moment(this.selectedTime).format("DD.MM.YYYY HH:mm") + " - " + moment(this.selectedTime).add(service.duration, "minutes").format("HH:mm") + " hodin",
              dateSubtitle: `<span class="font-medium">${moment(this.selectedTime).format('HH:mm')} - ${moment(this.selectedTime).add(service.duration, 'minutes').format('HH:mm')}</span> hodin`,
              dayDate: moment(this.selectedTime).format('YYYY-MM-DD'),
              dateFrom: moment(this.selectedTime).format('HH:mm'),
              dateTo: moment(this.selectedTime).add(service.duration, 'minutes').format('HH:mm'),
              dateIcon: require('./assets/icons/cas.svg'),
              dateIconError: require('./assets/icons/cas-error.svg'),
              locationIcon: require('./assets/icons/icona-misto.svg'),
              onSendOrder: this.onSendOrder,
              orderDateError: this.orderDateError,
              mobile: this.isMobileDisplay,
              submitBtnText: this.getSummarySelectBtnText(),
              orderComplete: this.orderComplete,
              loading: this.isStaffLoading,
              iconId: this.themeIconId,
              isSafari: this.isSafari,
              errorCode: this.orderDateErrorType,
              currency: this.companyData.config.currency,
              onErrorGoBack: this.onSummaryErrorGoBack,
              onAddressClick: this.onSummaryAddressClick
            };
            break;
        case routes.CANCEL_ORDER:
          return {
              appointmentData: this.appointmentData,
              orderDateError: this.orderDateError,
              errorCode: this.orderDateErrorType,
              serviceIcon: this.summarySvgIcon,
              serviceIconIsHTML: !this.isSafari,
              locationIcon: require('./assets/icons/icona-misto.svg'),
              mobile: this.isMobileDisplay,
              loading: this.isStaffLoading,
              iconId: this.themeIconId,
              isSafari: this.isSafari,
              orderCancelStatus: this.orderCancelStatus,
              onOrderCancel: this.onOrderCancel,
              idAppointment: this.idAppointment,
              idCompany: this.idCompany,
              locations: this.companyData.clinics,
              setAppointmentCancelUnavailable: this.setAppointmentCancelUnavailable,
              setAppointmentExpired: this.setAppointmentExpired
            };
      }
      return null;
    },
    sidebarStatusList() {
      const arr = [];

      if (!this.companyData.clinics) return arr;

      this.sidebarStatusConfig.map((c) => {
        let clinic = this.companyData.clinics.find((clin) => clin.id === this.openClinicId);
        const obj = {
          title: '',
          subtitle: '',
          src: c.src,
          step: c.step,
          path: c.path,
          mobileTitle: null,
        };
        if (c.mobileTitle) obj.mobileTitle = c.mobileTitle;

        obj.title = c.title;
        if (c.type === 'find') {
          if (!this[c.key]) obj.subtitle = c.nullLabel;
          else {
            if (c.key === 'selectedClinicId') obj.subtitle = clinic[c.subtitleKey];
            else if (c.key === 'selectedServiceId') {
              const service = clinic.services.find((ser) => ser.idService === this.openServiceId);
              obj.subtitle = service[c.subtitleKey];
            }
          }
        } else if (c.type === 'value') {
          if (!this[c.key]) obj.subtitle = c.nullLabel;
          else obj.subtitle = c.fulFillTitle;
        } else if (c.type === 'special') return arr.push(this.specialStatusSidebbarToView(c));

        arr.push(obj);
      });
      return arr;
    },
    sidebarErrorIndex() {
      const {timeBlocked, newClientBlocked} = getAppConfig("orderErrorCodes");

      if (!this.orderDateError) return null;

      if (this.orderDateErrorType === timeBlocked) return 2;
      if (this.orderDateErrorType === newClientBlocked) return 3;

      return null;
    },
  },
  watch: {
    $route(to) {
      switch (to.name) {
        case routes.CLINIC_SELECT:
          this.setWidgetStep(0, true);
          this.sectionTitleKey = "section-title-clinic";
          break;
        case routes.SERVICE_SELECT:
          this.setWidgetStep(1, true);
          this.setCorrectOpenClinicId();
          this.sectionTitleKey = "section-title-service";
          break;
        case routes.STAFF_SELECT:
          this.setWidgetStep(2, true);
          this.setCorrectOpenClinicId();
          this.sectionTitleKey = "section-title-staff";
          break;
        case routes.CLIENT_FORM:
          this.setWidgetStep(3, true);
          this.setCorrectOpenClinicId();
          this.sectionTitleKey = "section-title-form";
          break;
        case routes.SUMMARY:
          this.setWidgetStep(4, true);
          this.setCorrectOpenClinicId();
          this.sectionTitleKey = "summary-title";
          break;
      }
      this.resetStatesAccordingToStep(to.name);
    },
    selectedClinicId(newId) {
      if (!this.selectedClinicId) return this.clinicHeaderTitle = 'Neznámá pobočka';

      const clinic = this.companyData.clinics.find((c) => c.id === newId);
      this.clinicHeaderTitle = clinic.name;
      this.clinicHeadeSubTitle = clinic.title;
    },
    isCompanyError() {
      this.redirToNotFound();
    },
    companyDataLoading() {
      this.redirToNotFound();
    }
  },
  data() {
    return {
      themeClass: ['main-bg-light-theme-1', 'content-bg-light-theme-1', "default-theme"],
      companyData: {},
      activeLang: null,
      selectedClinicId: null,
      selectedServiceId: null,
      openServiceId: null,
      selectedStaffId: null,
      openStaffId: null,
      selectedStaffAvatar: null,
      selectedMonth: getCurrentMonthEpochDate(),
      selectedDay: Date.now(),
      selectedTime: null,
      avalTimesInMonth: [],
      clientFormData: {
        firstName: '',
        lastName: '',
        phonePrefix: '+420',
        phone: '',
        street: '',
        city: '',
        state: '',
        zip: '',
        email: '',
      },
      clientFormFilled: false,
      rules: getAppConfig("formRules"),
      sidebarStatusConfig: getAppConfig("sidebarConfig"),
      langConfig: ['cz', 'en', 'de', "uk", "ru"],
      widgetStep: 0,
      openWidgetStep: 0,
      orderComplete: false,
      showFinishedDialogue: false,
      isStaffLoading: true,
      clinicHeaderTitle: '',
      clinicHeadeSubTitle: '',
      clinicHeaderLogo: require('./assets/icons/default-no-text.svg'),
      initLoader: null,
      orderDateError: false,
      isMobileDisplay: false,
      customInputs: [],
      forceErrorOrder: getAppConfig('forceOrderFail'),
      forceSuccessOrder: getAppConfig('forceOrderSuccess'),
      isSafari: false,
      loadingStaffDates: false,
      openClinicId: null,
      isEmailOrPhoneReq: false,
      themeIconId: 0,
      isCompanyError: false,
      registeredUnload: false,
      phonePrefixList: [],
      summarySvgIcon: null,
      colorFromURL: false,
      themeFromURL: false,
      themeCode: "BOOKING_SCHEME_LIGHT_LIGHT",
      isThemeSpecial: false,
      sectionTitleLeft: 5,
      sectionTitleKey: "section-title-clinic",
      themeColorClass: "",
      companyDataLoading: true,
      orderDateErrorType: "",
      defaultStaffIcon: require("@/assets/icons/lekar.svg"),
      finishScript: null,
      routes: routes,
      orderCancelScreen: false,
      idAppointment: null,
      idCompany: null,
      appointmentData: null,
      appointmentLoading: false,
      orderCancelStatus: 0 //1 success, 2 fail time limit, 3 already occured, 4 = failed (request issue), 5 = already canceled
    };
  },
  methods: {
    redirToNotFound() {
      if (this.isCompanyError && !this.companyDataLoading) return this.$router.push("/notfound");
    },
    getClinic() {
      if (!this.selectedClinicId) return null;

      return this.companyData.clinics.find((c) => c.id === this.selectedClinicId);
    },
    getClinicById(id) {
      return this.companyData.clinics.find((c) => c.id === id);
    },
    getService() {
      if (!this.selectedClinicId || !this.selectedServiceId) return null;

      const clinic = this.getClinic();
      return clinic.services.find((s) => s.idService === this.selectedServiceId);
    },
    getStaff() {
      if (!this.selectedClinicId || !this.selectedServiceId || !this.selectedStaffId) return null;

      const service = this.getService();
      return service.staffs.find((s) => s.idStaff === this.selectedStaffId);
    },
    getThemeCode(idSchema) {
      let item = this.companyData.codelists.BOOKING_SCHEME.find(s => s.idCodelist === idSchema);
      if (item) return item.code;
      
      return null;
    },
    setWidgetStep(step, force = false) {
      this.openWidgetStep = step;
      if (step > this.widgetStep || force) this.widgetStep = step;
    },
    setThemeClass(cls) {
      let r = document.querySelector(":root");
      
      this.themeClass.forEach(c => r.classList.remove(c));
      this.themeClass = cls;
      this.themeClass.forEach(c => r.classList.add(c));
    },
    setCorrectOpenClinicId() {
      if (this.openClinicId !== this.selectedClinicId) this.onClinicOpen(this.selectedClinicId)
    },
    resetStatesAccordingToStep(pathName) {
      switch (pathName) {
        case routes.CLINIC_SELECT:
          if (this.openClinicId) this.resetService();
          if (this.openStaffId) this.resetStaffAndTime();
          this.removeLeaveAlert();
        break;
        case routes.SERVICE_SELECT:
          if (this.openStaffId) this.resetStaffAndTime();
          this.removeLeaveAlert();
        break;
      }
    },
    resetService() {
      this.selectedServiceId = null;
      this.openServiceId = null;
    },
    resetStaffAndTime() {
      this.selectedStaffId = null;
      this.openStaffId = null;
      this.selectedStaffAvatar = null;
      this.selectedMonth = getCurrentMonthEpochDate();
      this.selectedDay = Date.now();
      this.selectedTime = null;
    },
    onLangChange(key) {
      const lStorageKey = getAppConfig("language_localS_key_str");
      const keyToMomentKey = {
        cz: 'cs',
      };

      if (this.activeLang === key) return;
      if (this.langConfig.includes(key) === false) key = this.langConfig[0];

      this.$i18n.locale = key;
      setLocalParam(lStorageKey, key);
      this.activeLang = this.$i18n.locale;
      const momentKey = keyToMomentKey[key] ? keyToMomentKey[key] : key;
      moment.locale(momentKey);
    },
    onClinicOpen(id) {
      this.openClinicId = id;
      let openClinic = this.getClinicById(this.openClinicId);
      this.clinicHeaderTitle = openClinic.name;
      this.clinicHeadeSubTitle = openClinic.title;
      document.title = openClinic.name;
      this.clinicHeaderLogo = openClinic.logo ? `${BASE_URL}/getfile?idFile=${openClinic.logo}&size=100x100&imageAsType=0` : require('./assets/logo_placeholder.png');
      this.changeFavicon(openClinic.logoSquare ? `${BASE_URL}/getfile?idFile=${openClinic.logoSquare}&size=100x100&imageAsType=0` : null);
      
      if (!this.themeFromURL) {
        this.themeCode = this.getThemeCode(openClinic.idScheme);
        let [themeClass, isThemeSpecial] = getThemeClass(this.themeCode); 
        this.setThemeClass(themeClass);
        this.isThemeSpecial = isThemeSpecial;
        this.themeManagerNew(openClinic.color, openClinic.idScheme);
      }

      if (this.colorFromURL) return;
    },
    onClinicSelect(id) {
      this.selectedClinicId = id;
      if (this.selectedServiceId) this.setWidgetStep(0, true);
      this.selectedServiceId = null;
      this.selectedStaffId = null;
      this.openStaffId = null;
      (this.selectedMonth = getCurrentMonthEpochDate()), (this.selectedDay = Date.now()), this.$router.push({ name: 'ServiceSelection' });
    },
    onServiceOpen(id) {
      this.openServiceId = id; 
    },
    async onServiceSelect(id) {
      this.selectedServiceId = id;
      if (this.selectedStaffId) this.setWidgetStep(1, true);
      this.selectedStaffId = null;
      this.openStaffId = null;
      (this.selectedMonth = getCurrentMonthEpochDate()), (this.selectedDay = Date.now()), (this.isStaffLoading = true);
      await this.getDayTimes();
      this.isStaffLoading = false;
      this.$router.push({ name: 'StaffSelection' });

      if (!this.registeredUnload) {
        window.onbeforeunload = this.beforeWindowUnload;
        this.registeredUnload = true;
      }
    },
    onStaffAndDateSelect() {
      this.clientFormFilled = false;
      this.$router.push({ name: 'ClientForm' });
    },
    async onSelectStaff(id) {
      this.selectedStaffId = id;
      this.onStaffAndDateSelect();

      const staff = this.getStaff();
      if (staff.foto) {
        const data = await getPhotoFile(staff.foto);
        this.selectedStaffAvatar = `data:image/png;base64,${data}`;
      } else this.selectedStaffAvatar = require('./assets/icons/lekar.svg');
    },
    onOpenStaff(id) {
      this.openStaffId = id;
    },
    onMonthChange(m) {
      this.selectedMonth = m;
      this.selectedDay = m;
    },
    onDayChange(d) {
      this.selectedDay = d;
      this.selectedTime = null;
    },
    onTimeChange(t) {
      if (t) {
        const [time] = t;
        this.selectedTime = time;
        return;
      }
      this.selectedTime = t;
    },
    resetToWelcomeScreen(param) {
      if (!param) window.location = '/';

      return false;
    },
    closeConfirmation() {
      this.showFinishedDialogue = false;
    },
    openConfirmation() {
      this.orderComplete = true;
      this.showFinishedDialogue = true;
    },
    async getDayTimes() {
      const service = this.getService();

      if (service.datesReady && service.datesReady !== null) return;

      service['datesReady'] = true;

      this.loadingStaffDates = true;

      if (getAppConfig('fetchDatesAll') === true) {
        let staffMap = {};
        service.staffs.map(async (s) => {
          staffMap[s.idStaff] = s;
          if (typeof s.datesAval === 'object') s.datesAval = [];
        });
        const hardcodedDateSettings = getAppConfig("hardcodedDates");
        const data = hardcodedDateSettings && hardcodedDateSettings.use === true ? manualDates["dates"][hardcodedDateSettings.key] : await getAllAvailableDates(this.selectedServiceId, service.staffs);
        data.forEach((date) => {
          const s = staffMap[date.idStaff];
          if (!s.datesAval) s['datesAval'] = [];

          s.datesAval.push(date);
        });


        for (const key in staffMap) {
          const s = staffMap[key];

          s['datesAval'] = splitAllDatesToMonth(s['datesAval'], s.idStaff);
          await this.getStaffThumb(s)
        }

        return (this.loadingStaffDates = false);
      }

      for (const key in service.staffs) {
        const s = service.staffs[key];
        if (s.datesReady && s.datesReady === true) continue;
        s['datesAval'] = [];
        s['datesReady'] = true;
        const data = await getAvailableDates(this.selectedServiceId, s.idStaff);
        if (!Array.isArray(data)) {
          alert('Nastala chyba při načítání dat!');
          this.isStaffLoading = false;
          this.loadingStaffDates = false;
          this.$router.go(-1);
          return;
        }
        s['datesAval'] = splitAllDatesToMonth(data, s.idStaff);
        /* get staff thumb */
        await this.getStaffThumb(s);
        await new Promise((resolve) => setTimeout(resolve, 200));
      }

      this.loadingStaffDates = false;
    },
    async getStaffThumb(s) {
      if (!s['photoSrc']) {
        let staffPic;
        try {
          staffPic = await getStaffPhotoSrc(s.foto);
        } catch (error) {
          staffPic = require('./assets/icons/lekar.svg');
        }
        if (staffPic.includes("object")) staffPic = require('./assets/icons/lekar.svg'); //this solution could definitely be improved in the future
        s['photoSrc'] = staffPic;
      }
    },
    async onClientFormSubmit(data) {
      this.clientFormData = data;
      this.modifyFormFilled(true);
      this.isStaffLoading = true;
      await this.onSendOrder();
      this.isStaffLoading = false;
      this.$router.push({ name: 'Summary' });
    },
    onSummaryErrorGoBack() {
      this.orderDateErrorType = "";
      this.orderDateError = false;
      this.$router.go(-1);
    },
    onSummaryAddressClick() {
      const clinic = this.getClinic();

      window.open(`https://maps.google.com/?q=${clinic.street},${clinic.post},${clinic.city}`)
    },
    async onSendOrder() {
      const {timeBlocked} = getAppConfig("orderErrorCodes");

      if (this.orderDateError === true) {
        if (this.orderDateErrorType === timeBlocked)  return this.onPickOtherDate();

        return this.onPickOtherStaff();
      }
      if (this.orderComplete === true) return this.onNextOrder();
      const [customInputData, formData, miscCustomData] = pickOutCustomInputs(this.clientFormData, this.customInputs);

      const payload = parseDataToPayload(
        {
          locationId: this.selectedClinicId,
          serviceId: this.selectedServiceId,
          staffId: this.selectedStaffId,
          date: this.selectedDay,
          time: this.selectedTime,
          serviceName: this.getService().nameXdent,
          custom: customInputData,
          ...formData,
        },
        miscCustomData
      );

      const onSuccessFns = () => {
        this.openConfirmation();
        this.removeLeaveAlert();
        this.executeOnFinishScript();
      }

      if (this.forceErrorOrder)  {
        this.orderDateError = true;
        return;
      }
      if (this.forceSuccessOrder) return onSuccessFns();

      const orderRes = await sendClientOrderData(payload);
      if (orderRes !== true) {
          console.error("Encoutered error while sending request.");
          console.error(orderRes);
          this.orderDateErrorType = orderRes.codeType || orderRes.CodeType;
          this.orderDateError = true;
          return;
      }

      onSuccessFns();
    },
    executeOnFinishScript() {
      const script = this.companyData.detail[this.getLastCompanyDetailIndex()].htmlScript;

      if (!script) return;

      let scriptParsed = unescape(script).replace(/<\/?script>/g,"")
      setTimeout(() => {
        eval(scriptParsed);
      }, 100);
    },
    removeLeaveAlert() {
      return window.onbeforeunload = function () {};
    },
    async onPickOtherDate() {
      this.orderDateErrorType = "";
      this.orderDateError = false;
      this.getService().datesReady = false;
      this.isStaffLoading = true;
      await this.getDayTimes();
      this.isStaffLoading = false;
      this.$router.push({ path: '/staffSelection', query: { openStaff: this.selectedStaffId } });
    },
    onPickOtherStaff() {
      this.orderDateErrorType = "";
      this.orderDateError = false;
      this.$router.push({ path: '/clientForm' });
    },
    onNextOrder() {
      window.location.href = '/';
    },
    processCustomInputs() {
      const [parsedInputs, clientFormData] = formatCustomInputData(this.companyData.otherInputFields, this.clientFormData);
      this.customInputs = parsedInputs;
      this.clientFormData = clientFormData;
    },
    specialStatusSidebbarToView(s) {
      const obj = {
        title: 'special',
        subtitle: 'special',
        src: s.src,
        step: s.step,
        path: s.path,
      };
      if (s.mobileTitle) obj.mobileTitle = s.mobileTitle;

      switch (s.key) {
        case 'staffAndTime':
          if (!this.openStaffId || !this.selectedTime) {
            obj.title = s.title;
            obj.subtitle = s.nullLabel;
          } else {
            const dateBase = moment(this.selectedTime);
            let clinic = this.companyData.clinics.find((clin) => clin.id === this.selectedClinicId);
            const service = clinic.services.find((ser) => ser.idService === this.selectedServiceId);
            const staff = service.staffs.find((stf) => stf.idStaff === this.openStaffId);
            obj.title = staff.name;
            obj.subtitle = dateBase.format('DD.MM.YYYY HH:mm') + ' - ' + dateBase.add(service.duration, 'minutes').format('HH:mm');
          }
          break;

        case 'confirmForm':
          obj.title = s.title;
          obj.subtitle = !this.orderComplete ? s.nullLabel : s.fulFillTitle;
          break;
      }
      return obj;
    },
    modifyFormFilled(newState) {
      this.clientFormFilled = newState;
    },
    checkIsMobile() {
      if (window.innerWidth <= 850) return (this.isMobileDisplay = true);
      this.isMobileDisplay = false;
    },
    getSummarySelectBtnText() {
      const {timeBlocked, newClientBlocked} = getAppConfig("orderErrorCodes");

      let mainStr = '';
      let attachStr = '';
      if (this.isMobileDisplay) attachStr = '-mobile';

      if (this.orderDateError) {
        switch (this.orderDateErrorType) {
          case timeBlocked:
          mainStr = 'submit-order-error-date';
          break;
          case newClientBlocked:
          mainStr = 'submit-order-error-block';
          break;
          default:
          mainStr = "submit-order-error-unknown";
          break;
        }
      }
      else if (this.orderComplete) mainStr = 'submit-order-success';
      else mainStr = 'submit-order';

      return mainStr + attachStr;
    },
    beforeWindowUnload(e) {
      e.preventDefault();
      e.returnValue = '';
    },
    getLastCompanyDetailIndex() {
      return this.companyData.detail.length - 1;
    },
    processXdentRules() {
      const lastElIndex = this.getLastCompanyDetailIndex();
      const ruleSetData = [this.companyData.detail[lastElIndex].isEmailRequired, this.companyData.detail[lastElIndex].verifyEmail, this.companyData.detail[lastElIndex].isPhoneRequired];

      this.rules.email.push('email');

      if (ruleSetData[0] == false && ruleSetData[2] == false) {
        this.rules.email.push('required');
        this.rules.phone.push('required');
      }

      if (ruleSetData[0] == true && ruleSetData[2] == true) return (this.isEmailOrPhoneReq = true);

      if (ruleSetData[0] == true) this.rules.email.push('required');
      if (ruleSetData[2] == true) this.rules.phone.push('required');
    },
    changeFavicon(link) {
      if (!link) link = '/favicon.ico';
      let $favicon = document.querySelector('link[rel="icon"]');
      if ($favicon !== null) return ($favicon.href = link);

      $favicon = document.createElement('link');
      $favicon.rel = 'icon';
      $favicon.href = link;
      document.head.appendChild($favicon);
    },
    parsePhonePrefixes() {
      const arr = [];

      this.companyData.languages.map((l) => {
        arr.push({ value: l.phonePrefixTitle, label: l.phonePrefixTitle });
      });
      return arr;
    },
    changeSafariStyles() {
      let el = document.querySelector(':root');
      el.style.setProperty('--item-ml-mobile', '10px');
    },
    parseAndProcessInitQueryParams() {
      let search = window.location.search + window.location.hash.replace("#", "");
      const urlParams = new URLSearchParams(search);
      let firstName, lastName, email, phonePrefix, phone, idLocation, colorHex, idSchema, lang;

      firstName = urlParams.get('firstName');
      lastName = urlParams.get('lastName');
      email = urlParams.get('email');
      phonePrefix = urlParams.get('phonePrefix');
      phone = urlParams.get('phone');
      idLocation = urlParams.get('idLocation');
      colorHex = urlParams.get('color');
      idSchema = urlParams.get('idScheme');
      lang = urlParams.get("lang");

      if (firstName) this.clientFormData.firstName = firstName;
      if (lastName) this.clientFormData.lastName = lastName;
      if (email) this.clientFormData.email = email;
      if (phonePrefix) this.clientFormData.phonePrefix = phonePrefix;
      if (phone) this.clientFormData.phone = phone;
      if (idLocation) {
        let clinic = this.getClinicById(idLocation);
        if (clinic && clinic.services.length > 0) this.onClinicOpen(idLocation);
        this.selectedClinicId = idLocation
      }

      if (colorHex) {
        this.colorFromURL = true;
        if (colorHex.includes('#') === false) colorHex = '#' + colorHex;
      }
      if (idSchema) this.themeFromURL = true;

      if (lang && this.langConfig.includes(lang)) this.onLangChange(lang);

      this.themeManagerNew(colorHex, idSchema);
    },
    themeManagerNew(color, idScheme) {
      let root = document.querySelector(":root");
      const computed = getComputedStyle(root);

      let themeColorClass = hexToThemeColorClass(color);
      if (!themeColorClass && this.themeColorClass) root.classList.remove(this.themeColorClass);
      this.themeColorClass = themeColorClass;

      this.themeCode = this.getThemeCode(idScheme);
      let [themeClass, isThemeSpecial] = getThemeClass(this.themeCode); 
      this.isThemeSpecial = isThemeSpecial;

      if (isThemeSpecial && themeColorClass) themeColorClass += "-special";

      this.setThemeClass([...themeClass, themeColorClass]);

      root.style.removeProperty('--item-bg-color');
      root.style.removeProperty('--second-item-bg-color');
      root.style.removeProperty('--item-bg-hover-color');
      root.style.removeProperty('--content-background');
      root.style.removeProperty('--button-panel-background');
      root.style.removeProperty('--button-panel-box-shadow-color');
      root.style.removeProperty('--staff-detail-section-item-bg');

      if (themeColorClass) return;

      //add dynamically color coming from xdent
      const LIGHTER_PERC = 0.1;
      const DARKER_PERC = -0.1;

      let primary,secondary,primaryLighter;
      if (!color || color.length <= 1) {
        primary = computed.getPropertyValue('--primary');
        secondary = computed.getPropertyValue('--secondary');
        primaryLighter = computed.getPropertyValue('--primary-lighter');
      } else {
        primary = color;
        secondary = pSBC(DARKER_PERC, color.toUpperCase());
        primaryLighter = pSBC(LIGHTER_PERC, color.toUpperCase());
      }

      root.style.setProperty('--primary', primary);
      root.style.setProperty('--primary-lighter', primaryLighter);
      root.style.setProperty('--secondary', secondary);

      if (themeColorClass) {
        root.style.removeProperty('--primary');
        root.style.removeProperty('--primary-lighter');
        root.style.removeProperty('--secondary');

        primary = computed.getPropertyValue('--primary');
        primaryLighter = computed.getPropertyValue('--primary-lighter');
        secondary = computed.getPropertyValue('--secondary');
      }


      if (!this.isThemeSpecial) {
        root.style.setProperty('--select-item-bg-color', hexToRgbA(primary, 0.5));
        root.style.setProperty('--select-item-border-color', hexToRgbA(primary, 0.6));
      }
      if (this.isThemeSpecial) {
        root.style.removeProperty('--select-item-bg-color');
        root.style.removeProperty('--select-item-border-color');
        if (!themeColorClass) {
          root.style.setProperty('--select-item-bg-color', hexToRgbA(primary, 0.5));
          root.style.setProperty('--select-item-border-color', hexToRgbA(primary, 0.6));
        }

        root.style.setProperty('--item-bg-color', secondary);
        root.style.setProperty('--staff-detail-section-item-bg', secondary);
        root.style.setProperty('--second-item-bg-color', secondary);
        root.style.setProperty('--item-bg-hover-color', primaryLighter);
        root.style.setProperty('--content-background', primary);
        root.style.setProperty('--button-panel-background', secondary);
        root.style.setProperty('--button-panel-box-shadow-color', primaryLighter);
      }
    },
    themeManager(color, idScheme) {
      let themeColorClass = hexToThemeColorClass(color);
      if (!themeColorClass) return false; //not a color we know, go ahead and use the old theme system

      this.themeCode = this.getThemeCode(idScheme);
      let [themeClass, isThemeSpecial] = getThemeClass(this.themeCode); 
      this.isThemeSpecial = isThemeSpecial;

      if (isThemeSpecial) themeColorClass += "-special";

      this.setThemeClass([...themeClass, themeColorClass]);
      return true;
    },
    setUpDynamicColors(color) {
      let el = document.querySelector(':root');
      const computed = getComputedStyle(el);
      const LIGHTER_PERC = 10;
      const DARKER_PERC = -10;

      let primary = color;
      let secondary = shadeColor(color, DARKER_PERC);
      let primaryLighter = shadeColor(color, LIGHTER_PERC);

      //let matchedClass = hexToThemeColorClass(color);

      if (!color || color.length <= 1) {
        primary = computed.getPropertyValue('--primary');
        secondary = computed.getPropertyValue('--secondary');
        primaryLighter = computed.getPropertyValue('--primary-lighter');
      }


      el.style.setProperty('--primary', primary);
      el.style.setProperty('--primary-lighter', primaryLighter);
      el.style.setProperty('--secondary', secondary);
      el.style.setProperty('--inverted-primary-color', checkContrast(primary, false));
      el.style.setProperty('--theme-primary-color', checkContrast(primary, true));

    },
    modifyClinics() {
      const getDetail = (id) => {
        return this.companyData.detail.find(d => d.idLocation === id);
      }

      const newClinics = this.companyData.clinics.map(c => {
        const dtl = getDetail(c.id);
        c = {...c, ...dtl};
        return c;
      })
      this.companyData.clinics = newClinics;
    },
    async onOrderCancel() {
      if (this.orderCancelStatus != 0) return;

      this.appointmentLoading = true;
      const res = await cancelAppointment({idAppointment: this.idAppointment, idCompany: this.idCompany});

      if (res == true) {
        this.appointmentLoading = false;
        this.orderCancelScreen = true;
        this.orderCancelStatus = 1;

        return true;
      }

      if ("codeType" in res || "errors" in res) {
        this.setAppointmentCancelFail();
        this.appointmentLoading = false;
        this.orderCancelStatus = 4;
        console.error("Failed to cancel order");
        return false;
      }
    },
    onCancelOrderScreenClose() {
      this.orderCancelScreen = false;
    },
    getCancelAppointmentData() {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });

      if (params["aud"]) this.idAppointment = params.aud;
      if (params["cud"]) this.idCompany = params.cud;
    },
    setAppointmentCancelUnavailable() {
      this.orderCancelStatus = 2;
    },
    setAppointmentExpired() {
      this.orderCancelScreen = true;
      this.orderCancelStatus = 3;
    },
    setAppointmentCancelFail() {
      this.orderCancelScreen = true;
      this.orderCancelStatus = 4;
    },
    setAppointmentAlreadyCanceled() {
      this.orderCancelStatus = 5;
    },
    async getAppointmentData() {
      if (!this.idAppointment) return console.error("No appointment id provided");

      try {
        const {appointment} = await getCancelMetadata(this.idAppointment);

        if (!appointment) {
          this.isStaffLoading = false;
          this.companyDataLoading = false;
          this.isCompanyError = true;
          return console.error("No appointment found");
        }

        this.appointmentData = appointment;

        this.companyDataLoading = false;

        if (appointment.isAlreadyCancelled) this.setAppointmentAlreadyCanceled();
      } catch (error) {
        console.error(error);
        this.isStaffLoading = false;
        this.companyDataLoading = false;
        this.isCompanyError = true;
      }    
    }
  },
  async created() {
    this.initLoader = await getSvg("kolo.svg");
    this.isSafari = getIsSafari();
    if (this.isSafari) this.changeSafariStyles();
    
    this.getCancelAppointmentData();
    
    let data;
    try {
      data = await getCompanyMetadata();
    } catch (error) {
      this.isStaffLoading = false;
      this.companyDataLoading = false;
      this.isCompanyError = true;
      return;
    }
    if (data['codeType'] || !data) {
      this.isStaffLoading = false;
      this.companyDataLoading = false;
      this.isCompanyError = true;
      return;
    }
    this.isStaffLoading = false;
    this.companyData = data;
    let langInit = this.companyData.config.ietfLanguageTag.split('-')[1].toLowerCase();
    let storedLangKey = getLocalParam(getAppConfig("language_localS_key_str"));
    if (storedLangKey) langInit = storedLangKey;
    this.onLangChange(langInit);
    this.processCustomInputs();
    this.processXdentRules();
    this.modifyClinics();
    this.clientFormData.phonePrefix = this.companyData.config.phonePrefix;

    this.phonePrefixList = this.parsePhonePrefixes();

    this.parseAndProcessInitQueryParams();
    this.summarySvgIcon = await getSvg('sluzba-ic.svg');
    
    if (this.$route.name !== routes.CANCEL_ORDER) this.companyDataLoading = false;
    else await this.getAppointmentData();

    window.addEventListener('popstate', () => {
      if (this.orderComplete) window.location.href = '/';
    });
    
    document.body.addEventListener('touchmove', function(evt) {
      if(!evt._isScroller) {
        evt.preventDefault()
      }
    })
  },
  mounted() {
    window.addEventListener('resize', () => this.checkIsMobile());
    this.checkIsMobile();
  },
};
</script>

<style>
.lang-switch {
  right: 1vw;
  top: 2.5vh;
}
@media only screen and (max-width: 850px) {
  .lang-switch {
    top: 1vh;
  }
}
</style>

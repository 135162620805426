import http from './http';

export const getAvailableDatesInMonth = (idLocation, idService, idStaff, date) => {
  return http.get('/getavailabletimesinmonth', { idLocation, idService, idStaff, date });
};
export const getAvailableDates = (idService, idStaff) => {
  return http.get('/getavailabletimes', { idService, idStaff });
};
export const getAllAvailableDates = (idService, staffs) => {
  const allIds = staffs.map((s) => ({ value: s.idStaff, key: 'idStaffs' }));

  allIds.unshift({ value: idService, key: 'idService' });

  return http.get('/getavailabletimesall', allIds);
};
export const checkIsDateOpen = (date, idService, idStaff) => {
  return http.get('/isavailabletime', { dateTime: date, idService, idStaff });
};

<template>
  <div class="popup-wrapper d-flex">
    <div class="popup-text-wrapper">
      <p>{{ text }}</p>
    </div>
    <div class="chev-icon" v-bind:class="['chev-icon-' + position]" alt="chevron-icon"></div>
  </div>
</template>

<script>
export default {
  props: ['position', 'text'],
};
</script>

<style>
.popup-wrapper {
  position: absolute;
  border-radius: 5px;
  background-color: #36b9e0;
  color: var(--white);
  z-index: 10;
  justify-content: center;
  padding: 10px;
}
.popup-text-wrapper {
  position: relative;
  z-index: 10;
  margin: 0 !important;
  font-size: 14px;
}
.chev-icon {
  position: absolute;
  background-color: #36b9e0;
  transform: rotate(45deg);
  width: 22px;
  height: 22px;
}
.chev-icon-bottom {
  left: calc(50% - 11px);
  bottom: -8px;
}
</style>

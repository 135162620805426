import http from './http';

export const getCompanyMetadata = () => http.get('/metadatabyminiwebsite');

export const getCancelMetadata = (idAppointment) => http.get("/metadatabyappointment", {idAppointment});

export const getPhotoFile = (id) => http.get(`/getfile`, { idFile: id, size: '100x100', imageAsType: '2' });

export const getStaffPhotoSrc = async (id) => {
  const DEFAULT_ICON = require('../assets/icons/lekar.svg');

  if (!id) return DEFAULT_ICON;
  let photo;
  try {
    photo = await getPhotoFile(id);
  } catch (error) {
    return DEFAULT_ICON;
  }
  return `data:image/png;base64,${photo}`;
};

export const sendClientOrderData = (payload) => http.post('/sendunknownclient', JSON.stringify(payload));

export const cancelAppointment = (payload) => http.post("/cancelappointment", JSON.stringify(payload));
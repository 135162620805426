<template>
      <div class="init-loader-wrapper error-default-logo d-flex flex-column" v-bind:class="{'loader-wrapper-special': specialOverlay}">
      <img :src="require('@/assets/icons/x.svg')" class="x-bg" alt="">
      <div class="spin" v-html="initLoader"></div>
      <span class="init-loader-text">Načítání...</span>
    </div>
</template>

<script>
export default {
    props: ["initLoader", "specialOverlay"]
}
</script>

<style>
    .loader-wrapper-special {
        background-color: #4e4e4e;
        z-index: 11;
    }
    .loader-wrapper-special.error-default-logo {
        opacity: 0.8 !important;
    }
</style>
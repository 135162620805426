<template>
  <div class="not-found-wrapper">
     <NotfoundOverlay/>
  </div>
</template>

<script>
import NotfoundOverlay from "../Overlays/NotfoundOverlay.vue"
export default {
    components: {
        NotfoundOverlay
    },
    created() {
       //window.location.href = "404-notfound.html"
    }
}
</script>

<style>
    .not-found-wrapper {
        position: absolute;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        z-index: 9999;
        background-color: #fff;
    }
</style>
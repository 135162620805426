export const getSvg = async (name, el) => {
  let realPath = require(`../assets/icons/${name}`);
  const req = await fetch(realPath);
  const svg = await req.text();

  if (el) {
    el = document.querySelector('#' + el);
    el.innerHTML = svg;
    return;
  }
  return svg;
};

export const setLocalParam = (key, val) => {
    try {
        localStorage.setItem(key, val);
    } catch (error) {
        console.error(error);
        return false;
    }
    return true;
}
export const getLocalParam = key => {
    return localStorage.getItem(key);
}
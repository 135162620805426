<template>
  <div class="noselect loader-wrapper d-flex" v-if="loading">
    <img class="noselect loader" :src="require('@/assets/icons/loader.svg')" alt="loader" />
  </div>
</template>

<script>
export default {
  props: ['loading'],
};
</script>

<style>
.loader-wrapper {
  position: absolute;
  z-index: 10000;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 100%;
}
.loader {
  width: 150px;
}
</style>

<template>
  <div v-if="appointmentData" class="d-flex flex-column cancel-order-wrapper" style="width: 100%; height: 100%; justify-content: space-between">
    <div class="success-header d-flex">
      <img v-if="!contentData.mobile && icon" :src="icon" alt="success-icon" class="success-icon" />
      <div class="d-flex flex-column">
        <h2 v-bind:style="!subTitle ? 'margin-bottom: 0px;' : ''">{{$t(title)}}</h2> <!--přejete si zrušit rezervaci-->
        <span>{{$t(subTitle)}}</span>
      </div>
    </div>
    <div class="summary-wrapper d-flex flex-column">
      <div v-if="this.contentData.orderCancelStatus > 1 && this.contentData.orderCancelStatus < 4 && location && (location.email || location.phone)" class="cancel-order-unavailable-wrpr">
        <img :src="require('../assets/icons/otaznik_ic_error.svg')" alt="otaznik">
        <p>
          Pro další požadavek kontaktujte
          <span v-if="location.phone" class="phone-num">tel.: {{formatPhone(location.phone)}}</span>
          <span v-if="location.email" style="margin-left: 3px" class="email">{{location.email}}</span>
        </p>
      </div>
      <div class="scroller d-flex flex-column">
        <span class="client-label">{{ $t('client-label') }}</span>
        <h2 class="client-name">{{ appointmentData.subject }}</h2>
        <div class="summary-item-list d-flex flex-row-wrap">
          <SummaryTextItem
            :itemName="$t('selected-service-label')"
            :title="appointmentData.serviceCaption"
            :subtitle="serviceDuration"
            :imgSrc="contentData.serviceIcon"
            :isHtmlSrc="contentData.serviceIconIsHTML"
            :isRounded="true"
          />
          <SummaryTextItem :itemName="$t('selected-location-label')" :wrapperPointer="true" :onWrapperClick="contentData.onAddressClick" :title="locationTitle" :subtitle="locationSubtitle" :imgSrc="contentData.locationIcon" :imgIsIcon="true" />
          <SummaryTextItem :itemName="$t('selected-staff-label')" :subtitle="appointmentData.staff" :imgSrc="staffPfp" :isRounded="true" />
            <SummaryTextItem
                :errorIconSrc="dateIconError"
                :errorStyle="summaryTextErrorStyle"
                :imgIsIcon="true"
                :itemName="$t('selected-date-label')"
                :imgSrc="dateIcon"
                :title="dateTitle"
                :subtitle="dateSubtitle"
            />
        </div>
      </div>
    </div>
    <ButtonPanel
      :finishState="true"
      :iconId="contentData.iconId"
      :mobile="contentData.mobile"
      :cancelDisabled="true"
      :orderErrorCode="contentData.errorCode"
      :errorBtn="true"
      :errorBtnCancelOrder="true"
      v-on:select="onSelect"
      :submitTitle="btnTitle"
      :disabled="false"
      :loading="contentData.loading"
      class="summary-btn-panel"
      :childrenHidden="submitDisabled"
    />
  </div>
</template>

<script>
import SummaryTextItem from '../components/SummaryTextItem.vue';
import ButtonPanel from '../components/ButtonPanel.vue';

import moment from "moment";
import { capitalize } from '../helpers/textHelper';
import { formatPhoneNum } from '../helpers/formatHelper';
import { getAppConfig } from '../configManager';

export default {
  props: {
    contentData: Object,
  },
  components: {
    SummaryTextItem,
    ButtonPanel,
  },
  data() {
    return {
      title: "",
      subTitle: "",
      icon: null,
      btnTitle: "",
      appointmentData: null,
      serviceDuration: 0,
      staffPfp: require('../assets/icons/lekar.svg'),
      dateTitle: "",
      dateSubtitle: "",
      dateIcon: require('../assets/icons/cas.svg'),
      dateIconError: require('../assets/icons/cas-error.svg'),
      locationTitle: "",
      locationSubtitle: "",
      alreadyOccured: false,
      submitDisabled: false,
      location: null
    }
  },
  computed: {
    summaryTextErrorStyle() {
        const {timeBlocked} = getAppConfig("orderErrorCodes");

        return this.contentData.orderDateError && this.contentData.errorCode == timeBlocked
    }
  },
  methods: {
    onSelect() {
      if (this.submitDisabled) return;
      this.contentData.onOrderCancel();
    },
    getTexts() { //move this to computed?
      const obj = {
        title: "",
        subTitle: "",
        icon: null,
        btnTitle: ""
      }

      switch (this.contentData.orderCancelStatus) {
        case 0:
          obj.title = "order-cancel-title"
          obj.subTitle = "order-cancel-subtitle"
          obj.icon = require('@/assets/icons/otaznik_ic.svg'),
          obj.btnTitle = "cancel-order"
        break;
        case 1:
          obj.title = "order-canceled-title"
          obj.subTitle = "order-canceled-subtitle"
          obj.icon = require('@/assets/icons/ok_ic.svg'),
          obj.btnTitle = "close"
          this.submitDisabled = true;
        break;
        case 2:
          obj.title = "order-cancel-unavailable-title"
          obj.subTitle = "order-cancel-unavailable-subtitle"
          obj.icon = require('@/assets/icons/cross_ic.svg'),
          obj.btnTitle = "close"
          this.submitDisabled = true;
        break;
        case 3:
          this.submitDisabled = true;
        break;
        case 4:
          obj.title = "order-canceled-fail-request-title"
          obj.subTitle = this.location.phone || this.location.email ? `Pro další požadavek kontaktujte ${this.location.phone ? "tel.:" + this.formatPhone(this.location.phone) : ""} ${this.location.email ? this.location.email : ""}` : "";
          obj.icon = require('@/assets/icons/cross_ic.svg'),
          obj.btnTitle = "close"
          this.submitDisabled = true;
        break;
        case 5:
          obj.title = "order-already-canceled-request-title"
          obj.subTitle = ""
          obj.icon = require('@/assets/icons/cross_ic.svg'),
          obj.btnTitle = "close"
          this.submitDisabled = true;
        break;
      }

      if (obj.title === "") return;

      this.title = obj.title;
      this.subTitle = obj.subTitle;
      this.icon = obj.icon;
      this.btnTitle = obj.btnTitle;
    },
    formatPhone(num) {
      return formatPhoneNum("", num);
    },
    processAppointmentData() {
      const {startDate, endDate, idLocation} = this.appointmentData;
      const start = moment(startDate);
      const end = moment(endDate);

      this.alreadyOccured = end.diff(start) <= 0;

      if (this.alreadyOccured) {
        this.contentData.setAppointmentExpired();
        this.submitDisabled = true;
      }

      const diff = moment.duration(end.diff(start)).asMinutes() + " minut";

      this.serviceDuration = diff;
      this.dateTitle = `<span class="font-medium">${capitalize(start.format('dddd'))}</span> ${start.format('DD.MM.YYYY')}`;
      this.dateSubtitle = `<span class="font-medium">${start.format('HH:mm')} - ${end.add(diff, 'minutes').format('HH:mm')}</span> hodin`;
    
      const location = this.contentData.locations.find(l => l.idLocation === idLocation); 

      if (!location) return console.error("No location found");

      this.location = location;

      this.locationTitle = location.name;
      this.locationSubtitle = `${location.street}, ${location.post} ${location.city}`;
    },
    checkIfCancellable() {
      if (this.appointmentData.canBeCancelled || this.contentData.orderCancelStatus == 5) return;

      this.submitDisabled = true;
      this.contentData.setAppointmentCancelUnavailable();
    }
  },
  mounted() {
    this.getTexts();
  },
  updated() {
    this.getTexts();
  },
  async created() {
      this.appointmentData = this.contentData.appointmentData;

      this.processAppointmentData();
      this.checkIfCancellable();
  }
};
</script>

<style>
  .cancel-order-wrapper .success-header {
    background-color: #ED1C24;
  }
  .cancel-order-wrapper .summary-item .wrapper {
    border-color: #F2565C;
  }
  .cancel-order-wrapper .img-wrapper #sluzba-zub-outter, .img-wrapper .sluzba-zub-outter {
    fill: #F2565C;
  }

  .app-wrapper-cancel-order {
    grid-template-areas: 'header header'
                         'content content' !important;
    max-width: 1024px !important;
  }
  .btn-panel-error-special {
    background-color: #F2565C !important;
    color: white;
    margin-right: 0 !important;
  } 
  .cancel-order-unavailable-wrpr {
    color: #F2565C;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    position: absolute;
    left: 7.5%;
    top: 20px;
    font-weight: 300;
  }
  .cancel-order-unavailable-wrpr .phone-num, .cancel-order-unavailable-wrpr .email {
    font-weight: 400;
    margin: 0 5px;
  }
  .cancel-order-unavailable-wrpr img {
    margin-right: 10px;
    width: 26px;
    height: 26px;
  }
</style>

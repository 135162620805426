<template>
  <ValidationProvider class="select-input-wrapper d-flex flex-column input-wrapper" style="width: 100%" tag="div" :name="name" :rules="rulesPipe" v-slot="{ errors }">
    <div class="select-input-main d-flex flex-row">
      <label v-if="label">{{ label }}</label>
      <AppSelect
        v-bind:style="!label ? 'width: 100%' : ''"
        :options="options"
        @input="onInput"
        :value="value"
        :name="name"
        :localizedValue="localizedValue"
        :class="{ 'input-error': errors.length > 0 && errorActivated, 'input-warning-error': errors.length > 0 && errorActivated && isWarningError }"
      />
      <div v-if="displayError && errorActivated && errors.length > 0" class="error-input-icon" v-html="isWarningError ? warningIcon : errorIcon"></div>
    </div>
    <span class="error-text" v-bind:class="{ 'error-warning-text': isWarningError }" v-if="displayError && errorActivated && errors.length > 0">{{ errors[0] }}</span>
  </ValidationProvider>
</template>

<script>
import AppSelect from './AppSelect.vue';

export default {
  components: {
    AppSelect,
  },
  props: {
    name: String,
    rules: Array,
    label: String,
    displayError: Boolean,
    model: String,
    options: Array,
    localizedValue: {
      type: Boolean,
      default: () => true,
    },
    isWarningError: Boolean,
    errorIcon: String,
    warningIcon: String
  },
  methods: {
    onFocus() {
      this.errorActivated = true;
    },
    onInput(newVal) {
      this.$emit('change', { newVal, name: this.name });
    },
  },
  data() {
    return {
      rulesPipe: '',
      value: '',
      errorActivated: true,
    };
  },
  watch: {
    isWarningError: function () {
      this.onFocus();
    },
  },
  created() {
    this.rules.map((r) => {
      if (this.rulesPipe !== '') this.rulesPipe += '|';

      this.rulesPipe += r;
    });

    this.value = this.model;
  },
};
</script>

<style>
.select-input-wrapper {
  align-items: center;
}
.select-input-wrapper select {
  border: 1px solid rgba(100, 100, 100, 0.3);
  border-radius: 10px;
  padding: 5px 10px;
  box-sizing: border-box;
  height: 50px;
  font-size: 16px;
  font-weight: 300;
}
.select-input-main {
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 0 !important;
}
.select-input-wrapper option,
.select-input-wrapper select {
  opacity: 0.7;
}
.select-input-wrapper option,
.select-input-wrapper label {
  font-weight: 300;
}
.select-input-wrapper label {
  margin-right: 3vw;
  text-align: left;
  color: var(--form-label-errorwarn-c);
}
.select-input-wrapper {
  text-align: right;
}
.select-input-wrapper .error-text {
  margin-right: 15px;
}
.select-input-wrapper {
  text-align: right;
}
.select-input-wrapper .input-error {
  padding-right: calc(5% + 20px) !important;
}
.select-input-wrapper .wrapper {
  position: relative;
  margin: 0;
}
.select-input-wrapper .error-input-icon {
  position: absolute;
  right: 5%;
  top: calc(50% - 9px);
}
.select-input-wrapper select option {
  padding: 5px 0;
  line-height: 25px;
  font-size: 16px;
  box-sizing: border-box;
}
.select-input-wrapper select option:hover {
  cursor: pointer;
}
@media only screen and (max-width: 850px) {
  .select-input-wrapper label {
    margin-right: 0;
    width: fit-content;
    max-width: 40%;
  }
  .select-input-wrapper .app-select-wrapper {
    width: 60%;
  }
  .phone-wrapper {
    margin: 1% 0 !important;
  }
}
</style>

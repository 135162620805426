//import { removeWhitespaces } from './formatHelper';
import i18n from '../i18n';

const findUsOptions = ['find-us-recommended', 'find-us-internet', 'find-us-ecatalogue', 'find-us-catalogue', 'find-us-video', 'find-us-other'];

const getRawInputTypeToParsed = (typeRaw) => {
  switch (typeRaw) {
    case 'NOTES':
      return ['TextArea', ''];
    case 'OPT':
      return ['CheckboxInput', false];
    case 'CHK':
      return ['CheckboxInput', false];
    case 'COMBO':
      return ['SelectInput', ''];
    case 'FIND':
      return ['SelectInput', 'find-us-recommended'];
    case 'PROMO':
      return ['TextInput', ''];
    case 'ADDRESS':
      return ['TextInput', ''];
    case 'TEXT':
      return ['TextInput', ''];
  }
};
const parseOptions = (rawOpt, translate = false) => {
  const parsedArr = [];
  const oldLocale = i18n.locale;
  i18n.locale = 'cz';
  rawOpt.forEach((rO) => {
    let value = rO;
    if (translate) value = i18n.t(rO);
    parsedArr.push({ value, label: rO });
  });
  i18n.locale = oldLocale;

  return parsedArr;
};
export const formatCustomInputData = (data, models) => {
  const parsedInputs = [];
  data.forEach(({ code, label, isRequired, instruction }) => {
    const parsedInputObj = {
      label,
      name: label.toLowerCase(),
      type: null,
      rules: null,
      options: [],
      code,
      localizedValue: false,
    };

    const type = code.split('_')[1];

    if (type === 'ADDRESS') return;

    const ruleArr = [];
    if (isRequired) ruleArr.push('required');
    parsedInputObj.rules = ruleArr;

    let [parsedType, defValue] = getRawInputTypeToParsed(type);
    parsedInputObj.type = parsedType;

    if (type === 'FIND') {
      parsedInputObj.options = parseOptions(findUsOptions, true);
      defValue = parsedInputObj.options[0].value;
      parsedInputObj.localizedValue = true;
    } else if (instruction) {
      const instrArr = instruction.split(',');
      parsedInputObj.options = parseOptions(instrArr);
      defValue = parsedInputObj.options[0].value;
    }

    if (type === 'NOTES') parsedInputObj.localizedValue = true;

    parsedInputs.push(parsedInputObj);
    models = { ...models, [parsedInputObj.name]: defValue };
  });

  return [parsedInputs, models];
};
export const pickOutCustomInputs = (formData, customInputs) => {
  let cFormDara = { ...formData };
  const pickedOutValues = [];
  let pickedOutExceptValues = {};
  const exceptionValues = ['INFO_PROMO', 'INFO_FIND', 'INFO_OPT_IN', "INFO_NOTES"];

  customInputs.forEach(({ name, label, code }) => {
    const obj = { name, value: cFormDara[name], label: label };
    delete cFormDara[name];

    if (exceptionValues.includes(code)) return (pickedOutExceptValues = { ...pickedOutExceptValues, [code]: obj });

    pickedOutValues.push(obj);
  });

  return [customInpDataArrToStr(pickedOutValues), cFormDara, pickedOutExceptValues];
};

export const customInpDataArrToStr = (data) => {
  let str = '';

  data.map(({ label, value }, i) => {
    if (i > 0) str += '##';

    str += `${label}$$${value}`;
  });

  return str;
};

export const getNumRuleOfPrefix = (prefix) => {
  const obj = {
    '+420': ['digits:9'],
    '+421': ['digits:9'],
    '+49': ['min:10', 'max: 11'], //10, 11
    '+44': ['digits:10'],
    '+34': ['digits:9'],
    '+33': ['digits:9'],
    '+43': ['min:10', 'max: 11'], //10 11
    '+39': ['min:9', 'max: 10'], //90 10
    '+32': ['digits:9'],
    '+41': ['digits:9'],
    '+48': ['digits:9'],
    '+30': ['digits:10'],
    '+45': ['digits:8'],
  };

  if (!obj[prefix]) return [];

  return obj[prefix];
};

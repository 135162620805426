<template>
  <div v-bind:style="staffDetailsOpen ? 'height: 100%' : 'height: 100%'" style="justify-content: space-between" class="d-flex flex-column">
    <ErrorOverlay
      v-if="!contentData.staffs || (filteredStaffs.length === 0 && !openStaffId)"
      :icon="require('@/assets/icons/staff_overlay.svg')"
      style="margin-top: 25vh"
      :subtitle="$t('no-staff-overlay-subtitle')"
      :title="$t('no-staff-available')"
    />
    <div class="d-flex flex-column main-staff-wrapper">
      <div ref="scroller" class="scroller d-flex flex-column">
        <StaffDetails
          v-bind:data-staff-id="openStaffId"
          :showBorder="filteredStaffs.length === 0 && openStaffId"
          :isTemplate="openStaffId == earliestStaffId"
          :monthLimit="monthLimit"
          class="selection-staff-details"
          :dateDisplayLimit="dateDisplayLimit"
          :noonTimes="noonTimes"
          :afternoonTimes="afternoonTimes"
          :highlighted="highlightedDates"
          :name="openStaff.name"
          :photoSrc="openStaff.photoSrc"
          :datesAval="openStaff.datesAval"
          :contentData="contentData"
          v-if="staffDetailsOpen && openStaffId"
          v-on:month-change="onMonthChange"
          v-on:day-change="onDayChange"
          v-on:time-change="onTimeChange"
        />
        <div
          class="clinic-service-staff flex-layout-list d-flex"
          v-bind:style="!contentData.staffs || filteredStaffs.length === 0 ? 'justify-content: center' : ''"
          v-bind:class="{ 'pd-top': openStaffId }"
        >
          <span v-if="filteredStaffs.length > 1 && openStaffId" class="more-staff-title">{{ $t('staff-pick-others') }}</span>
          <StaffItem
            :isTemplate="s.idStaff == earliestStaffId"
            v-for="s in filteredStaffs"
            :dateDisplayLimit="dateDisplayLimit"
            :datesAval="s.datesAval"
            :key="s.idStaff"
            :name="s.name"
            :id="s.idStaff"
            :photoSrc="s.photoSrc"
            v-on:open-staff="onOpenStaff"
            :defaultStaffIcon="contentData.defaultStaffIcon"
          />
        </div>
      </div>
    </div>
    <ButtonPanel
      :hintComplete="(openStaffId && contentData.selectedTime) || !contentData.staffs || filteredStaffs.length === 0 ? true : false"
      :iconId="contentData.iconId"
      :mobile="contentData.mobile"
      :hintText="$t('hint_staff')"
      :hideSelectBtn="!staffDetailsOpen"
      :submitTitle="'select-title'"
      :loading="false"
      v-on:cancel="onCancelStaffSelect"
      v-on:select="onSelectStaff"
      class="staff-button-panel"
      :disabled="openStaffId === null || contentData.selectedDay === null || contentData.selectedTime === null"
    />
  </div>
</template>

<script>
import StaffItem from '../components/Staff/StaffItem.vue';
import StaffDetails from '../components/Staff/StaffDetails.vue';
import ButtonPanel from '../components/ButtonPanel.vue';
import ErrorOverlay from '../Overlays/ErrorOverlay.vue';
import { getAfternoonTimes, getNoonTimes, timestampToDay, timestampToMonthYear, getEarliestDate } from '../helpers/timesOfDayHelper';
import { simpleFadeIn } from '../helpers/animationHelper';

export default {
  components: {
    StaffItem,
    StaffDetails,
    ButtonPanel,
    ErrorOverlay,
  },
  props: {
    contentData: Object,
  },
  data() {
    return {
      staffDetailsOpen: false,
      openStaffId: null,
      openStaff: null,
      highlightedDates: { daysOfMonth: [] },
      afternoonTimes: [],
      noonTimes: [],
      dateDisplayLimit: 20,
      filteredStaffs: [],
      monthLimit: 2,
      idOfSelectedTime: null,
      earliestStaffId: -1,
      earliestStaffData: {
        idStaff: null,
        datesAval: {},
        datesReady: true,
        name: 'earliest-date-staff',
        photoSrc: require('../assets/icons/lekar.svg'),
      },
    };
  },
  watch: {
    openStaff: function (newStaff) {
      this.updateTimes(newStaff);
    },
    'contentData.selectedDay': function () {
      this.updateTimes();
    },
    'contentData.staffs': function () {
      this.filterStaffList();
      if (this.filteredStaffs.length === 1 && !this.openStaff) this.openFirstStaff(this.contentData.selectedStaffId ? false : true);
    },
  },
  created() {
    this.earliestStaffData.idStaff = this.earliestStaffId;
    this.filterStaffList();
    if (this.filteredStaffs.length === 1) this.openFirstStaff(this.contentData.selectedStaffId ? false : true);

    const urlParams = new URLSearchParams(window.location.search);
    const openStaffId = urlParams.get('openStaff');

    if (openStaffId) this.onOpenStaff(openStaffId);
    if (this.contentData.selectedStaffId) this.onOpenStaff(this.contentData.selectedStaffId, false);
  },
  methods: {
    updateTimes(newStaff) {
      if (newStaff === null) return;
      const month = this.openStaff.datesAval[timestampToMonthYear(this.contentData.selectedMonth, true)];
      const stringHighlightedArr = month ? Object.keys(month) : [];

      this.highlightedDates.daysOfMonth = stringHighlightedArr.map((d) => parseInt(d));
      let day = month ? month[timestampToDay(this.contentData.selectedDay)] : [];
      this.afternoonTimes = getAfternoonTimes(day);
      this.noonTimes = getNoonTimes(day);

      if (day) return;
    },
    updateOpenStaff() {
      let newStaff = this.filteredStaffs.find((s) => s.idStaff === this.openStaffId);
      if (newStaff) this.openStaff = newStaff;
    },
    onSelectStaff() {
      let id;
      if (this.openStaffId === this.earliestStaffId) id = this.idOfSelectedTime;
      else id = this.openStaffId;
      this.contentData.onSelectStaff(id);
    },
    checkIfStaffHasMoreDates(mKey, dKey, idNow, idPrev) {
      let prevStaff = this.filteredStaffs.find((s) => s.idStaff === idPrev);
      let nowStaff = this.filteredStaffs.find((s) => s.idStaff === idNow);

      if (!prevStaff.datesAval[mKey] || !prevStaff.datesAval[mKey][dKey]) return true;
      if (!nowStaff.datesAval[mKey] || !nowStaff.datesAval[mKey][dKey]) return false;

      return nowStaff.datesAval[mKey][dKey].length > prevStaff.datesAval[mKey][dKey].length;
    },
    getAllDatesAval() {
      let allDatesAvalObj = {};
      let checkFunc = this.checkIfStaffHasMoreDates;
      this.filteredStaffs.forEach((s) => {
        for (const m in s.datesAval) {
          const monthAval = {...s.datesAval[m]};
          if (!allDatesAvalObj[m]) allDatesAvalObj = { ...allDatesAvalObj, [m]: monthAval };
          else {
            for (const d in monthAval) {
              if (!allDatesAvalObj[m][d]) allDatesAvalObj[m][d] = monthAval[d];
              else allDatesAvalObj[m][d] = [...allDatesAvalObj[m][d], ...monthAval[d]];
            }
          }
        }
      });
      for (const m in allDatesAvalObj) {
        for (const d in allDatesAvalObj[m]) {
          const uniq = allDatesAvalObj[m][d]
            .slice()
            .sort((a, b) => Date.parse(a.dateTime) - Date.parse(b.dateTime))
            .reduce(function (a, b) {
              const lastEl = a.slice(-1)[0];

              if (!lastEl || lastEl.dateTime !== b.dateTime) a.push(b);
              else {
                if (checkFunc(m, d, b.idStaff, lastEl.idStaff)) lastEl.idStaff = b.idStaff;
              }
              return a;
            }, []);
          allDatesAvalObj[m][d] = uniq;
        }
      }
      return allDatesAvalObj;
    },
    filterStaffList() {
      this.filteredStaffs = this.contentData.staffs
        .filter((s) => Object.keys(s.datesAval).length > 0)
        .filter((s) => s.idStaff != this.openStaffId)
        .sort((a, b) => {
          return Date.parse(Object.values(Object.values(a.datesAval)[0])[0][0].dateTime) - Date.parse(Object.values(Object.values(b.datesAval)[0])[0][0].dateTime);
        });
      if (Object.keys(this.earliestStaffData.datesAval).length === 0) this.earliestStaffData.datesAval = this.getAllDatesAval();
      if (this.openStaffId != this.earliestStaffId && ((this.filteredStaffs.length > 0 && this.staffDetailsOpen) || (this.filteredStaffs.length > 1 && !this.staffDetailsOpen))) this.filteredStaffs.unshift(this.earliestStaffData);
    },
    onOpenEarliestDate() {
      let earliestStaffId = null;
      let lowestDate = Number.POSITIVE_INFINITY;
      this.contentData.staffs.forEach((s) => {
        const parsedDates = Object.values(s.datesAval);
        if (parsedDates.length === 0) return;

        const date = Date.parse(Object.values(parsedDates[0])[0][0].dateTime);
        if (date < lowestDate) {
          lowestDate = date;
          earliestStaffId = s.idStaff;
        }
      });
      this.onOpenStaff(earliestStaffId);
    },
    onOpenStaff(id, resetTime = true) {
      if (!id) {
        this.openStaffId = null;
        this.openStaff = null;
        this.contentData.onOpenStaff(null);
        return;
      }
      this.staffDetailsOpen = true;
      this.openStaffId = id;
      this.updateOpenStaff();
      let earliestDate = getEarliestDate(this.openStaff.datesAval, true);
      if (resetTime) {
        this.onMonthChange(earliestDate);
        this.onDayChange(earliestDate);
        this.onTimeChange(null);
      }
      this.jumpToStaffDetails();
      this.filterStaffList();


      if (this.openStaffId === this.earliestStaffId) id = this.idOfSelectedTime;
      else id = this.openStaffId;
      if (id) this.contentData.onOpenStaff(id);

      let detailEl = document.querySelector(".staff-details-wrapper");
      if (!detailEl) return;
      detailEl.setAttribute("data-staff-id", this.openStaffId);
      detailEl.style.opacity = 0;
      simpleFadeIn(detailEl, 0, this.openStaffId);
    },
    onMonthChange(m) {
      const parsedM = typeof m === 'object' || typeof m === 'string' ? Date.parse(m) : m;
      this.contentData.onSelectMonth(parsedM);
    },
    onDayChange(d) {
      const parsedD = typeof d === 'object' || typeof d === 'string' ? Date.parse(d) : d;
      this.contentData.onSelectDay(parsedD);
    },
    onTimeChange(t) {
      if (Array.isArray(t)) this.idOfSelectedTime = t[1];
      this.contentData.onSelectTime(t);

      if (this.openStaffId === this.earliestStaffId) this.contentData.onOpenStaff(this.idOfSelectedTime);
    },
    onCancelStaffSelect() {
      if (this.staffDetailsOpen === false || this.filteredStaffs.length <= 1) {
        this.onOpenStaff(null);
        return this.$router.go(-1);
      }
      this.staffDetailsOpen = false;
      this.onOpenStaff(null);
      this.filterStaffList();
    },
    jumpToStaffDetails() {
      if (!this.$refs.scroller) return;
      this.$refs.scroller.scrollIntoView();
    },
    openFirstStaff(force = true) {
      if (this.filteredStaffs.length === 0) return;
      this.onOpenStaff(this.filteredStaffs[0].idStaff, force);
    },
  },
};
</script>

<style>
.selection-staff-details {
  width: 92%;
  border-bottom: 1px solid rgba(100, 100, 100, 0.3);
  margin-left: 3%;
}
.clinic-service-staff {
  width: 95%;
  margin-bottom: 20px;
  position: relative;
  margin-left: 5%;
}
.pd-top {
  padding-top: 50px;
}
.more-staff-title {
  position: absolute;
  left: 0%;
  top: 17.5px;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: var(--staff-item-aval-days-c);
}
.main-staff-wrapper {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}
.main-staff-wrapper .scroller {
  align-items: center;
}
.staff-button-panel {
  bottom: 0px;
}
.clinic-service-staff .no-staff {
  font-size: 20px;
}
@media only screen and (max-width: 850px) {
  .pd-top {
    padding-top: 70px;
  }
  .more-staff-title {
    left: var(--item-ml-mobile);
  }
  .selection-staff-details {
    width: 94%;
    margin-left: 5%;
  }
  .safari-style .selection-staff-details {
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
  }
  .safari-style .clinic-service-staff {
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
  }
}
</style>
<template>
  <div class="error-overlay-wrapper d-flex flex-column">
    <div class="icon-wrapper">
      <img :src="icon" alt="ikona" />
    </div>
    <div class="text-wrapper d-flex flex-column">
      <h2>{{ title }}</h2>
      <h3 v-if="subtitle">{{ subtitle }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  props: ['icon', 'title', 'subtitle'],
};
</script>

<style>
.error-overlay-wrapper {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.error-overlay-wrapper img {
  width: 126px !important;
  height: 126px !important;
  margin-bottom: 25px;
}
.error-overlay-wrapper h2 {
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: var(--error-no-items-text-c);
  margin-bottom: 10px;
}
.error-overlay-wrapper h3 {
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: var(--error-no-items-text-c);
}
</style>

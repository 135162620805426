<template>
  <div class="order-complete-wrapper d-flex flex-column">
    <img v-if="icon" :src="icon" alt="thumbsup-icon" />
    <h1>{{$t(title)}}</h1>
    <h3>{{$t(subTitle)}}</h3>
    <AppButton class="btn-close" :background="true" v-on:close="onClose" :action="'close'" :title="'Zavřít'" />
  </div>
</template>

<script>
import AppButton from '../components/Button.vue';
export default {
  components: {
    AppButton,
  },
  props: ["orderCancelStatus"],
  data() {
    return {
      window: null,
      title: "",
      subTitle: "",
      icon: null
    };
  },
  watch: {
    orderCancelStatus: function() {
      this.getCancelOrderScreenTexts();
    }
  },
  methods: {
    onClose() {
      this.$emit('close-screen');
    },
    getCancelOrderScreenTexts() {
      const obj = {
        title: "",
        subTitle: "",
        icon: null
      }

      switch (this.orderCancelStatus) {
        case 1:
          obj.title = "order-canceled-title"
          obj.subTitle = "order-canceled-subtitle"
          obj.icon = require('@/assets/icons/ok_ic.svg')
        break;
        case 3:
          obj.title = "order-canceled-fail-already-title"
          obj.subTitle = "order-canceled-fail-already-subtitle"
          obj.icon = require('@/assets/icons/cross_ic.svg')
        break;
        case 4:
          obj.title = "order-canceled-fail-request-title"
          obj.subTitle = "order-canceled-fail-request-subtitle"
          obj.icon = require('@/assets/icons/cross_ic.svg')
        break;
      }

      this.title = obj.title;
      this.subTitle = obj.subTitle;
      this.icon = obj.icon;
    }
  },
  mounted() {
    this.getCancelOrderScreenTexts();
  },
  updated() {
    this.getCancelOrderScreenTexts();
  }
};
</script>

<style>
.order-complete-wrapper {
  position: absolute;
  z-index: 100;
  background-color: #F2565C;
  opacity: 0.9;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: white;
  left: 0;
  top: 0;
}
.order-complete-wrapper h1 {
  font-size: 40px;
  font-weight: 300;
}
.order-complete-wrapper h3 {
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}
.order-complete-wrapper img {
  width: 10vw;
}
.order-complete-wrapper h1 {
  margin: 20px 0;
}
.order-complete-wrapper .btn-close {
  margin-top: 75px;
  margin-bottom: 20px;
  min-width: 5vw;
  width: fit-content;

  background: #FFFFFF;
  border: 1px dashed #FFFFFF;
  border-radius: 5px;
  color: #D80027;
}
.order-complete-wrapper .btn-close:hover {
  background: #FFFFFF;
  border: 1px dashed #FFFFFF;
  border-radius: 5px;
  color: #D80027;
}
@media only screen and (max-width: 850px) {
  .order-complete-wrapper img {
    width: 45vw;
  }
  .order-complete-wrapper h1 {
    font-size: 32px;
  }
  .order-complete-wrapper h3 {
    font-size: 16px;
    font-weight: 300;
    width: 70%;
    margin-top: 20px;
  }
  .order-complete-wrapper .btn-close {
    margin-top: 20vh;
    width: 30vw;
  }
}
</style>

import moment from 'moment';

const dayTypeToFull = (type) => {
  const fullString = {
    MON: 'Pondělí',
    TUE: 'Úterý',
    WED: 'Středa',
    THU: 'Čtvrtek',
    FRI: 'Pátek',
    SAT: 'Sobota',
    SUN: 'Neděle',
  };

  if (!fullString[type]) {
    console.error('NO FULL STRING DAY FOUND FOR ' + type);
    return type;
  }

  return fullString[type];
};
export const dayTypeToId = (type) => {
  const ids = {
    MON: 1,
    TUE: 2,
    WED: 3,
    THU: 4,
    FRI: 5,
    SAT: 6,
    SUN: 7,
  };

  if (!ids[type]) {
    console.error('NO FULL STRING DAY FOUND FOR ' + type);
    return type;
  }

  return ids[type];
};

const getFormattedDate = (tmstp) => {
  let date = moment().startOf('day').add(tmstp, 'minutes').format('HH:mm');

  return date;
};

export default {
  dayTypeToFull,
  getFormattedDate,
};

<template>
  <ValidationProvider tag="div" class="input-wrapper textarea-input" :name="name" :rules="rulesPipe" v-slot="{ errors }">
    <div class="textarea-input-wrapper">
      <label v-if="false">{{ label }}</label>
      <div class="wrapper">
        <textarea
          @focus="onFocus"
          :placeholder="placeholder"
          :id="name"
          :name="name"
          class="form-control"
          :class="{ 'input-error': errors.length > 0 && errorActivated, 'input-warning-error': errors.length > 0 && errorActivated && isWarningError }"
          v-model="value"
          :maxlength="max"
        ></textarea>
      <div v-if="displayError && errorActivated && errors.length > 0" class="error-input-icon" v-html="isWarningError ? warningIcon : errorIcon"></div>
      </div>
      <span class="error-text" v-bind:class="{ 'error-warning-text': isWarningError }" v-if="displayError && errorActivated && errors.length > 0">{{ errors[0] }}</span>
    </div>
  </ValidationProvider>
</template>

<script>
export default {
  props: {
    name: String,
    rules: Array,
    label: String,
    displayError: Boolean,
    model: String,
    placeholder: String,
    isWarningError: Boolean,
    errorIcon: String,
    warningIcon: String
  },
  data() {
    return {
      rulesPipe: '',
      value: '',
      errorActivated: true,
      max: 1024
    };
  },
  methods: {
    onFocus() {
      this.errorActivated = true;
    },
  },
  watch: {
    value: function (newVal) {
      this.$emit('change', { newVal, name: this.name });
    },
    isWarningError: function () {
      this.onFocus();
    },
    setMax(max) {
      this.max = max.trim().split(":")[1];
    }
  },
  created() {
    this.rules.map((r) => {
      if (this.rulesPipe !== '') this.rulesPipe += '|';

        if (r.includes("max") || r.includes("digits")) this.setMax(r);

      this.rulesPipe += r;
    });

    this.value = this.model;
  },
};
</script>

<style>
.textarea-input-wrapper textarea {
  border: 1px solid rgba(100, 100, 100, 0.3);
  border-radius: 10px;
  padding: 15px 10px;
  box-sizing: border-box;
  height: 50px;
  font-size: 16px;
  width: 100%;
  min-height: 15vh;
  resize: none;
  font-weight: 300;
}
.textarea-input-wrapper label {
  font-weight: 300;
  color: var(--form-label-errorwarn-c);
}
.textarea-input-wrapper {
  text-align: right;
}
.textarea-input-wrapper .error-text {
  margin-right: calc(2% + 15px);
}
.textarea-input-wrapper {
  text-align: right;
}
.textarea-input-wrapper .input-error {
  padding-right: calc(5% + 20px) !important;
}
.textarea-input-wrapper .wrapper {
  position: relative;
  margin: 0;
  width: 100%;
}
.textarea-input-wrapper .error-input-icon {
  position: absolute;
  right: 5%;
  top: calc(50% - 9px);
}
@media only screen and (max-width: 850px) {
  .textarea-input-wrapper .error-text {
    margin-right: 15px;
  }
}
</style>

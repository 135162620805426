export const adjustDetailsToOpen = (parent, active, detail) => {
  const top = active.getBoundingClientRect().y - parent.getBoundingClientRect().y + parent.scrollTop;
  detail.style.top = top + 'px';
};
export const scrollbarVisible = element => {
  return element.scrollHeight > element.clientHeight;
}
export const adjustDetailsToOpenFit = (parent, openItem, detail) => {
  const tolerance = 2;
  let openItemBoundingC = openItem.getBoundingClientRect();
  let parentBoundingC = parent.getBoundingClientRect();
  let detailBoundingC = detail.getBoundingClientRect();

  let openItemHeight = openItemBoundingC.height;
  let yPosition = openItemBoundingC.y + openItemHeight + parent.scrollTop;

  let detailPositionY = openItemBoundingC.y - parentBoundingC.y - detailBoundingC.height + openItemBoundingC.height + parent.scrollTop; //openItemBoundingC.y - detailBoundingC.height - openItemBoundingC.height + parent.scrollTop - 20;
  let isBottomAlign = yPosition + tolerance >= detailBoundingC.height + parentBoundingC.y; 

  if (isBottomAlign) detail.style.top = detailPositionY + "px";

  else detail.style.top = "0px";

  adjustDetailBorders(isBottomAlign, detail, openItemBoundingC.y - parentBoundingC.y);
}
export const adjustDetailBorders = (bottomAl, detail, diff) => {
  if (bottomAl) {
    detail.style.borderTopLeftRadius = "var(--content-item-border-radius)";
    detail.style.borderBottomLeftRadius = "0px";
    return;
  }
  
  if (diff <= 0) {
    detail.style.borderTopLeftRadius = "0px";
    detail.style.borderBottomLeftRadius = "var(--content-item-border-radius)";
    return;
  }
  
  detail.style.borderTopLeftRadius = "var(--content-item-border-radius)";
  detail.style.borderBottomLeftRadius = "var(--content-item-border-radius)";
  
}
import { getAppConfig } from '../configManager';

const SPECIAL_THEME_CLASSES = ["BOOKING_SCHEME_LIGHT_COLOR"];

const CODE_THEME_CLASS_MAP = {
  BOOKING_SCHEME_LIGHT_LIGHT: "main-bg-light-theme-1 content-bg-light-theme-1",
  BOOKING_SCHEME_LIGHT_DARK: "main-bg-light-theme-1 content-bg-dark-theme-1",
  BOOKING_SCHEME_LIGHT_COLOR: "main-bg-light-theme-1 content-bg-light-color-theme", 
  BOOKING_SCHEME_DARK_LIGHT: "main-bg-dark-theme-1 content-bg-light-theme-1",
  BOOKING_SCHEME_DARK_DARK: "main-bg-dark-theme-1 content-bg-light-theme-2",
  BOOKING_SCHEME_DARK_COLOR: "main-bg-dark-theme-2 content-bg-dark-theme-2"
}

const hexColorClassObj = {
  "#659be0": "cornflower-blue-theme",
  "#ed1c24": "red-theme",
  "#80c343": "lime-green-theme",
  "#f7941e": "sandy-brown-theme",
  "#65e09b": "medium-spring-green-theme",
  "#e0659b": "orchid-theme",
  "#337ab7": "steel-blue-theme",
  "#36c6d3": "torquoise-theme",
  "#ed6b75": "tomato-theme",
}

export const hexToThemeColorClass = hex => {
  return hexColorClassObj[hex];
}

export const getThemeIcon = (id) => {
  const cfg = getAppConfig('themes');

  if (cfg[id]) return cfg[id].icon;

  console.warn('Warning: No available theme for theme id: ' + id);

  return '0';
};

export const getThemeClass = themeCode => {
  let cls = CODE_THEME_CLASS_MAP[themeCode] ? CODE_THEME_CLASS_MAP[themeCode].split(" ") : Object.values(CODE_THEME_CLASS_MAP)[0].split(" ")
  let isThemeSpecial = false;

  isThemeSpecial = SPECIAL_THEME_CLASSES.includes(themeCode);
  return [cls, isThemeSpecial];
}

export const shadeColor = (color, percent) => {
  if (!color) return "#ffffff";
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  let RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
  let GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
  let BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

  return '#' + RR + GG + BB;
};
//https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors @Pimp Trizkit, slightly modified
export const pSBC=(p,c0,c1,l)=>{
  try {
    let r,g,b,P,f,t,myH,i=parseInt,m=Math.round,a=typeof(c1)=="string";
    if(typeof(p)!="number"||p<-1||p>1||typeof(c0)!="string"||(c0[0]!='r'&&c0[0]!='#')||(c1&&!a))return null;
    let pSBCr =(d)=>{
      let n=d.length,x={};
      if(n>9){
        [r,g,b,a]=d=d.split(","),n=d.length;
        if(n<3||n>4)return null;
        x.r=i(r[3]=="a"?r.slice(5):r.slice(4)),x.g=i(g),x.b=i(b),x.a=a?parseFloat(a):-1
      }else{
        if(n==8||n==6||n<4)return null;
        if(n<6)d="#"+d[1]+d[1]+d[2]+d[2]+d[3]+d[3]+(n>4?d[4]+d[4]:"");
        d=i(d.slice(1),16);
        if(n==9||n==5)x.r=d>>24&255,x.g=d>>16&255,x.b=d>>8&255,x.a=m((d&255)/0.255)/1000;
        else x.r=d>>16,x.g=d>>8&255,x.b=d&255,x.a=-1
      }return x};
    myH=c0.length>9,myH=a?c1.length>9?true:c1=="c"?!myH:false:myH,f=pSBCr(c0),P=p<0,t=c1&&c1!="c"?pSBCr(c1):P?{r:0,g:0,b:0,a:-1}:{r:255,g:255,b:255,a:-1},p=P?p*-1:p,P=1-p;
    if(!f||!t)return null;
    if(l)r=m(P*f.r+p*t.r),g=m(P*f.g+p*t.g),b=m(P*f.b+p*t.b);
    else r=m((P*f.r**2+p*t.r**2)**0.5),g=m((P*f.g**2+p*t.g**2)**0.5),b=m((P*f.b**2+p*t.b**2)**0.5);
    a=f.a,t=t.a,f=a>=0||t>=0,a=f?a<0?t:t<0?a:a*P+t*p:0;
    if(myH)return"rgb"+(f?"a(":"(")+r+","+g+","+b+(f?","+m(a*1000)/1000:"")+")";
    else return"#"+(4294967296+r*16777216+g*65536+b*256+(f?m(a*255):0)).toString(16).slice(1,f?undefined:-2)
  } catch (error) {
    console.error(error); 
  }
}
export const checkContrast = (bgColor, returnInverted) => {
  const CONTRAST_TRESHOLD = 186;

  const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);

  let overTreshold = '#000';
  let underTreshold = '#fff';

  if (returnInverted) {
    overTreshold = '#fff';
    underTreshold = '#000';
  }

  return r * 0.299 + g * 0.587 + b * 0.114 > CONTRAST_TRESHOLD ? overTreshold : underTreshold;
};
export const hexToRgbA = (hex, alfa = 1) => {
  if (hex.includes("rgb")){
    let [r,g,b] = hex.replace("rgb", "").replace("(", "").replace(")", "").trim().split(",");
    return `rgba(${r},${g},${b},${alfa})`;
  }
  let c;
  hex = hex.toLowerCase().trim();

  c = hex.substring(1).split('');
  if (c.length == 3) c= [c[0], c[0], c[1], c[1], c[2], c[2]];

  c = '0x' + c.join('');

  return `rgba(${[(c>>16)&255, (c>>8)&255, c&255].join(',')},${alfa})`;

}
export const formatPhoneNum = (prefix, num) => {
  if (!num) return '';

  const [int] = num.toString().split('.');
  let str = prefix ? prefix + ' ' : '';
  return str + int.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};
export const removeWhitespaces = (str) => {
  return str.replace(/ /g, '');
};

<template>
  <div v-if="activeLanguage" :class="['language-switch-wrapper', 'd-flex', 'flex-column', elClass, listOpen ? 'open' : '']">
    <div class="active-flag-wrapper d-flex flex-row" v-on:click="toggleLangList">
      <img :src="flagSrc" class="language-switcher-flag" />
      <img class="chevron-right" v-bind:class="{ open: listOpen }" :src="require('@/assets/icons/chevron-right.svg')" alt="chevron-icon" />
    </div>
    <div v-show="listOpen" class="available-flag-list">
      <div class="flag-wrapper" v-on:click="onLanguageSwitch(l)" v-for="l in filteredLangList" :key="l">
        <img :src="getFlagSrc(l)" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    langList: Array,
    setLang: String,
    elClass: String,
  },
  data() {
    return {
      activeLanguage: null,
      listOpen: false,
      filteredLangList: [],
      flagSrc: null,
      hasModernFlag: ['cz', 'en', 'de'],
    };
  },
  methods: {
    onLanguageSwitch(key) {
      this.$emit('languageChanged', key);
      this.toggleLangList();
    },
    setActiveLangObj() {
      if (!this.langList) return;
      this.activeLanguage = this.langList.find((l) => l === this.setLang);
      this.filteredLangList = this.langList.filter((l) => l !== this.setLang);
    },
    setFlagSrc() {
      if (!this.activeLanguage) return;

      this.flagSrc = this.getFlagSrc(this.activeLanguage);
    },
    toggleLangList() {
      this.listOpen = !this.listOpen;
    },
    getFlagSrc(key) {
      return require('@/assets/icons/' + key + '.svg');
    },
  },
  created() {
    this.setActiveLangObj();
    this.setFlagSrc();
  },
  watch: {
    setLang: function () {
      this.setActiveLangObj();
      this.setFlagSrc();
    },
    langList: function () {
      this.setActiveLangObj();
    },
  },
};
</script>

<style scoped>
.language-switch-wrapper {
  position: absolute;
  z-index: 10;
  align-items: flex-start;
}
.active-flag-wrapper,
.flag-wrapper {
  cursor: pointer;
}
.active-flag-wrapper {
  align-items: center;
}
.active-flag-wrapper .language-switcher-flag {
  width: 27px;
  margin-right: 10px;
}
.active-flag-wrapper .chevron-right.open {
  transform: rotate(90deg);
}
.available-flag-list {
  margin-top: 5px;
}
.flag-wrapper {
  margin: 5px 0;
}
.flag-wrapper:hover .flag-wrapper img {
  opacity: 0.8;
}
.flag-wrapper img {
  width: 27px;
}
.flag-wrapper img:hover {
  opacity: 0.7;
}
@media only screen and (max-width: 850px) {
  .language-switch-wrapper {
    padding-left: 3%;
    border-left: 1px solid rgba(151, 151, 151, 0.5);
    height: 5%;
    justify-content: center;
  }
  .language-switch-wrapper.open {
    justify-content: flex-start;
    padding-top: 2%;
    box-sizing: border-box;
  }
  .chevron-right {
    display: none;
  }
}
</style>

const DEFAULT_FADE_TICK = 0.2;

function getInterval(duration, maxFade, minFade, tick) {
  return duration / ((maxFade - minFade) / tick);
}

export function fadeOut(minFade, maxFade, fadeDuration, element, fadePerTick = null, setDisplay = false, clb) {
  if (!fadePerTick) fadePerTick = DEFAULT_FADE_TICK;

  const interval = getInterval(fadeDuration, maxFade, minFade, fadePerTick);

  let opacity = maxFade;

  const callback = () => {
    element.style.display = 'none';
  };

  const intervalFunc = setInterval(() => {
    if (opacity <= minFade) {
      clearInterval(intervalFunc);
      if (setDisplay) callback();
      if (clb) {
        clb();
        element.style.opacity = 1;
      }
      return;
    }
    opacity -= fadePerTick;
    element.style.opacity = opacity;
  }, interval);
}

export function simpleFadeIn(el, opacity, id) {
  if (!el) return;
  if (el.getAttribute("data-staff-id") != id) return;

  if (opacity < 1) {
    opacity += .1;
    setTimeout(function(){simpleFadeIn(el, opacity, id)},50);
 }
 el.style.opacity = opacity;
}
<template>
  <p>
    <i v-if="icon" :class="['fa', 'fa-' + icon]"></i>
    {{ text }}
  </p>
</template>

<script>
export default {
  name: 'AppText',
  props: ['icon', 'text'],
};
</script>

<style></style>

export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
export const cutText = (str, maxWords = 1) => {
  const splitStr = str.split(/\s+/);

  let cutStr = '';
  for (let i = 0; i < splitStr.length; i++) {
    if (i >= maxWords) break;
    if (i > 0) cutStr += ' ';
    cutStr += splitStr[i];
  }

  if (splitStr.length > maxWords) cutStr += '...';

  return cutStr;
};
export const escapeText = text => {
  return text.replace(/["&<>]/g, a => {
      return { '"': '&quot;', '&': '&amp;', '<': '&lt;', '>': '&gt;' }[a];
  });
}
export const removeHtmlTags = text => {
  if (!text)  text = "";
  text = text.replace(/(<([^>]+)>)/gi, "");
  return text;
}

export const URLize = url => {
  if (url.includes("https://") || url.includes("http://")) return url;

  let newUrl = "https://";

  return newUrl + url;
}

export const getTextUrl = web => {
  web = URLize(web);
  return new URL(web).host;
}
<template>
  <div>
    <span class="day-span">{{ $t(dayName.toLowerCase()) }}</span>
    <span class="time-span">{{ morningTimeParsed }}</span>
    <span class="time-span">{{ afternoonTimeParsed }}</span>
  </div>
</template>

<script>
import * as dateHelper from '../helpers/openHourHelper';

export default {
  props: ['dayName', 'morningTime', 'afternoonTime'],
  data() {
    return {
      morningTimeParsed: '',
      afternoonTimeParsed: '',
    };
  },
  created() {
    this.morningTimeParsed = dateHelper.default.getFormattedDate(this.morningTime);
    this.afternoonTimeParsed = dateHelper.default.getFormattedDate(this.afternoonTime);
  },
};
</script>

<style scoped>
div {
  display: grid;
  grid-template-columns: 2.5fr 1fr 1fr;
  margin: 8px 0;
}
.day-span {
  color: var(--openhours-day-c);
}
.time-span {
  color: var(--openhours-time-c)
}
</style>

<template>
  <div class="d-flex flex-column" style="height: 100%; justify-content: space-between">
    <div class="client-form-wrapper">
      <div class="scroller d-flex flex-column">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <form class="form-client-info d-flex flex-column" @submit.prevent="handleSubmit(onSubmit)">
            <div class="form-section d-flex flex-row-wrap client-basic-info-wrapper">
              <TextInput
                :displayError="true"
                v-on:input="onInputChange"
                :isWarningError="isWarning"
                :rules="inputRules.firstName"
                :name="'firstName'"
                :model="formData.firstName"
                :placeholder="$t('first-name-plcdh')"
                :errorIcon="errorIcon" 
                :warningIcon="warningIcon"
                :autoCapitalize="true"
              />
              <TextInput
                :displayError="true"
                v-on:input="onInputChange"
                :isWarningError="isWarning"
                :rules="inputRules.lastName"
                :name="'lastName'"
                :model="formData.lastName"
                :placeholder="$t('last-name-plcdh')"
                :errorIcon="errorIcon" 
                :warningIcon="warningIcon"
                :autoCapitalize="true"
              />
              <div class="phone-wrapper d-flex flex-row">
                <SelectInput
                  :displayError="true"
                  v-on:change="onInputChange"
                  :isWarningError="isWarning"
                  :options="contentData.phonePrefixList"
                  :rules="inputRules.phonePrefix"
                  :localizedValue="false"
                  :name="'phonePrefix'"
                  :model="formData.phonePrefix"
                  :errorIcon="errorIcon" 
                  :warningIcon="warningIcon"
                />
                <TextInput
                  :displayError="true"
                  v-on:input="onInputChange"
                  :isWarningError="isWarning"
                  :rules="inputRules.phone"
                  :name="'phone'"
                  :model="formData.phone"
                  :placeholder="$t('phone-plcdh')"
                  :errorIcon="errorIcon" 
                  :warningIcon="warningIcon"
                  :autoCapitalize="false"
                />
              </div>
              <TextInput :errorIcon="errorIcon" :autoCapitalize="false" :warningIcon="warningIcon" :displayError="true" v-on:input="onInputChange" :isWarningError="isWarning" :rules="emailRules" :name="'email'" :model="formData.email" :placeholder="$t('email-plcdh')" />
              <component
                v-for="i in contentData.customInputs"
                :isWarningError="isWarning"
                :key="i.name"
                :is="i.type"
                v-bind="{
                  name: i.name,
                  model: formData[i.name],
                  localizedValue: i.localizedValue,
                  placeholder: i.localizedValue ? $t(i.label) : i.label,
                  rules: i.rules,
                  displayError: true,
                  label: i.localizedValue ? $t(i.label) : i.label,
                  options: i.options,
                }"
                @change="onInputChange"
                @input="onInputChange"
                :errorIcon="errorIcon" 
                :warningIcon="warningIcon"
                :autoCapitalize="true"
              ></component>
            </div>
            <div v-if="false" class="terms">
              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nam, quia quos. Alias asperiores beatae illo nisi, expedita corporis quam vitae ratione eos, ad laborum incidunt, temporibus
                similique ipsum vero odit.
              </p>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
    <ButtonPanel
      :hintComplete="disabledSubmit === false ? true : false"
      :finishState="contentData.orderComplete"
      :hintText="contentData.orderComplete ? true : contentData.orderDateError ? $t('submit-order-footer-error') : $t('hint_summary')"
      :hintTextIsError="contentData.orderDateError"
      :cancelDisabled="contentData.orderComplete"
      :errorBtn="contentData.orderDateError"
      v-on:cancel="onCancel"
      v-on:select="onFormSubmit"
      :submitTitle="contentData.submitBtnText"
      :disabled="false"
      :loading="contentData.loading"
      class="clientForm-btn-panel"
      :iconId="contentData.iconId"
      :mobile="contentData.mobile"
    />
  </div>
</template>

<script>
import TextInput from '../components/Forms/TextInput.vue';
import SelectInput from '../components/Forms/SelectInput.vue';
import TextArea from '../components/Forms/Textarea.vue';
import ButtonPanel from '../components/ButtonPanel.vue';
import CheckboxInput from '../components/Forms/CheckboxInput.vue';
import { removeWhitespaces } from '../helpers/formatHelper';
import { getNumRuleOfPrefix } from '../helpers/customInputsHelper';
import { getSvg } from '../helpers/assetHelper';

export default {
  props: {
    contentData: Object,
  },
  components: {
    TextInput,
    SelectInput,
    TextArea,
    ButtonPanel,
    CheckboxInput,
  },
  computed: {
    emailRules() {
      if (!this.contentData.isEmailOrPhoneReq) return this.contentData.rules.email;

      let newRulesArr = [...this.contentData.rules.email];

      if (!this.formData['phone']) newRulesArr.push('required');

      return newRulesArr;
    },
  },
  data() {
    return {
      formData: null,
      disabledSubmit: true,
      isWarning: true,
      initFocus: false,
      warningIcon: null,
      errorIcon: null,
      inputRules: {},
      inputList: {
        text: [
          { name: 'firstName', rules: ['required', 'min:6'], label: 'Zadejte své křestní jméno', displayError: true },
          { name: 'lastName', rules: ['required', 'min:6'], label: 'Zadejte své příjmení', displayError: true },
          { name: 'phone', rules: ['required'], label: 'Zadejte vaše telefonní číslo', displayError: true },
        ],
        select: [{ name: 'phonePrefix', rules: ['required'], label: null, displayError: true, options: [{ label: '+420', value: '+420' }] }],
        radio: [
          {
            name: 'operation',
            rules: ['required'],
            label: 'Chci vytrhat',
            displayError: true,
            options: [
              { label: 'Vše vytrhat', value: 'all' },
              { label: 'Něco vytrhat', value: 'some' },
              { label: 'Nic vytrhat', value: 'none' },
            ],
          },
        ],
        checkbox: [
          {
            name: 'operationCheck',
            rules: ['required'],
            label: 'Chci vytrhat',
            displayError: true,
            options: [
              { label: 'Vše vytrhat', value: 'all' },
              { label: 'Něco vytrhat', value: 'some' },
              { label: 'Nic vytrhat', value: 'none' },
            ],
          },
        ],
        textArea: [{ name: 'note', rules: ['required', 'min:6'], label: 'Poznámka:', displayError: true }],
      },
    };
  },
  watch: {
    '$refs.form.errors': {
      deep: true,
      handler: function () {
        let newDisabled = false;
        Object.values(this.$refs['form'].errors).forEach((e) => {
          if (e.length > 0) newDisabled = true;
        });

        this.disabledSubmit = newDisabled;
        //this.contentData.onChangeFill(!newDisabled);
      },
    },
    '$i18n.locale': function () {
      this.$refs.form.validate();
    },
    'formData.phonePrefix': function () {
      this.getPhoneRules();
      this.changePhoneLength();
    },
    'formData.email': function () {
      this.getPhoneRules();
    },
  },
  mounted() {
    this.getPhoneRules();
    this.$watch(
      () => {
        return this.$refs.form.errors;
      },
      (val) => {
        this.updateIsDisabled(val);
      }
    );
    this.$refs.form.validate();
  },
  async created() {
    this.formData = this.contentData.formData;
    this.inputRules = { ...this.contentData.rules };

    this.errorIcon = await getSvg("alert.svg");
    this.warningIcon = await getSvg("alert-warning.svg");
  },
  methods: {
    getPhoneRules() {
      let newRulesArr = [...this.contentData.rules.phone];
      if (this.formData['email'].length === 0 && !newRulesArr.includes('required')) newRulesArr.push('required');

      newRulesArr = [...newRulesArr, ...getNumRuleOfPrefix(this.formData.phonePrefix)];

      this.inputRules.phone = newRulesArr;
    },
    changePhoneLength() {
      let max = 0;
      this.inputRules.phone.forEach(r => {
        if (r.includes("max") || r.includes("digits")) {
          let str = r.trim().split(":");
          max = parseInt(str[1]);
        }
      })
      
      if (this.formData.phone.length <= max) return;

      this.formData.phone = this.formData.phone.substring(0, max);
    },
    onFormSubmit() {
      if (this.disabledSubmit === true) {
        this.openFirstRequired(this.$refs.form.errors);
        return (this.isWarning = false);
      }
      this.contentData.onSubmit(this.formData);
    },
    updateIsDisabled(newVal) {
      let newDisabled = false;
      Object.values(newVal).forEach((e) => {
        if (e.length > 0) newDisabled = true;
      });

      this.disabledSubmit = newDisabled;
      if (!this.initFocus) {
        this.openFirstRequired(this.$refs.form.errors);
        this.initFocus = true;
      }
    },
    onInputChange(data) {
      let max, oldVal;
      if (data.name === 'phone') {
        max = this.inputRules.phone.find((r) => r.includes('digits') || r.includes('max')).split(':')[1];

        data.newVal = data.newVal.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
        data.newVal = removeWhitespaces(data.newVal);

        oldVal = this.formData[data.name];
      }

      this.formData[data.name] = data.newVal;
      if (max && (data.newVal.length > max && data.validated)) {
        this.$nextTick(() => {
          this.formData[data.name] = oldVal;
        });
      }
    },
    onCancel() {
      this.$router.go(-1);
    },
    openFirstRequired(errors) {
      let found = false;
      
      document.querySelectorAll('input, textarea').forEach((el) => {
        if (found) return el.focus();

        if (errors[el.getAttribute('name')].length > 0) {
          found = el;
        }
      });
      if (found) found.focus();
    },
  },
};
</script>

<style>
.client-form-wrapper {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding-bottom: 10px;
}
.client-form-wrapper #warning-svg-icon {
  fill: var(--form-input-warn-icon-c) !important;
}
.client-form-wrapper h1 {
  margin-left: 8%;
  text-align: left;
  font-size: 16px;
  font-weight: 300;
}
.form-client-info {
  align-items: center;
}
.form-section {
  width: 95%;
  margin-left: 5%;
}
.client-basic-info-wrapper {
  gap: 2%;
}
.client-basic-info-wrapper .input-wrapper {
  width: 48%;
  margin: 1% 0;
}
.client-basic-info-wrapper input::placeholder,
.client-basic-info-wrapper textarea::placeholder,
.app-select-wrapper {
  color: var(--form-input-i-placeholder-c);
}
.client-basic-info-wrapper input,
.client-basic-info-wrapper textarea,
.app-select-wrapper {
  background-color: var(--item-bg-color);
  color: var(--form-input-i-text-c);
}
.client-basic-info-wrapper .textarea-input {
  width: 100%;
}
.phone-wrapper {
  width: 48%;
  margin: 1% 0%;
}
.client-basic-info-wrapper .textarea-input-wrapper .wrapper {
  width: 98% !important;
}
.phone-wrapper .input-wrapper {
  width: auto !important;
  margin: 0 !important;
}
.phone-wrapper .text-input-wrapper {
  width: 100% !important;
  margin-left: 10px !important;
}
.client-additional-info-wrapper {
  justify-content: flex-start;
  padding-left: 1%;
  margin: 1vh 0;
}
.client-note-input-wrapper {
  padding-left: 1%;
}
.terms {
  width: 70%;
  margin-top: 5vh;
  text-align: left;
  padding: 0 1%;
  box-sizing: border-box;
  font-weight: 300;
}
.clientForm-btn-panel .button-panel-select-btn {
  height: 40%;
}
@media only screen and (max-width: 850px) {
  .client-form-wrapper .scroller {
    margin-left: 20px;
  }
  .client-form-wrapper h1 {
    margin-left: 5%;
  }
  .form-section {
    width: 90%;
    margin-left: 0;
  }
  .client-basic-info-wrapper .input-wrapper {
    width: 100%;
    margin: 2% 0%;
  }
  .terms {
    margin-top: 2vh;
    margin-bottom: 2vh;
    width: 90%;
  }
  .scroller h1 {
    margin-bottom: 10px;
  }
  .phone-wrapper {
    width: 100%;
  }
  .client-basic-info-wrapper .textarea-input-wrapper .wrapper {
    width: 100% !important;
  }
  .safari-style .client-form-wrapper .scroller {
    margin-left: 0 !important;
  }
}
</style>
<style scoped>
.button-panel-wrapper {
  box-shadow: 10px 10px 10px 14px var(--button-panel-box-shadow-color) !important;
}
</style>

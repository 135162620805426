<template>
  <div class="staff-wrapper flex-layout-list-item d-flex flex-column" v-bind:class="{ 'staff-wrapper-disabled': isDisabled }" v-on:click="(e) => onSelectStaff(e, id)">
    <div class="staff-overview-wrapper d-flex flex-row">
      <img :src="!photoSrc ? defaultStaffIcon : photoSrc" alt="staff_icon" />
      <div class="d-flex flex-column staff-overview-text-wrapper">
        <span class="title-span">{{ isTemplate ? $t(name) : name }}</span>
        <div class="d-flex flex-column select-error" v-if="isDisabled">
          <span>{{ $t('clinic-select-not-possible') }}</span>
          <span>{{ $t('continue-select-other-staff') }}</span>
        </div>
        <div class="d-flex flex-column" v-if="isDisabled === false">
          <span class="aval-dates-span" v-if="isTemplate === false">{{ $t('free-dates-in-following-months') }}</span>
          <span class="aval-dates-listed" v-if="isTemplate === false">
            <span style="margin-right: 3px" v-for="(d, mKey, i) in datesAvalFiltered" :key="mKey">{{ i === 0 ? getEarliestDate() + ' | ' : '' }}{{ monthParser(d) }} {{ countAvalDates(d) }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { capitalize } from '../../helpers/textHelper';
import { fullStrDateToDMY } from '../../helpers/timesOfDayHelper';

export default {
  props: ['name', 'id', 'isTemplate', 'photoSrc', 'datesAval', 'dateDisplayLimit', "defaultStaffIcon"],
  data() {
    return {
      isDisabled: false,
      datesAvalFiltered: {},
    };
  },
  watch: {
    datesAval() {
      this.updateDisabledState();
      this.filterDatesAval();
    },
  },
  created() {
    this.updateDisabledState();
    this.filterDatesAval();
  },
  methods: {
    getEarliestDate() {
      return fullStrDateToDMY(Object.values(Object.values(this.datesAvalFiltered)[0])[0][0].dateTime);
    },
    filterDatesAval() {
      let i = 0;
      let filtObj = {};
      for (const key in this.datesAval) {
        if (i >= 2) break;
        filtObj = { ...filtObj, [key]: this.datesAval[key] };
        i++;
      }

      this.datesAvalFiltered = filtObj;
    },
    updateDisabledState() {
      if (this.isTemplate) return (this.isDisabled = false);

      this.isDisabled = Object.keys(this.datesAval).length === 0;
    },
    countAvalDates(d) {
      let totalCount = 0;
      totalCount = Object.values(d).length;
      //Object.values(d).map(dA => (totalCount += dA.length))
      if (totalCount > this.dateDisplayLimit) return `${this.dateDisplayLimit}+ `;

      return totalCount;
    },
    monthParser(d) {
      return capitalize(moment(Object.values(d)[0][0].dateTime).format('MMMM'));
    },
    onSelectStaff(e, id) {
      e.stopPropagation();

      this.$emit('open-staff', id);
    },
  },
};
</script>

<style>
.staff-wrapper .open {
  width: 100%;
  height: auto;
}
.staff-wrapper {
  width: calc(49% - 10px) !important;
  padding: 10px 5px !important;
  margin-left: 0 !important;
}
.staff-overview-wrapper {
  align-items: center;
}
.staff-overview-text-wrapper {
  width: calc(100% - 120px);
}
.staff-overview-text-wrapper span {
  max-width: 100%;
  word-wrap: break-word;
}
.staff-wrapper .service-details {
  margin-top: 5%;
}
.staff-overview-wrapper img {
  width: 64px;
  margin: 0 20px;
  border-radius: 50%;
}
.staff-overview-wrapper span {
  font-size: 11px;
  text-align: left;
}
.staff-overview-wrapper .title-span {
  font-weight: 400;
  font-size: 16px;
  color: var(--staff-item-title-c);
}
.aval-dates-listed {
  color: var(--staff-item-title-c);
  font-weight: 400;
}
.noon-time-picker {
  grid-area: noonTime;
}
.afternoon-time-picker {
  grid-area: afternoonTime;
}
.staff-submit-button {
  grid-area: submitButton;
  margin-top: 13%;
}
.aval-dates-span {
  margin-top: 7px;
  color: var(--staff-item-aval-days-c);
}

.staff-wrapper-disabled {
  pointer-events: none;
}
.staff-wrapper-disabled .title-span {
  font-weight: normal;
  opacity: 0.5;
}
.staff-wrapper-disabled img {
  opacity: 0.5;
}
.staff-wrapper-disabled .select-error {
  color: var(--danger);
  margin-top: 7px;
}
@media only screen and (max-width: 850px) {
  .staff-wrapper {
    width: 100% !important;
    padding: 15px 5px !important;
    margin-left: 0 !important;
  }
  .date-time-wrapper {
    margin-top: 25px;
  }
  .staff-overview-wrapper img {
    width: 80px;
    margin: 0;
    margin-right: 20px;
  }
  .staff-overview-wrapper .aval-dates-span {
    font-weight: 300;
    color: #9b9b9b;
  }
  .staff-overview-wrapper {
    padding: 0 20px;
    box-sizing: border-box;
  }
  .staff-overview-text-wrapper {
    width: calc(100% - 120px);
  }
}
</style>

<template>
  <div class="button-panel-wrapper d-flex flex-row" v-bind:style="finishState ? 'justify-content: center' : ''">
    <AppButton
      v-if="!finishState"
      class="btn-panel btn-panel-cancel"
      v-bind:style="cancelDisabled === true ? 'visibility: hidden' : ''"
      :greyTheme="true"
      :background="true"
      v-on:cancel="onCancel"
      :action="'cancel'"
      :title="$t('go-back-title')"
    />
    <div v-if="!finishState && hintComplete !== true" v-bind:style="hintText === true ? 'visibility: hidden' : ''" class="hint-wrapper d-flex flex-row">
      <Popup
        ref="popup"
        v-if="mobile"
        v-bind:style="hintOpen ? 'display: flex !important' : 'display: none !important'"
        class="btn-panel-popup"
        v-bind:class="hintTextIsError ? 'btn-panel-popup-error' : ''"
        :position="'bottom'"
        :text="hintText"
      />
      <div v-if="!hintTextIsError" ref="hintIcon" class="hint-icon" v-html="hintIconSrc" @click="onOpenHint"></div>
      <img v-if="false" ref="hintIcon" :src="require('@/assets/icons/hint-icon0.svg')" @click="onOpenHint" alt="" class="hint-icon" />
      <AppText v-if="!mobile" class="hint-text" :text="hintText" v-bind:class="{ 'hint-text-error': hintTextIsError }" />
    </div>
    <div class="placeholder" v-if="!isSelectShown" style="visibility: hidden;"></div>
    <AppButton
      class="btn-panel button-panel-select-btn"
      v-bind:class="{ 'btn-panel-error': errorBtn, 'btn-panel-error-special': errorBtnCancelOrder, 'btn-select-blocked': blocked, 'btn-select-hidden': hideSelectBtn }"
      v-if="isSelectShown"
      :disabled="disabled"
      :background="true"
      v-on:select="onSelect"
      :action="'select'"
      :title="$t(submitTitle)"
    />
    <MissingParamError v-if="errorObj" :title="$t(errorObj.title)" :subtitle="mobile === true && errorObj.subtitleMobile ? $t(errorObj.subtitleMobile) : $t(errorObj.subtitle)" :icon="errorObj.icon" />
    <AppText class="loading-text" v-if="loading" :text="'Načítání...'" />
  </div>
</template>

<script>
import AppButton from '../components/Button.vue';
import MissingParamError from '../components/MissingParamError.vue';
import AppText from '../components/Text.vue';
import Popup from '../components/Popup.vue';
import { fadeOut } from '../helpers/animationHelper';
import { getSvg } from '../helpers/assetHelper';
import { getAppConfig } from '../configManager';

export default {
  components: {
    AppButton,
    MissingParamError,
    AppText,
    Popup,
  },
  data() {
    return {
      hintOpen: true,
      hintClosing: false,
      hintInitFadeDone: false,
      hintIconSrc: null
    };
  },
  computed: {
    isSelectShown: function () {
      const {timeBlocked, newClientBlocked} = getAppConfig("orderErrorCodes");

      return !this.errorObj && !this.childrenHidden && this.loading === false && (!this.orderErrorCode || this.orderErrorCode == newClientBlocked || this.orderErrorCode == timeBlocked)
    }
  },
  props: ['finishState', "childrenHidden", "showCancel", "orderErrorCode", 'disabled', 'blocked', 'cancelDisabled', 'hideSelectBtn', 'errorObj', 'loading', 'submitTitle', 'mobile', 'errorBtn', 'errorBtnCancelOrder', 'hintText', 'hintTextIsError', 'iconId', 'hintComplete'],
  watch: {
    hideSelectBtn: function () {
      this.$nextTick(function () {
        this.adjustHintBox();
      });
    },
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    onSelect() {
      if (this.disabled) return;

      this.$emit('select');
    },
    async getIcon() {
      this.hintIconSrc = await getSvg("hint-icon0.svg");
    },
    onOpenHint() {
      if (!this.mobile || this.hintClosing) return;

      this.hintOpen = !this.hintOpen;
    },
    changeHintState() {
      this.hintOpen = false;
      this.hintClosing = false;
      this.hintInitFadeDone = true;
    },
    fadePopup() {
      if (!this.hintOpen || this.hintClosing || this.hintInitFadeDone) return;
      let el = document.querySelector('.btn-panel-popup');

      if (!el) return;
      this.hintClosing = true;
      setTimeout(() => {
        fadeOut(0, 1, 500, el, 0.1, false, this.changeHintState);
      }, 2000);
    },
    adjustHintBox() {
      const icon = this.$refs.hintIcon;
      const popup = document.querySelector('.btn-panel-popup');
      if (!icon || !popup) return;
      const iconCoords = icon.getBoundingClientRect();
      let hackPx = window.location.href.includes('staffSelection') && this.hideSelectBtn === true ? 0 : 0;

      popup.style.left = `${(iconCoords.left + iconCoords.right) / 2 - popup.clientWidth / 2 + hackPx}px`;
    },
  },
  created() {
    this.getIcon();
  },
  mounted() {
    this.adjustHintBox();
    this.fadePopup();
  },
  updated() {
    this.adjustHintBox();
    this.fadePopup();
  },
};
</script>

<style>
.button-panel-wrapper {
  background-color: var(--button-panel-background);
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
  min-height: 10vh;
  position: sticky;
  box-shadow: 10px 10px 11px 20px var(--button-panel-box-shadow-color);
}
.button-panel-wrapper .placeholder {
  width: 120px;
}
.button-panel-wrapper .hint-icon-fill {
  fill: var(--button-panel-hint-c);
}
.button-panel-wrapper .btn-panel-cancel {
  margin-left: 50px;
}
.button-panel-wrapper .button-panel-select-btn {
  margin-right: 50px;
}
.loading-text {
  margin-right: 50px;
}
.btn-panel {
  min-width: 4vw;
  font-size: 14px;
  background-color: var(--button-panel-cancel-bg-c);
}
.btn-panel-error {
  background-color: var(--danger) !important;
}
.btn-select-blocked {
  background-color: var(--greyish-white) !important;
  color: var(--white) !important;
}
.hint-wrapper {
  align-items: center;
}
.hint-text {
  color: var(--button-panel-hint-c);
  font-size: 14px;
  margin-left: 10px;
}
.hint-text-error {
  color: var(--danger) !important;
}
.btn-select-hidden {
  visibility: hidden;
}
.btn-panel-popup {
  max-width: 70vw;
  width: fit-content;
  top: -30px;
  right: 66px;
  margin: 0 !important;
}
.btn-panel-popup-error,
.btn-panel-popup-error .chev-icon {
  background-color: var(--danger) !important;
}
@media only screen and (max-width: 850px) {
  .button-panel-wrapper {
    min-height: calc(10vh - 20px);
    padding: 10px 0;
  }
  .button-panel-wrapper .btn-panel-cancel {
    margin-left: 20px;
  }
  .button-panel-wrapper .button-panel-select-btn {
    margin-right: 20px;
  }
  .loading-text {
    margin-right: 20px;
  }
  .hint-text {
    display: none;
  }
}
</style>

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Moment from 'vue-moment';
import DatePicker from 'vuejs-datepicker';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import i18n from './i18n';
import './vee-validate';

import '@/assets/global.css';
import '@/assets/themesMainBg.css';
import '@/assets/themesContentBg.css';
import '@/assets/themeColors.css';
import '../node_modules/add-to-calendar-button/assets/css/atcb.min.css' //xD

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('datepicker', DatePicker);
Vue.config.productionTip = false;

Vue.use(Moment);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
